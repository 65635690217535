import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { Row, Text, Switch } from "atomize/dist";

import AdminDashboardLayout from "./AdminDashboardLayout";
import Card from "../../components/Card";
import { changeUserStatus, getAdminDashboard, getServerStatus, updateServerStatus } from "../../core/apiAdmin";
import Label from "atomize/dist/atoms/label/Label";

function AdminDashBoard({ props }) {
  const [serverStatus, setServerStatus] = useState(false)
  const [serverStatusLoading, setServerStatusLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [datas, setDatas] = useState({
    data: {
      msg: [],
      dashboard: {
        click: 0,
        url: 0,
        days: {
          days: 0,
        },
      },
    },
    isloading: false,
  });
  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });

  const { data } = datas;

  const init = async () => {
    setIsLoading(true);
    await getAdminDashboard()
      .then((data) => {
        if (data.error) {
          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          setDatas({ ...datas, data });
        }
      })
      .catch((e) => {
        setNotificationData({
          content: e.error,
          bg: "danger700",
        });
      });

    await getServerStatus()
      .then((data) => {
        if (data.error) {
          setIsLoading(false);
          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          setServerStatus(data.value);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setNotificationData({
          content: e.error,
          bg: "danger700",
        });
        setIsLoading(false);
      });
  };

  const updateStatus = () => {
    setServerStatusLoading(true);
    updateServerStatus(!serverStatus).then(data => {
      if (data.error) {
        setServerStatusLoading(false);
        setNotificationData({
          content: JSON.parse(data.error).error,
          bg: "danger700",
        });
      } else {
        setServerStatusLoading(false);
        init()
      }

    }).catch(e => {
      setNotificationData({
        content: e.error,
        bg: "danger700",
      });
      setServerStatusLoading(false);
    })
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <AdminDashboardLayout
      notification={notificationData}
      onClose={(e) => {
        setNotificationData({
          content: false,
        });
      }}
      isLoading={isLoading}
    >
      <Row>
        <Card
          route="/admin/urls"
          title="Total Urls"
          value={data.urls}
          icon="Link"
          color="info700"
        ></Card>
        <Card
          title="Brands"
          route="/admin/users"
          value={data.brands}
          icon="UserSolid"
          color="warning600"
        ></Card>
        <Card
          title="Brand Urls"
          route="/admin/users"
          value={data.brandurls}
          icon="UserSolid"
          color="danger600"
        ></Card>
        <Card
          title="Total Users"
          route="/admin/users"
          value={data.users}
          icon="UserSolid"
          color="gray600"
        ></Card>
        <Card
          route="/admin/plans"
          title="Total Plans"
          value={data.plans}
          icon="Draft"
          color="brand700"
        ></Card>
        <Card
          title="Total Earnings"
          value={`₹ ${data.earn?data.earn:'0'}`}
          color="success700"
        ></Card>
      </Row>
      <br />
      <Label
        p={{ t: "0.6rem" }}
        onClick={(e) => {
          updateStatus()
        }}
        align="center"
        m={{ b: "1rem" }}
      >
        <Switch isLoading={serverStatusLoading} checked={serverStatus} />
        User Dashboard Status
        </Label>
    </AdminDashboardLayout>
  );
}

export default AdminDashBoard;

import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { Div, Row, Text } from "atomize/dist";
import UserDashboardLayout from "./UserDashboardLayout";
import Card from "../../components/Card";
import NotificationCard from "../../components/NotificationCard";
import { getDashboard } from "../../core/apiUser";
import { isAuthenticated } from "../../core";
import moment from "moment";
import { GetErrorMessage } from "../../components/ErrorMessageFunction";

const UserDashBoard = ({ props }) => {
  const { user } = isAuthenticated();

  const [datas, setDatas] = useState({
    data: {
      msg: [],
      dashboard: {
        click: 0,
        url: 0,
        days: {
          days: 0,
        },
      },
    },
    isloading: false,
  });
  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });
  const history = useHistory();

  const { data, isloading } = datas;

  const init = () => {
    setDatas({ ...datas, isloading: true });
    getDashboard()
      .then((data) => {
        if (data.error) {
          setDatas({ ...datas, isloading: false });
          setNotificationData({
            content: JSON.parse(data.error).error,

            bg: "danger700",
          });
        } else {
          setDatas({ ...datas, data, isloading: false });
        }
      })
      .catch((e) => {
        setNotificationData({
          content: e.error,
          bg: "danger700",
        });
        setDatas({ ...datas, isloading: false });
      });
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <UserDashboardLayout
      notification={notificationData}
      onClose={(e) => {
        setNotificationData({
          content: false,
        });
      }}
      isLoading={isloading}
    >

      <Div style={{ width: "Calc(100% - 10px)" }}>
        {datas.data && <GetErrorMessage availability={datas.data.avalibility}></GetErrorMessage>}

        <Row>
          <Card
            route="/user/urls"
            title="Total Urls"
            value={data?.avalibility?.noOfLinkUsed}
            icon="Link"
            color="info700"
          ></Card>
          <Card
            route="/user/urls"
            title="Brands"
            value={data?.avalibility?.noofbrandUsed}
            icon="Link"
            color="danger700"
          ></Card>
          <Card
            route="/user/urls"
            title="Brand Urls"
            value={data?.avalibility?.noofbrandlinkUsed}
            icon="Link"
            color="info700"
          ></Card>
          {/* <Card
            title="Total Hits"
            value={data?.avalibility?.noOfClickUsed}
            icon="RightUp"
            color="warning600"
          ></Card> */}
          <Card
            title="Refer Count"
            value={data.dashboard.referCount}
            icon="UpArrowSolid"
            color="success600"
          ></Card>
          <Card
            route="/user/membership"
            title="Plan Expiry"
            value={
              `${data.dashboard && data.dashboard.days && data.dashboard.days.days ? data.dashboard.days.days : '0'} days`
            }
            icon="Timestamp"
            color="brand700"
          ></Card>
        </Row>
        <Div bg="white" shadow="4" p="1rem" m={{ t: "2rem" }}>
          <Text textSize="title">Notifications</Text>
          {datas.data.msg.length < 1
            ? "No Notifications"
            : datas.data.msg.map((item, index) => (
              <NotificationCard key={index} data={item} />
            ))}
        </Div>
      </Div>
    </UserDashboardLayout>
  );
};

export default UserDashBoard;

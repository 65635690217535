import { Div, Row, Col, Text, Button, Icon, Anchor, Input } from 'atomize';
import moment from 'moment';
import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip';
import { CopyUrlItem } from './CopyUrlItem';
import { errMessageSimple } from './ErrorMessageFunction';

const UrlList = ({ urlData, setNotification = () => { }, onEdit = () => { }, onDelete = () => { } }) => {


    const listUrlBasedOnApp = (data) => {
        const { mac_url, and_url, pc_url, ios_url, url } = data;
        if (mac_url == null || and_url == null || pc_url == null || ios_url == null)
            return (
                <Div>
                    <Icon name="Link" size="10px" /> Target URL:{" "}
                    <Anchor style={{ wordWrap: 'break-word' }} textColor="gray700" href={data.url} target="_blank">
                        {" "}
                        {data.url}
                    </Anchor>
                </Div>
            );
        else if (
            mac_url == url &&
            and_url == url &&
            pc_url == url &&
            ios_url == url
        )
            return (
                <Div>
                    <Icon name="Link" size="10px" /> Target URL:{" "}
                    <Anchor style={{ wordWrap: 'break-word' }} textColor="gray700" href={data.url} target="_blank">
                        {" "}
                        {data.url}
                    </Anchor>
                </Div>
            );
        else
            return (
                <Div>
                    <Div>
                        <Icon name="Link" size="10px" /> Target URL:{" "}
                        <Anchor style={{ wordWrap: 'break-word' }} textColor="gray700" href={data.url} target="_blank">
                            {" "}
                            {data.url}
                        </Anchor>
                    </Div>
                    <Div>
                        <Icon name="Link" size="10px" />
            Mac:{" "}
                        <Anchor style={{ wordWrap: 'break-word' }} textColor="gray700" href={data.mac_url} target="_blank">
                            {" "}
                            {data.mac_url}
                        </Anchor>
                    </Div>
                    <Div>
                        <Icon name="Link" size="10px" />
            IOS:{" "}
                        <Anchor style={{ wordWrap: 'break-word' }} textColor="gray700" href={data.ios_url} target="_blank">
                            {" "}
                            {data.ios_url}
                        </Anchor>
                    </Div>
                    <Div>
                        <Icon name="Link" size="10px" /> PC:{" "}
                        <Anchor style={{ wordWrap: 'break-word' }} textColor="gray700" href={data.pc_url} target="_blank">
                            {" "}
                            {data.pc_url}
                        </Anchor>
                    </Div>
                    <Div>
                        <Icon name="Link" size="10px" /> Android:
            <Anchor style={{ wordWrap: 'break-word' }} textColor="gray700" href={data.and_url} target="_blank">
                            {" "}
                            {data.and_url}
                        </Anchor>
                    </Div>
                </Div>
            );
    };


    const [searchValue, setSearchValue] = useState()
    const [searchRes, setSearchRes] = useState(urlData.data)

    const searchFn = (searchValue) => {
        const _value = [];
        urlData.data.map((item, i) => {
            console.log('====================================');
            console.log(item.title);
            console.log('====================================');
            if (item.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
                _value.push(item)
            }
        });
        setSearchRes(_value);
    }

    return (
        <div>
            <Div d="inline-grid" justify="right" w="100%" textAlign="right">
                <Input m={{r:"0px"}}  maxW="200px" placeholder="Search urls" onChange={(e) => searchFn(e.target.value)}></Input>
            </Div>
            {
                searchRes.map((data, index) => (
                    <Div key={index} m={{ y: "20px" }} p="20px" shadow="3" rounded="md">
                        {
                            data.problem ? (
                                <Row>
                                    <ReactTooltip />
                                    <Col size={{ lg: 7, md: 8, sm: 12 }}>
                                        <Text style={{ textOverflow: 'ellipsis' }} textSize="title">{data.title}</Text>
                                    </Col>
                                    <Col size={{ lg: 2, md: 4, xs: 6 }} textAlign="-webkit-right">
                                        <Div border="1px solid" maxW="fit-content" p={{ x: "10px" }} h="26px" borderColor="gray700" rounded="sm">
                                            {moment(data.createdAt).format('YYYY-MM-DD')}
                                        </Div>
                                    </Col>
                                    <Col size={{ lg: 3, md: 12, xs: 12 }} textAlign="-webkit-right">
                                        <Div bg="danger300" d="flex" maxW="fit-content" data-tip={errMessageSimple()} m={{ l: "10px" }} textColor="danger700" p={{ x: "10px", y: "2px" }} h="26px" borderColor="gray700" rounded="sm">
                                            <Text textSize="caption">Not Deep linkable</Text>
                                            <Icon name="AlertSolid" color="danger700" size="18px"></Icon>
                                        </Div>
                                    </Col>
                                </Row>
                            ) : (
                                <Row>
                                    <Col size={{ lg: 10, md: 8, sm: 12 }}>
                                        <Text style={{ textOverflow: 'ellipsis' }} textSize="title">{data.title}</Text>
                                    </Col>
                                    <Col size={{ lg: 2, md: 4, xs: 6 }} textAlign="-webkit-right">
                                        <Div border="1px solid" maxW="fit-content" p={{ x: "10px" }} h="26px" borderColor="gray700" rounded="sm">
                                            {moment(data.createdAt).format('YYYY-MM-DD')}
                                        </Div>
                                    </Col>
                                </Row>
                            )
                        }
                        {listUrlBasedOnApp(data)}
                        <Div m={{ y: "10px" }} bg="gray300" h="2px" rounded="sm" w="100%"></Div>
                        {/* <Anchor style={{ wordWrap: 'break-word' }} textColor="danger700" target="_blank" href={`${uri}${data.url_id}`}>{`${uri}${data.url_id}`}</Anchor> */}
                        {
                            urlData.avalibility && (<CopyUrlItem
                                data={data}
                                availability={urlData.avalibility}
                                OnChange={() => setNotification("Link Copied", "success700")}
                                url={data.url_id}
                            ></CopyUrlItem>)
                        }

                        <Div m={{ t: "20px" }} d="flex">
                            <a target="_blank" href={`/url/chart/${data.id}`}>
                                <Button
                                    h="2rem"
                                    w="2rem"
                                    bg="success300"
                                    hoverBg="success400"
                                    rounded="lg"
                                    m={{ x: "0.5rem" }}
                                >
                                    <Icon name="Info" color="success800" size="18px" />
                                </Button>
                            </a>
                            <Button
                                h="2rem"
                                w="2rem"
                                bg="info300"
                                hoverBg="info400"
                                rounded="lg"
                                m={{ x: "0.5rem" }}
                                onClick={(e) => {
                                    onEdit(data)
                                }}
                            >
                                <Icon name="Edit" color="info800" size="18px" />
                            </Button>

                            <Button
                                h="2rem"
                                w="2rem"
                                bg="danger300"
                                hoverBg="danger400"
                                rounded="lg"
                                m={{ r: "0.5rem" }}
                                onClick={(e) => {
                                    onDelete(data)
                                }}
                            >
                                <Icon name="Delete" color="danger800" size="18px" />
                            </Button>


                        </Div>
                    </Div>
                ))
            }
        </div>
    )
}

export default UrlList

import React, { useEffect, useState } from "react";
import UserDashboardLayout from "./UserDashboardLayout";

import {
  Div,
  Button,
  Icon,
  Col,
  Row,
  Modal,
  Textarea,
  Text,
  Label,
  currentDevice,
  Switch,
} from "atomize";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DropDown from "../../components/DropDown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getReferralLink, updateReferalStatus } from "../../core/apiUser";
import { Fragment } from "react";
const Referral = ({ match }) => {
  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });
  const [referralData, setReferralData] = useState({
    copied: false,
    enableReferral: false,
    data: {},
    link: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const fetchLink = (params) => {
    setIsLoading(true);
    getReferralLink()
      .then((data) => {
        console.log(data);
        setIsLoading(false);
        if (data.error) {
          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          setIsLoading(false);
          setReferralData({
            ...referralData,
            data: data,
            enableReferral: data.isReferEnabled,
            link: data.referalLink,
          });
        }
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };
  const updateReferal = (e) => {
    e.preventDefault();
    setReferralData({
      ...setReferralData,
      enableReferral: !referralData.enableReferral,
    });
    setIsLoading(true);
    updateReferalStatus({ isReferEnabled: !referralData.enableReferral })
      .then((data) => {
        setIsLoading(false);
        if (data.error) {
          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          setIsLoading(false);
          setNotificationData({
            content: "Referral Status Updated",
            bg: "success700",
          });
          fetchLink();
        }
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  useEffect(() => {
    fetchLink();
  }, []);

  return (
    <UserDashboardLayout
      notification={notificationData}
      onClose={(e) => {
        setNotificationData({
          content: false,
        });
      }}
      isLoading={isLoading}
    >
      <Row>
        <Label
          p={{ t: "0.6rem" }}
          onClick={(e) => updateReferal(e)}
          align="center"
          m={{ b: "1rem" }}
        >
          <Switch checked={referralData.enableReferral} />
          Enable Referral
        </Label>
      </Row>
          <Div
            bg={referralData.enableReferral ? "info300" : "gray500"}
            w="100%"
            h="auto"
            rounded="md"
            p={{ y: "0.8rem", x: "1rem" }}
          >
            <Div >
              If anyone join Utubelink with this referral link, you get one
              month extra membership. Share Now
            </Div>

            <Div d="flex" justify="flex-start">
              {referralData.enableReferral && (
                <Fragment>
                  <Div> Referral Code: </Div>
                  <Div m={{ r: "1rem", r: "3px" }} textColor="info700">
                    {` ${referralData.link}`}
                  </Div>
                  <CopyToClipboard
                    text={referralData.link}
                    onCopy={() =>
                      setNotificationData({
                        content: "Link Copied",
                        bg: "success700",
                      })
                    }
                  >
                    <Button
                      h="2rem"
                      w="2rem"
                      bg="info300"
                      hoverBg="info400"
                      rounded="lg"
                      disabled={!referralData.enableReferral}
                      m={{ x: "0.5rem" }}
                    >
                      <FileCopyOutlinedIcon color="primary" />
                    </Button>
                  </CopyToClipboard>
                </Fragment>
              )}
            </Div>


          </Div>
    </UserDashboardLayout>
  );
};

export default Referral;

import React, { useState, useEffect } from "react";
import {
  Div,
  Button,
  Icon,
  Row,
  Modal,
  Col,
  Input,
  Text,
  Label,
  Switch,
  Textarea,
} from "atomize";
import AdminDashboardLayout from "./AdminDashboardLayout";
import DropDown from "../../components/DropDown";
import Table from "../../components/Table";
import {
  createPlan,
  deletePlan,
  editPlan,
  getPlansAdmin,
} from "../../core/apiAdmin";

const Plans = () => {
  const [showAddPlanModal, setShowAddPlanModal] = useState(false);

  // const [reRender, setRender] = useState(false);

  const [showEditPlanModal, setShowEditPlanModal] = useState(false);

  const [showDeletePlanModal, setShowDeletePlanModal] = useState(false);

  const [isAddPlanLoading, setIsAddPlanLoading] = useState(false);

  const [isEditPlanLoading, setIsEditPlanLoading] = useState(false);

  const [isDeletePlanLoading, setIsDeletePlanLoading] = useState(false);

  const [deletePlanId, setDeletePlanId] = useState(null);

  const [planAddData, setPlanAddData] = useState({
    noOfClick: 0,
    noOfLink: 0,
    noOfedit: 0,
    noofbrand: 0,
    noofbrandlink: 0,
    appLinkOrNot: false,
    forReferral: false,
    price: 0,
    offerPrice: 0,
    type: "PREMIUM",
    title: "",
    subDomain: [],
    specifiedUserEmail: null,
  });

  const changeToUnlimitedCreate = (name) => {
    if (planAddData[name] != -1) {
      setPlanAddData({ ...planAddData, [name]: -1 });
    } else {
      setPlanAddData({ ...planAddData, [name]: 0 });
    }
  }

  const changeToUnlimitedEdit = (name) => {
    if (planEditData[name] != -1) {
      setPlanEditData({ ...planEditData, [name]: -1 });
    } else {
      setPlanEditData({ ...planEditData, [name]: 0 });
    }
  }

  const CheckUnlimited = (val) => {
    return val == -1 ? true : false;
  }

  const showifNotUnlimited = (val, children) => {
    if (CheckUnlimited(val)) {
      return (
        <Div p={{ x: "7px", y: "7px" }}
          border="1px solid"
          borderColor="disabled" rounded="sm">
          <p>Unlimited</p>
        </Div>
      )
    } else {
      return children;
    }
  }


  const [planEditData, setPlanEditData] = useState({
    noOfClick: 0,
    noOfLink: 0,
    noOfedit: 0,
    appLinkOrNot: false,
    forReferral: false,
    price: 0,
    offerPrice: 0,
    type: "",
    subDomain: [],
    id: null, email: null
  });

  const fetchPlans = () => {
    setPlanData({ ...planData, isLoading: true });
    getPlansAdmin()
      .then((data) => {
        if (data.error) setPlanData({ ...planData, isLoading: false });
        else {
          setPlanData({ ...planData, isLoading: false, data: data });
        }
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };
  const [planData, setPlanData] = useState({
    data: [],
    isLoading: false,
    error: false,
  });

  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });

  const handleChangePlan = (name) => (e) => {
    setPlanAddData({ ...planAddData, [name]: e.target.value });
  };

  const handleChangePlanEdit = (name) => (e) => {
    setPlanEditData({ ...planEditData, [name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsAddPlanLoading(true);
    createPlan(planAddData)
      .then((data) => {
        if (data.error) {
          setIsAddPlanLoading(false);

          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          setIsAddPlanLoading(false);

          setNotificationData({
            content: "Plan Added",
            bg: "success700",
          });
          setShowAddPlanModal(false);
          fetchPlans();
        }
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  const saveEdits = (e) => {
    e.preventDefault();
    setIsEditPlanLoading(true);

    editPlan(planEditData, planEditData.id)
      .then((data) => {
        if (data.error) {
          setIsEditPlanLoading(false);

          setNotificationData({
            content: JSON.parse(data.error).error,

            bg: "danger700",
          });
        } else {
          setIsEditPlanLoading(false);
          setNotificationData({
            content: "Plan Edited",
            bg: "success700",
          });
          setShowEditPlanModal(false);
        }
        fetchPlans();
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  const handleDomainChange = (e) => {
    let domains = e.target.value;
    let domainArray = domains.split(",");
    setPlanAddData({ ...planAddData, subDomain: domainArray });
  };

  const handleDomainChangeEdit = (e) => {
    let domainArray = e.target.value.split(",");
    setPlanEditData({ ...planEditData, subDomain: domainArray });
    console.log(planEditData);
  };

  const deletePlanHandler = (params) => {
    setIsDeletePlanLoading(true);

    deletePlan(deletePlanId)
      .then((data) => {
        if (data.error) {
          setShowDeletePlanModal(false);

          setNotificationData({
            content: JSON.parse(data.error).error,

            bg: "danger700",
          });
        } else {
          setShowDeletePlanModal(false);
          setNotificationData({
            content: "Plan Deleted",
            bg: "success700",
          });
          setShowDeletePlanModal(false);
        }
        setIsDeletePlanLoading(false);
        fetchPlans();
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  const DeletePlanModal = (params) => {
    return (
      <Modal
        isOpen={showDeletePlanModal}
        onClose={() => setShowDeletePlanModal(false)}
        rounded="md"
        align="start"
        rounded="md"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setShowDeletePlanModal(false)}
          cursor="pointer"
        />
        <Div d="flex" m={{ b: "4rem" }}>
          <Icon
            name="AlertSolid"
            color="warning700"
            m={{ t: "0.35rem", r: "0.5rem" }}
          />
          <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="subheader">
            Do you really want to delete Plan?
          </Text>
        </Div>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setShowDeletePlanModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            isLoading={isDeletePlanLoading}
            onClick={(e) => deletePlanHandler()}
            bg="info700"
          >
            Delete
          </Button>
        </Div>
      </Modal>
    );
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const AddPlanModal = () => {
    return (
      <Modal
        isOpen={showAddPlanModal}
        onClose={(e) => setShowAddPlanModal(false)}
        maxW="50rem"
        rounded="md"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setShowAddPlanModal(false)}
          cursor="pointer"
        />
        <Text textAlign="center" textSize="title" m={{ b: "1rem" }}>
          Add New Plan
        </Text>
        {" "}
        <Row>
          <Col>
            <Row m={{ b: { xs: "0.5rem", md: "1rem" } }}>
              <Col size={{ xs: "12", md: "6" }}>
                Type
                  <DropDown
                  datas={[
                    { value: "PREMIUM", title: "PREMIUM" },
                    {
                      value: "ENTERPRISE",
                      title: "ENTERPRISE",
                    },
                    {
                      value: "INFLUENCERS",
                      title: "INFLUENCERS",
                    },
                  ]}
                  valueKey="title"
                  defaultValueKey="title"
                  defaultValue="PREMIUM"
                  title="Plan Type"
                  onChange={(data) => {
                    setPlanAddData({ ...planAddData, type: data.value });
                  }}
                ></DropDown>
              </Col>

              <Col size={{ xs: "12", md: "6" }}>
                Subdomain
                  <Textarea
                  m={{ t: { xs: "0.5rem", md: "auto" } }}
                  placeholder="Sub Domain (eg: demo.utube.link,asd.utube.link,abc.utube.link)"
                  onChange={handleDomainChange}
                />
              </Col>
            </Row>
            {(planAddData.type == "ENTERPRISE" || planAddData.type == "INFLUENCERS") && (
              <Row m={{ b: { xs: "0.5rem", md: "1rem" } }}>
                <Col>
                  User Email
                    <Input
                    type="email"
                    required
                    w="100%"
                    m={{ b: { xs: "0.5rem" } }}
                    placeholder="User Email"
                    onChange={handleChangePlan("specifiedUserEmail")}
                  />
                </Col>
              </Row>
            )}
            <Row m={{ b: { xs: "0.5rem", md: "1rem" } }}>
              <Col>
                Title
                    <Input
                  type="text"
                  required
                  w="100%"
                  m={{ b: { xs: "0.5rem" } }}
                  placeholder="Plan title"
                  onChange={handleChangePlan("title")}
                />
              </Col>

              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                No. of Brands
                  {
                  showifNotUnlimited(planAddData.noofbrand,
                    <Input
                      type="number"
                      required
                      m={{ b: { xs: "0.5rem" } }}
                      placeholder="No. of Brands"
                      onChange={handleChangePlan("noofbrand")}
                    />)
                }

              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                No. of Brands links
                  {
                  showifNotUnlimited(planAddData.noofbrandlink,
                    <Input
                      type="number"
                      required
                      m={{ b: { xs: "0.5rem" } }}
                      placeholder="No. of Brands links"
                      onChange={handleChangePlan("noofbrandlink")}
                    />)
                }

              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                No. of Clicks
                  {
                  showifNotUnlimited(planAddData.noOfClick,
                    <Input
                      type="number"
                      required
                      m={{ b: { xs: "0.5rem" } }}
                      placeholder="No. of Clicks"
                      onChange={handleChangePlan("noOfClick")}
                    />)
                }
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                No. of Link
                  {
                  showifNotUnlimited(planAddData.noOfLink,
                    <Input
                      required
                      m={{ b: { xs: "0.5rem" } }}
                      type="number"
                      placeholder="No. of Link"
                      onChange={handleChangePlan("noOfLink")}
                    />)
                }

              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                No. of Edit
                  {
                  showifNotUnlimited(planAddData.noOfedit,
                    <Input
                      required
                      m={{ b: { xs: "0.5rem" } }}
                      type="number"
                      placeholder="No. of Edit"
                      onChange={handleChangePlan("noOfedit")}
                    />)
                }

              </Col>

              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                Price
                  <Input
                  m={{ b: { xs: "0.5rem" } }}
                  required
                  type="number"
                  placeholder="Price"
                  onChange={handleChangePlan("price")}
                />
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                Offer Price
                  <Input
                  required
                  type="number"
                  m={{ b: { xs: "0.5rem" } }}
                  placeholder="Price - Offer Price"
                  onChange={handleChangePlan("offerPrice")}
                />
              </Col>
              {(planAddData.type == "ENTERPRISE" || planAddData.type == "INFLUENCERS") && (
                <Col size={{ xs: "12", md: "6", lg: "4" }}>
                  Count Of Months
                  {
                    showifNotUnlimited(planAddData.noofmonths,
                      <Input
                        required
                        type="number"
                        m={{ b: { xs: "0.5rem" } }}
                        placeholder="Months"
                        onChange={handleChangePlan("noofmonths")}
                      />)
                  }

                </Col>
              )}


            </Row>
            <Row>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    setPlanAddData({
                      ...planAddData,
                      appLinkOrNot: !planAddData.appLinkOrNot,
                    });
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={planAddData.appLinkOrNot} />
                  Custom Target URL
                  </Label>
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    setPlanAddData({
                      ...planAddData,
                      forReferral: !planAddData.forReferral,
                    });
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={planAddData.forReferral} />
                  For Referral
                </Label>
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    changeToUnlimitedCreate("noofmonths")
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={CheckUnlimited(planAddData.noofmonths)} />
                  Unlimited Months
                </Label>
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    changeToUnlimitedCreate("noOfClick")
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={CheckUnlimited(planAddData.noOfClick)} />
                  Unlimited Click
                </Label>
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    changeToUnlimitedCreate("noOfLink")
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={CheckUnlimited(planAddData.noOfLink)} />
                  Unlimited Links
                </Label>
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    changeToUnlimitedCreate("noofbrand")
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={CheckUnlimited(planAddData.noofbrand)} />
                  Unlimited Brands
                </Label>
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    changeToUnlimitedCreate("noofbrandlink")
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={CheckUnlimited(planAddData.noofbrandlink)} />
                  Unlimited Brand link
                </Label>
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    changeToUnlimitedCreate("noOfedit")
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={CheckUnlimited(planAddData.noOfedit)} />
                  Unlimited Edit
                </Label>
              </Col>
            </Row>
          </Col>
        </Row>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setShowAddPlanModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
            </Button>

          <Button
            type="submit"
            bg="info700"
            isLoading={isAddPlanLoading}
            onClick={(e) => submitHandler(e)}
          >
            Add
            </Button>
        </Div>
      </Modal>
    );
  };

  const EditPlanModal = () => {
    return (
      <Modal isOpen={showEditPlanModal} maxW="50rem" rounded="md">
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setShowEditPlanModal(false)}
          cursor="pointer"
        />
        <Text textAlign="center" textSize="title" m={{ b: "1rem" }}>
          Edit Plan
        </Text>
        {/* <form onSubmit={(e) => handleChangePlanEdit}> */}
        <Row>
          <Col>
            <Row m={{ b: "1rem" }}>
              <Col size={{ xs: "12", md: "6" }}>
                <Label>
                  Plan Type
                  <DropDown
                    // m={{ y: { xs: "1rem", md: "auto" } }}
                    datas={[
                      { value: "PREMIUM", title: "PREMIUM" },
                      {
                        value: "ENTERPRISE",
                        title: "ENTERPRISE",
                      },
                      {
                        value: "INFLUENCERS",
                        title: "INFLUENCERS",
                      },
                    ]}
                    valueKey="title"
                    defaultValueKey="title"
                    defaultValue={planEditData.type}
                    title="Plan Type"
                    onChange={(data) => {
                      setPlanEditData({ ...planEditData, type: data.value });
                    }}
                  ></DropDown>
                </Label>
              </Col>
              <Col size={{ xs: "12", md: "6" }}>
                Subdomain
                <Textarea
                  w={{ xs: "100%" }}
                  defaultValue={planEditData.subDomain}
                  placeholder="Sub Domain (eg: demo.utube.link,asd.utube.link,abc.utube.link)"
                  onChange={handleDomainChangeEdit}
                />
              </Col>
            </Row>
            {(planEditData.type == "ENTERPRISE" || planEditData.type == "INFLUENCERS") && (
              <Row m={{ b: { xs: "0.5rem", md: "1rem" } }}>
                <Col>
                  User Email
                    <Input
                    type="email"
                    required
                    defaultValue={planEditData.email}
                    w="100%"
                    m={{ b: { xs: "0.5rem" } }}
                    placeholder="User Email"
                    onChange={handleChangePlanEdit("email")}
                  />
                </Col>
              </Row>
            )}
            <Row m={{ b: "1rem" }}>

              <Col>
                Title
                    <Input
                  type="text"
                  required
                  defaultValue={planEditData.title}
                  w="100%"
                  m={{ b: { xs: "0.5rem" } }}
                  placeholder="Plan title"
                  onChange={handleChangePlanEdit("title")}
                />
              </Col>

              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                No. of Brands
                  {
                  showifNotUnlimited(planEditData.noofbrand,
                    <Input
                      type="number"
                      required
                      defaultValue={planEditData.noofbrand}
                      m={{ b: { xs: "0.5rem" } }}
                      placeholder="No. of Brands"
                      onChange={handleChangePlanEdit("noofbrand")}
                    />)
                }
              </Col>

              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                No. of Brands links
                  {
                  showifNotUnlimited(planEditData.noofbrandlink,
                    <Input
                      type="number"
                      required
                      defaultValue={planEditData.noofbrandlink}
                      m={{ b: { xs: "0.5rem" } }}
                      placeholder="No. of Brands links"
                      onChange={handleChangePlanEdit("noofbrandlink")}
                    />)
                }
              </Col>

              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                No. of Clicks
                  {
                  showifNotUnlimited(planEditData.noOfClick,
                    <Input
                      type="number"
                      required
                      m={{ b: { xs: "0.5rem" } }}
                      placeholder="No. of Clicks"
                      defaultValue={planEditData.noOfClick}
                      onChange={handleChangePlanEdit("noOfClick")}
                    />)
                }
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                No. of Link
                  {
                  showifNotUnlimited(planEditData.noOfLink,
                    <Input
                      required
                      m={{ b: { xs: "0.5rem" } }}
                      type="number"
                      placeholder="No. of Link"
                      defaultValue={planEditData.noOfLink}
                      onChange={handleChangePlanEdit("noOfLink")}
                    />)
                }

              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                No. of Edit
                  {
                  showifNotUnlimited(planEditData.noOfedit,
                    <Input
                      required
                      m={{ b: { xs: "0.5rem" } }}
                      type="number"
                      placeholder="No. of Edit"
                      defaultValue={planEditData.noOfedit}
                      onChange={handleChangePlanEdit("noOfedit")}
                    />)
                }

              </Col>

              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                Price
                  <Input
                  m={{ b: { xs: "0.5rem" } }}
                  required
                  type="number"
                  placeholder="Price"
                  defaultValue={planEditData.price}
                  onChange={handleChangePlanEdit("price")}
                />
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                Offer Price
                  <Input
                  required
                  type="number"
                  m={{ b: { xs: "0.5rem" } }}
                  placeholder="Price - Offer Price"
                  defaultValue={planEditData.offerPrice}
                  onChange={handleChangePlanEdit("offerPrice")}
                />
              </Col>
              {(planEditData.type == "ENTERPRISE" || planEditData.type == "INFLUENCERS") && (
                <Col size={{ xs: "12", md: "6", lg: "4" }}>
                  Count Of Months
                  {
                    showifNotUnlimited(planEditData.noofmonths,
                      <Input
                        required
                        type="number"
                        m={{ b: { xs: "0.5rem" } }}
                        placeholder="Months"
                        defaultValue={planEditData.noofmonths}
                        onChange={handleChangePlanEdit("noofmonths")}
                      />)
                  }
                </Col>
              )}


            </Row>

            <Row>

              {(planEditData.type == "ENTERPRISE" || planEditData.type == "INFLUENCERS") && (
                <Col size={{ xs: "12", md: "6", lg: "4" }}>
                  <Label
                    p={{ t: "0.6rem" }}
                    onClick={(e) => {
                      changeToUnlimitedEdit("noofmonths")
                    }}
                    align="center"
                    m={{ b: "1rem" }}
                  >
                    <Switch checked={CheckUnlimited(planEditData.noofmonths)} />
                  Unlimited Months
                </Label>
                </Col>
              )}
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    setPlanEditData({
                      ...planEditData,
                      appLinkOrNot: !planEditData.appLinkOrNot,
                    });
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={planEditData.appLinkOrNot} />
                  Custom Target URL
                  </Label>
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    setPlanEditData({
                      ...planEditData,
                      forReferral: !planEditData.forReferral,
                    });
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={planEditData.forReferral} />
                  For Referral
                </Label>
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    changeToUnlimitedEdit("noOfClick")
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={CheckUnlimited(planEditData.noOfClick)} />
                  Unlimited Click
                </Label>
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    changeToUnlimitedEdit("noOfLink")
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={CheckUnlimited(planEditData.noOfLink)} />
                  Unlimited Links
                </Label>
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    changeToUnlimitedEdit("noofbrand")
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={CheckUnlimited(planEditData.noofbrand)} />
                  Unlimited Brands
                </Label>
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    changeToUnlimitedEdit("noofbrandlink")
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={CheckUnlimited(planEditData.noofbrandlink)} />
                  Unlimited Brand link
                </Label>
              </Col>
              <Col size={{ xs: "12", md: "6", lg: "4" }}>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    changeToUnlimitedEdit("noOfedit")
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={CheckUnlimited(planEditData.noOfedit)} />
                  Unlimited Edit
                </Label>
              </Col>
            </Row>

          </Col>
        </Row>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setShowEditPlanModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>

          <Button
            bg="info700"
            isLoading={isEditPlanLoading}
            onClick={(e) => saveEdits(e)}
          >
            Save
          </Button>
        </Div>
        {/* </form> */}
      </Modal>
    );
  };

  return (
    <AdminDashboardLayout
      isLoading={planData.isLoading}
      notification={notificationData}
      onClose={(e) => {
        setNotificationData({
          content: false,
        });
      }}
    >
      {DeletePlanModal()}
      {EditPlanModal()}
      <Div style={{ width: "Calc(100% - 10px)" }}>
        <Row justify="flex-end">
          <Button
            bg="info700"
            hoverBg="info600"
            onClick={(e) => setShowAddPlanModal(true)}
            m={{ r: "0.5rem", b: "0.5rem" }}
          >
            Add Plan
        </Button>
          {AddPlanModal()}
        </Row>
        <Table
          data={[
            {
              title: "ID",
              key: "id",
            },
            {
              title: "Title",
              key: "title",
            },
            {
              title: "Plan Type",
              key: "type",
            },
            {
              title: "User",
              row: [
                (data, key) => (data.email == null ? "Public Plan" : data.email),
              ],
            },
            {
              title: "No of Link",
              row: [
                (data, key) =>
                  CheckUnlimited(data.noOfLink) ? ("Unlimited") : data.noOfLink
              ],
            },
            {
              title: "No of Clicks",
              row: [
                (data, key) =>
                  CheckUnlimited(data.noOfClick) ? ("Unlimited") : data.noOfClick
              ],
            },
            {
              title: "No of Edit",
              row: [
                (data, key) =>
                  CheckUnlimited(data.noOfedit) ? ("Unlimited") : data.noOfedit
              ],
            },
            {
              title: "No of Brands",
              row: [
                (data, key) =>
                  CheckUnlimited(data.noofbrand) ? ("Unlimited") : data.noofbrand
              ],
            },
            {
              title: "No of Brand links",
              row: [
                (data, key) =>
                  CheckUnlimited(data.noofbrandlink) ? ("Unlimited") : data.noofbrandlink
              ],
            },
            {
              title: "App Linkable",
              row: [
                (data, key) =>
                  data.appLinkOrNot ? (
                    <Icon name="Checked" color="success800" size="20px" />
                  ) : (
                    <Icon name="Cross" color="danger800" size="20px" />
                  ),
              ],
            },
            {
              title: "For referal",
              row: [
                (data, key) =>
                  data.forReferral ? (
                    <Icon name="Checked" color="success800" size="20px" />
                  ) : (
                    <Icon name="Cross" color="danger800" size="20px" />
                  ),
              ],
            },
            {
              title: "Price",
              key: "price",
            },
            {
              title: "Offer Price",
              key: "offerPrice",
            },
            {
              title: "Subdomain",
              row: [
                (data, key) => (
                  <Div>
                    {data.subDomain.map((item, i) => {
                      return (
                        <Div>
                          <Icon name="Dot" size="10px" /> {item}
                        </Div>
                      );
                    })}
                  </Div>
                ),
              ],
            },
            {
              title: "Manage",
              row: [
                (data, key) => (
                  <Button
                    key={key}
                    h="2rem"
                    w="2rem"
                    bg="info300"
                    hoverBg="info400"
                    rounded="lg"
                    m={{ x: "0.5rem" }}
                    onClick={(e) => {
                      setPlanEditData(data);
                      setShowEditPlanModal(true);
                    }}
                  >
                    <Icon name="Edit" color="info800" size="18px" />
                  </Button>
                ),
                (data, key) => (
                  <Button
                    key={key}
                    h="2rem"
                    w="2rem"
                    bg="danger300"
                    hoverBg="danger400"
                    rounded="lg"
                    m={{ x: "0.5rem" }}
                    onClick={(e) => {
                      setShowDeletePlanModal(true);
                      setDeletePlanId(data.id);
                    }}
                  >
                    <Icon name="Delete" color="danger800" size="18px" />
                  </Button>
                ),
              ],
            },
          ]}
          value={planData.data}
        ></Table>
      </Div>

    </AdminDashboardLayout>
  );
};

export default Plans;

import React, { useState,useEffect } from "react";
import UserDashboardLayout from "./UserDashboardLayout";
import { Div, Button, Icon, Row, Modal, Col, Text, Input } from "atomize";
import { isAuthenticated, updateProfileValuesLocal } from "../../core";
import { changePassword, updateProfile } from "../../core/apiUser";
import { countryList, stateList } from "../../core/apiCore";
import moment from "moment";
import DropDown from "../../components/DropDown";
import { Fragment } from "react";

const Settings = () => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { user } = isAuthenticated();
  const [passwordData, setPasswordData] = useState({
    password: null,
    newPassword: null,
    confirmPassword: "",
    email: user.email,
  });

  const checkPassword=()=>{
    let res=[];
    if(!passwordData.newPassword || passwordData.newPassword==null){
      return [];
    }
    if(passwordData.newPassword.length==0){
      return [];
    }
    if(passwordData.confirmPassword.length>0&&(passwordData.confirmPassword!=passwordData.newPassword)){
      res.push({msg:"Password must be equal"})
    }
    if(passwordData.newPassword.length<8){
      res.push({msg:"Password must contain 8 character"})
    }
    if(!/[A-Z]/.test(passwordData.newPassword)){
      res.push({msg:"Password must contain an uppercase letter"})
    }
    if(!/[a-z]/.test(passwordData.newPassword)){
      res.push({msg:"Password must contain an lowercase letter"})
    }
    if(!/\d/.test(passwordData.newPassword)){
      res.push({msg:"Password must contain a number"})
    }
    return res;
  }

  const [showUpdateProfileModal, setShowUpdateProfileModal] = useState(false);
  const [profileData, setProfileData] = useState({
    currentData: {
      name: null,
      gstid:null,
      address: null,
      state: null,
      country: null,
      phone: null
    },
  });

  useEffect(() => {
    console.log('====================================');
    console.log(profileData);
    console.log('====================================');
  }, [profileData])
  const handleChangeProfileUpdate = (name) => (event) => {
    setProfileData({ ...profileData, [name]: event.target.value });
  };

  const [isProfileUpdateLoading, setIsProfileUpdateLoading] = useState(false);



  const checkgst=(profileData)=>{
    let res=[];
    if(!profileData.gstid){
      return "";
    }
    if(profileData.gstid.length==0){
      return "";
    }
    if(profileData.gstid.length>15){
      res ="Enter a valid GSTIN";
    }
    if(profileData.gstid.length<15){
      res ="Enter a valid GSTIN";
    }
    if(!/[A-Z]/.test(profileData.gstid) && !/[a-z]/.test(profileData.gstid)){
      res ="Enter a valid GSTIN";
    }
    if(!/\d/.test(profileData.gstid)){
      res ="Enter a valid GSTIN";
    }
    if(profileData.gstid[13]!=="z" && profileData.gstid[13]!=="Z" ){
      res ="Enter a valid GSTIN";
    }
    return res;
  }

  const UpdateProfileModal = (params) => {
    return (
      <Modal
        isOpen={showUpdateProfileModal}
        rounded="md"
        onClose={() => setShowUpdateProfileModal(false)}
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setShowUpdateProfileModal(false)}
          cursor="pointer"
        />
        {/* <form onSubmit={(e) => handleChangePassword(e)}> */}
        <Row>
          <Col >
            <Text textAlign="center" textSize="title" m={{ b: "1rem" }}>
              Update Profile
            </Text>

            <Input
              required
              placeholder="Name"
              m={{ b: "1rem" }}
              defaultValue={profileData.currentData.name}
              type="text"
              w="100%"
              onChange={handleChangeProfileUpdate("name")}
            />

            <Input
              required
              placeholder="Address"
              m={{ b: "1rem" }}
              defaultValue={profileData.currentData.address}
              type="text"
              w="100%"
              onChange={handleChangeProfileUpdate("address")}
            />
            <Input
              required
              placeholder="GST  IN"
              m={{ b: "1rem" }}
              defaultValue={profileData.currentData.gstid}
              type="text"
              w="100%"
              onChange={handleChangeProfileUpdate("gstid")}
            />
            {
              <Text textSize="tiny" m={{b:"3px"}} textColor="danger700">{checkgst(profileData)}</Text>
            }
            <Input
              required
              placeholder="Phone"
              m={{ b: "1rem" }}
              defaultValue={profileData.currentData.phone}
              type="text"
              w="100%"
              onChange={handleChangeProfileUpdate("phone")}
            />
            Country
            <DropDown
              datas={countryList}
              valueKey="name"
              defaultValueKey="name"
              defaultValue={profileData.currentData.country}
              title="Country"
              onChange={(data) => {
                let currentData = {...profileData.currentData};
                currentData.country = data.name;
                setProfileData({ ...profileData, country: data.name  });
                if(data.name!="India"){
                  setProfileData({ ...profileData,country: data.name, state: null,currentData:currentData });
                }
              }}
            ></DropDown>
            {
              (profileData.country == "India" || profileData.currentData.country == "India") && (
                <Fragment>
                  State
                  <DropDown
                    datas={stateList}
                    valueKey="name"
                    defaultValueKey="name"
                    defaultValue={profileData.currentData.state}
                    title="State"
                    onChange={(data) => {
                      setProfileData({ ...profileData, state: data.name });
                    }}
                  ></DropDown>
                </Fragment>
              )
            }
          </Col>
        </Row>
        <Text textSize="tiny" >Enter trade name and address as per records to claim your GST credit successfully.</Text>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setShowUpdateProfileModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(e) => handleUpdateProfile(e)}
            isLoading={isProfileUpdateLoading}
            bg="info700"
          >
            Update Profile
          </Button>
        </Div>
        {/* </form> */}
      </Modal>
    );
  };


  const handleUpdateProfile = (event) => {
    event.preventDefault();
    setIsProfileUpdateLoading(true);
    updateProfile(profileData)
      .then((data) => {
        if (data.error) {
          setIsProfileUpdateLoading(false);

          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {

          updateProfileValuesLocal(data, () => {
            setNotificationData({
              content: "Profile Updated",
              bg: "success700",
            });
            setIsProfileUpdateLoading(false);
            setShowUpdateProfileModal(false);
          });
        }
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);
  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });

  const { password, newPassword, confirmPassword } = passwordData;
  const handleChange = (name) => (event) => {
    setPasswordData({ ...passwordData, [name]: event.target.value });
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    if (newPassword == confirmPassword) {
      const { user } = isAuthenticated();
      setPasswordData({ ...passwordData, email: user.email });

      setIsChangePasswordLoading(true);

      changePassword(passwordData)
        .then((data) => {
          if (data.error) {
            setIsChangePasswordLoading(false);

            setNotificationData({
              content: JSON.parse(data.error).error,
              bg: "danger700",
            });
          } else {
            setIsChangePasswordLoading(false);

            setNotificationData({
              content: "Passowrd Changed",
              bg: "success700",
            });
            setShowChangePasswordModal(false);
          }
        })
        .catch((err) => {
          setNotificationData({
            content: err.error,
            bg: "danger700",
          });
        });
    } else {
      setNotificationData({
        content: "New Passowrd and Confirm Password Doesn't match",
        bg: "danger700",
      });
    }
  };
  const [showOldPass, setShowOldPass] = useState(false);

  const [showNewPass, setShowNewPass] = useState(false);

  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const ChangePasswordModal = (params) => {
    return (
      <Modal
        isOpen={showChangePasswordModal}
        rounded="md"
        onClose={() => setShowChangePasswordModal(false)}
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setShowChangePasswordModal(false)}
          cursor="pointer"
        />
        {/* <form onSubmit={(e) => handleChangePassword(e)}> */}
        <Row>
          <Col textAlign="center">
            <Text textSize="title" m={{ b: "1rem" }}>
              Change password
            </Text>

            <Input
              required
              placeholder="Old Password"
              m={{ b: "1rem" }}
              type={ "password"}
              w="100%"
              onChange={handleChange("password")}
            />
            <Input
              required
              placeholder="New Password"
              m={{ b: "1rem" }}
              type={"password"}
              w="100%"
              onChange={handleChange("newPassword")}
            />
            <Input
              required
              placeholder="Confirm Password"
              m={{ b: "1rem" }}
              w="100%"
              type={showConfirmPass ? "text" : "password"}
              onChange={handleChange("confirmPassword")}
              suffix={
                <Button
                  pos="absolute"
                  onClick={(e) => setShowConfirmPass(!showConfirmPass)}
                  bg="transparent"
                  w="3rem"
                  top="0"
                  right="0"
                  rounded={{ r: "md" }}
                >
                  <Icon
                    name={showConfirmPass ? "EyeSolid" : "Eye"}
                    color={showConfirmPass ? "danger800" : "success800"}
                    size="16px"
                  />
                </Button>
              }
            />
          </Col>
        </Row>
        {checkPassword().map((item,i)=>(
          <Fragment key={i}>
            <Text textSize="caption" textColor="danger700">{item.msg}</Text>
          </Fragment>
        ))}
        <Text textSize="tiny" >Please use forgot password if you have used login with google</Text>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setShowChangePasswordModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(e) => handleChangePassword(e)}
            isLoading={isChangePasswordLoading}
            bg="info700"
          >
            Change Password
          </Button>
        </Div>
        {/* </form> */}
      </Modal>
    );
  };
  return (
    <UserDashboardLayout
      notification={notificationData}
      onClose={(e) => {
        setNotificationData({
          content: false,
        });
      }}
    >
      {ChangePasswordModal()}
      {UpdateProfileModal()}
      <Div
        bg="gray300"
        w="100%"
        rounded="md"
        p={{ y: "1rem", x: "1rem" }}
      >
        <Text textSize="title">{user.name}</Text>
        <Text>{user.email}</Text>
        <Text>{user.address}</Text>
        <Text>{user.country}</Text>
        <Text>{user.state}</Text>
        <Text>{user.gstid}</Text>
        <Text>{user.phone}</Text>
        <Text>Joined on: {moment(user.createdAt).format("DD-MM-YYYY")}</Text>
      </Div>
      <Button m={{ t: "20px" }} bg="info700" onClick={(e) => setShowChangePasswordModal(true)}>
        Change Password
        </Button>
      <Button m={{ t: "20px" }} bg="info700" onClick={(e) => {
        setProfileData({ ...profileData, currentData: user })
        setShowUpdateProfileModal(true)
      }}>
        Update Profile
        </Button>
    </UserDashboardLayout>
  );
};

export default Settings;

import React, { useEffect, useState } from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { getBrandUrlAnalytics, getUrlAnalytics } from "../../core/apiUser";
import { Div, Button, Col, Row, Modal, Textarea, Text } from "atomize";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  SplineSeries,
  ZoomAndPan,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { EventTracker, Title } from "@devexpress/dx-react-chart";
import DropDown from "../../components/DropDown";

const ChartAdminBrandUrl = ({ match }) => {
  const [data, setDatas] = useState({
    analytics: [],
    url: {},
  });
  const [isLoading, setIsLoading] = useState(true);
  const [target, setTarget] = useState(null);
  const [months, setMonths] = useState(1);

  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });

  const init = (urlId, months) => {
    setIsLoading(true);
    getBrandUrlAnalytics(urlId, months)
      .then((res) => {
        if (res.error) {
          setIsLoading(false);
          setNotificationData({
            content: JSON.parse(res.error).error,
            bg: "danger700",
          });
        } else {
          setIsLoading(false);
          let data = res.data;
          let f = [];
          for (let i = 0; i < data.length; i++) {
            let date = new Date(data[i].createdAt).toJSON().split("T")[0];
            let index = f.findIndex((x) => x.x === date);
            if (index != -1) {
              f[index].y++;
            } else {
              f.push({ x: date, y: 1 });
            }
          }
          setDatas({ ...data, analytics: f, url: res.url });
        }
      })
      .catch((e) => {
        setNotificationData({
          content: e.error,
          bg: "danger700",
        });
        setIsLoading(false);
      });
  };
  useEffect(() => {
    init(match.params.brandUrlId, months);
  }, []);

  const chartDy = () => {
    if (window.screen.availWidth <990) {
      return "80px";
    } else {
      return "1rem";
    }
  };

  const ArgumentLabel = (props) => {
    const { text } = props;
    return (
      <ArgumentAxis.Label
        {...props}
        text={`${text}`}
        dy={chartDy()}
        className="rotate-sm"
      />
    );
  };

  return (
    <AdminDashboardLayout
      notification={notificationData}
      onClose={(e) => {
        setNotificationData({
          content: false,
        });
      }}
      isLoading={isLoading}
    >
      <Text textSize="title" m={{ b: "10px" }}>
        Url - {data.url && data.url.title}{" "}
      </Text>
      <Text textSize="body" m={{ b: "20px" }}>
        {" "}
        <a href={data.url && data.url.url}>{data.url && data.url.url}</a>{" "}
      </Text>
      <Div h="360px">
        <Col size="3">
          <DropDown
            datas={[
              {
                value: 1,
                title: "This Week",
              },
              {
                value: 4,
                title: "This month",
              },
              {
                value: 8,
                title: "Last two month",
              },
              {
                value: 12,
                title: "Last three month",
              },
              {
                value: 9999,
                title: "Life time",
              },
            ]}
            valueKey="title"
            title="This Week"
            defaultValue={months}
            defaultValueKey="value"
            onChange={(data) => {
              setMonths(data.value);
              init(match.params.brandUrlId, data.value);
            }}
          ></DropDown>
        </Col>
        <br />
        <Chart height="260" data={data.analytics}>
            <ArgumentAxis
            labelComponent={ArgumentLabel} />
          <ValueAxis />

          <SplineSeries name="Clicks" valueField="y" argumentField="x" />
          <ZoomAndPan />
          <EventTracker />
          <Tooltip
            contentComponent={(e) => {
              return <Div>{e.text} Clicks</Div>;
            }}
            targetItem={target}
            onTargetItemChange={(e) => setTarget(e)}
          />
        </Chart>
      </Div>
    </AdminDashboardLayout>
  );
};

export default ChartAdminBrandUrl;

import React, { useEffect, useState } from "react";
import {
  Div,
  Icon,
  Button,
  Container,
  Notification,
  Text,
  Input,
} from "atomize";
import { Link, Redirect, useHistory } from "react-router-dom";
import { forgetPassword } from "../core/index";

const ForgotPassword = () => {
  // const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [data, setData] = useState({
    email: "",
    content: false,
    isLoading: false,
    bg: "",
  });

  const submitEmail = (params) => {
    console.log(email);
    setIsLoading(true);
    forgetPassword({ email: email })
      .then((data) => {
        if (data.error) {
          console.log(data.error);
          setIsLoading(false);
          setData({
            ...data,
            content: JSON.parse(data.error)?.error,
            bg: "danger700",
            icon: "CloseSolid",
          });
        } else {
          setIsLoading(false);
          setData({
            ...data,
            content: "Please Check Your mail for reset link",
            bg: "success700",
            icon: "Checked",
          });
          //
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setData({ ...data, content: err.error });
        console.log(err);
      });
  };

  const showError = () => {
    return (
      <Notification
        bg={data.bg}
        isOpen={data.content}
        onClose={() => setData({ ...data, content: false })}
        prefix={
          <Icon
            name={data.icon}
            color="white"
            size="18px"
            m={{ r: "0.5rem" }}
          />
        }
      >
        {data.content && data.content}
      </Notification>
    );
  };


  
  return (
    <Container p={{ t: "20px" }} h="100vh">
      <br />
      <br />
      <br />
      {showError()}
      <Div textAlign="center">
        <img src="/img/logo.svg" width="100px" alt="logo" />
      </Div>
      <Text textAlign="center" textSize="display1" tag="h4">
      UTUBELINK
      </Text>
      <br />
      <Div
        m={{ xs: "20px", md: "auto" }}
        bg="white"
        shadow="5"
        rounded="xl"
        p="20px"
        maxW={{ xs: "auto", md: "350px" }}
      >
        <Text textAlign="center" textSize="subheading" m={{ b: "0.5rem" }}>
          Forgot Password
        </Text>

        <Input
          placeholder="Email"
          p={{ x: "2.5rem" }}
          m={{ b: "1rem" }}
          prefix={
            <Icon
              name="UserSolid"
              color="warning800"
              size="16px"
              cursor="pointer"
              pos="absolute"
              top="50%"
              left="0.75rem"
              transform="translateY(-50%)"
            />
          }
          onChange={(e) => setEmail(e.target.value )}
        />

        <Div d="flex" justify="center">
          <Button
            p={{ x: "2.5rem" }}
            hoverShadow="2"
            m={{ r: "1rem" }}
            bg="info800"
            hoverBg="info700"
            onClick={submitEmail}
            isLoading={isLoading}
          >
            Send an Email
          </Button>
        </Div>
      </Div>
    </Container>
  );
};

export default ForgotPassword;

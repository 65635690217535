import React, { useEffect, useState } from "react";
import UserDashboardLayout from "./UserDashboardLayout";
import { getBrandUrlAnalytics, getUrlAnalytics } from "../../core/apiUser";
import { Div, Button, Col, Row, Modal, Textarea, Text } from "atomize";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  SplineSeries,
  ZoomAndPan,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { EventTracker, Title } from "@devexpress/dx-react-chart";
import DropDown from "../../components/DropDown";
import { Icon } from "@material-ui/core";

const BrandUrlAnalytics = ({ match }) => {
  const [data, setDatas] = useState({
    analytics: [],
    deviceandBrowsers: {},
    url: {},
  });
  const [isLoading, setIsLoading] = useState(true);
  const [target, setTarget] = useState(null);
  const [weeks, setWeeks] = useState(1);

  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });

  const init = (urlId, weeks) => {
    setIsLoading(true);
    getBrandUrlAnalytics(urlId, weeks)
      .then((res) => {
        if (res.error) {
          setIsLoading(false);
          setNotificationData({
            content: JSON.parse(res.error).error,
            bg: "danger700",
          });
        } else {
          setIsLoading(false);

          let data = res.data;
          let f = [];
          let deviceandBrowser = {};
          for (let i = 0; i < data.length; i++) {
            let el = data[i]
            let date = new Date(el.createdAt).toJSON().split("T")[0];
            let index = f.findIndex((x) => x.x === date);
            if (index != -1) {
              f[index].y++;
              if (!f[index].device[el.device]) {
                f[index].device[el.device] = 1;
              } else {
                f[index].device[el.device]++;
              }
              if (!f[index].device[el.browser]) {
                f[index].device[el.browser] = 1;
              } else {
                f[index].device[el.browser]++;
              }
              if (!deviceandBrowser[el.device]) {
                deviceandBrowser[el.device] = 1;
              } else {
                deviceandBrowser[el.device]++;
              }
              if (!deviceandBrowser[el.browser]) {
                deviceandBrowser[el.browser] = 1;
              } else {
                deviceandBrowser[el.browser]++;
              }
            } else {
              if (!deviceandBrowser[el.device]) {
                deviceandBrowser[el.device] = 1;
              }
              if (!deviceandBrowser[el.browser]) {
                deviceandBrowser[el.browser] = 1;
              }
              f.push({ x: date, y: 1, device: { [el.device]: 1 }, browser: { [el.browser]: 1 } });
            }
          }
          setDatas({ ...data, analytics: f, deviceandBrowsers: deviceandBrowser, url: res.url });
        }
      })
      .catch((e) => {
        setNotificationData({
          content: e.error,
          bg: "danger700",
        });
        setIsLoading(false);
      });
  };
  useEffect(() => {
    init(match.params.brandUrlId, weeks);
  }, []);

  return (
    <UserDashboardLayout
      notification={notificationData}
      onClose={(e) => {
        setNotificationData({
          content: false,
        });
      }}
      isLoading={isLoading}
    >
      <Text textSize="title" m={{ b: "10px" }}>
        Url - {data.url && data.url.title}
      </Text>
      <Text textSize="body" m={{ b: "20px" }}>
        <a href={data.url && data.url.url}>{data.url && data.url.url}</a>{" "}
      </Text>
      <Div h="360px">
        <Col size="3">
          <DropDown
            datas={[
              {
                value: 1,
                title: "This Week",
              },
              {
                value: 4,
                title: "This month",
              },
              {
                value: 8,
                title: "Last two month",
              },
              {
                value: 12,
                title: "Last three month",
              },
              {
                value: 9999,
                title: "Life time",
              },
            ]}
            valueKey="title"
            title="This Week"
            defaultValue={weeks}
            defaultValueKey="value"
            onChange={(data) => {
              setWeeks(data.value);
              init(match.params.brandUrlId, data.value);
            }}
          ></DropDown>
        </Col>
        <br />
        <Chart height="260" data={data.analytics}>
          <ArgumentAxis />
          <ValueAxis />

          <SplineSeries name="Clicks" valueField="y" argumentField="x" />
          <ZoomAndPan />
          <EventTracker />
          <Tooltip
            contentComponent={(e) => {
              let analytics = [];
              Object.keys(data.analytics[e.targetItem.point].device).forEach((key) => {
                analytics.push(<Text>{key} :{data.analytics[e.targetItem.point].device[key]}</Text>);
              })
              Object.keys(data.analytics[e.targetItem.point].browser).forEach((key) => {
                analytics.push(<Text>{key} :{data.analytics[e.targetItem.point].browser[key]}</Text>);
              })
              console.log(analytics);
              return <Div>
                <Text tag="h1">Total Clicks:{e.text}</Text>
                {analytics.map((item, i) => (item))}
              </Div>;
            }}
            targetItem={target}
            onTargetItemChange={(e) => {
              setTarget(e)
            }}
          />
        </Chart>
      </Div>
      <br />
      <Row>
      {
        Object.keys(data.deviceandBrowsers).map((key, i) => (
          <Div
            bg="white"
            rounded="md"
            shadow="5"
            h="100px"
            m={{ y: "15px",r:"15px" }}
            p="20px"
          >
            <Text textWeight="500"> {key} </Text>
            <Row justify="flex-end" align="flex-end">

              <Text
                textAlign="right"
                textSize="display1"
                textWeight="700"
              >
                {data.deviceandBrowsers[key]}
              </Text>
            </Row>
          </Div>
        ))
      }
      </Row>
      <Text textSize="tiny">*Analytics may not be accurate.</Text>
    </UserDashboardLayout>
  );
};

export default BrandUrlAnalytics;

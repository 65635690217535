import React, { useState, useEffect } from "react";
import {
  Div,
  Icon,
  Button,
  Row,
  Textarea,
  Modal,
  Text,
  Input,
  Col,
  Label,
  Switch,
} from "atomize/dist";
import Table from "../../components/Table";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { getUsers, changeUserStatus, createMessage } from "../../core/apiAdmin";
import { isAuthenticated } from "../../core";
import { Link } from "react-router-dom";
import moment from "moment";
import exportFromJSON from "export-from-json";
import DropDown from "../../components/DropDown";

export const Users = () => {
  let date = new Date();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterData, setFilterData] = useState({
    startdate: "2020-02-06",
    enddate: new Date(date.setDate(date.getDate() + 1)).toISOString().slice(0, 10),
    nolink: false,
    nobrand: false,
    noplan: false,
    planType: 'all',
  });

  const [usersData, setUsersData] = useState({
    data: [],
    isLoading: false,
    error: false,
  });
  const { user } = isAuthenticated();
  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });

  const [showCreateMessageModal, setShowCreateMessageModal] = useState(false);
  const [createUserMessageData, setCreateUserMessageData] = useState({
    title: "",
    content: "",
    userID: null,
    isLoading: false,
  });
  const { isLoading, showModal } = createUserMessageData;
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);

  const fetchUsers = (params) => {
    setUsersData({ ...usersData, isLoading: true });
    getUsers(params)
      .then((data) => {
        if (data.error) {
          setUsersData({ ...usersData, isLoading: false });
        } else {
          console.log('====================================');
          console.log(data);
          console.log('====================================');
          setUsersData({ ...usersData, isLoading: false, data: data });
        }
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  const exportTocsv = (data) => {
    for (var i = 0; i < data.length; i++) {
      delete data[i]['id'];
      delete data[i]['role'];
      delete data[i]['status'];
      delete data[i]['createdAt'];
    }
    exportFromJSON({
      data: data,
      fileName: "Users email",
      exportType: "csv",
    });
  }

  const handleChange = (name) => (event) => {
    setCreateUserMessageData({
      ...createUserMessageData,
      [name]: event.target.value,
    });
  };

  const sendMessage = (params) => {
    setCreateUserMessageData({ ...createUserMessageData, isLoading: true });

    createMessage(createUserMessageData)
      .then((data) => {
        if (data.error) {
          setCreateUserMessageData({
            ...createUserMessageData,
            isLoading: false,
          });

          setNotificationData({
            content: JSON.parse(data.error).error,

            bg: "danger700",
          });
        } else {
          setCreateUserMessageData({
            ...createUserMessageData,
            isLoading: false,
          });

          setNotificationData({
            content: "Message Sent",
            bg: "success700",
          });
          setShowCreateMessageModal(false);
        }
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  const CreateMessageModal = (params) => {
    return (
      <Modal
        isOpen={showCreateMessageModal}
        onClose={(e) => {
          setShowCreateMessageModal(false);
        }}
        align="center"
        rounded="md"
      >
        <Text textAlign="center" textSize="title" m={{ b: "1rem" }}>
          Send Notification
        </Text>
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => {
            setShowCreateMessageModal(false);
            setCreateUserMessageData({
              ...createUserMessageData,
              userID: null,
            });
          }}
          cursor="pointer"
        />
        Title
        <Input
          placeholder="Title"
          m={{ b: "1rem" }}
          w="100%"
          onChange={handleChange("title")}
        />
        Content
        <Textarea
          placeholder="Content"
          m={{ b: "1rem" }}
          w="100%"
          onChange={handleChange("content")}
        />
        <Div d="flex" justify="flex-end">
          <Button
            bg="gray200"
            onClick={(e) => {
              setShowCreateMessageModal(false);
              setCreateUserMessageData({
                ...createUserMessageData,
                userID: null,
              });
            }}
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            bg="info700"
            isLoading={createUserMessageData.isLoading}
            onClick={(e) => sendMessage()}
          >
            Send
          </Button>
        </Div>
      </Modal>
    );
  };


  const filterModal = () => {
    return (
      <Modal
        isOpen={showFilterModal}
        onClose={(e) => {
          setShowFilterModal(false);
        }}
        align="center"
        maxW="50rem"
        rounded="md"
      >
        <Text textAlign="center" textSize="title" m={{ b: "1rem" }}>
          Filter Users
        </Text>
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => {
            setShowFilterModal(false);
          }}
          cursor="pointer"
        />
        <Row>

          <Col size={{ xs: "12", md: "6", lg: "4" }}>
            Plan Type
            <DropDown
              datas={[
                { value: "FREE", title: "FREE" },
                { value: "all", title: "ALL" },
                { value: "PREMIUM", title: "PREMIUM" },
                {
                  value: "ENTERPRISE",
                  title: "ENTERPRISE",
                },
                {
                  value: "INFLUENCERS",
                  title: "INFLUENCERS",
                },
              ]}
              valueKey="title"
              title="Plan Type"
              defaultValue={filterData.planType}
              defaultValueKey="value"
              onChange={(data) => {
                setFilterData({ ...filterData, planType: data.value });
              }}
            ></DropDown>
          </Col>
          <Col size={{ xs: "12", md: "6", lg: "4" }}>
            <Label
              p={{ t: "0.6rem" }}
              onClick={(e) => {
                setFilterData({
                  ...filterData,
                  nolink: !filterData.nolink,
                });
              }}
              align="center"
              m={{ b: "1rem" }}
            >
              <Switch checked={filterData.nolink} />
                No link created
              </Label>
          </Col>
          <Col size={{ xs: "12", md: "6", lg: "4" }}>
            <Label
              p={{ t: "0.6rem" }}
              onClick={(e) => {
                setFilterData({
                  ...filterData,
                  nobrand: !filterData.nobrand,
                });
              }}
              align="center"
              m={{ b: "1rem" }}
            >
              <Switch checked={filterData.nobrand} />
                No brand created
              </Label>
          </Col>
          <Col size={{ xs: "12", md: "6", lg: "4" }}>
            <Label
              p={{ t: "0.6rem" }}
              onClick={(e) => {
                setFilterData({
                  ...filterData,
                  noplan: !filterData.noplan,
                });
              }}
              align="center"
              m={{ b: "1rem" }}
            >
              <Switch checked={filterData.noplan} />
                No Paid membership taken              </Label>
          </Col>
          <Col size={{ xs: "12", md: "6", lg: "4" }}>
            <Label
              p={{ t: "0.6rem" }}
              align="center"
            >
              Start date              </Label>
            <Input type="date" onChange={(e) => setFilterData({
              ...filterData,
              startdate: e.target.value,
            })} placeholder="Start date" />
          </Col>
          <Col size={{ xs: "12", md: "6", lg: "4" }}>
            <Label
              p={{ t: "0.6rem" }}
              align="center"
            >
              End date              </Label>
            <Input type="date" onChange={(e) => setFilterData({
              ...filterData,
              enddate: e.target.value,
            })} placeholder="End date" />
          </Col>
        </Row>

        <Div d="flex" p={{ t: "20px" }} justify="flex-end">
          <Button
            bg="gray200"
            onClick={(e) => {
              setShowFilterModal(false);
            }}
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            bg="gray200"
            onClick={(e) => {
              setFilterData({
                startdate: "2020-02-06",
                enddate: new Date().toISOString().slice(0, 10),
                nolink: false,
                nobrand: false,
                noplan: false,
                planType: "all",
              });
              fetchUsers({
                startdate: "2020-02-06",
                enddate: new Date().toISOString().slice(0, 10),
                nolink: false,
                nobrand: false,
                noplan: false,
                planType: "all",
              })
              setShowFilterModal(false);
            }}
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Clear
          </Button>
          <Button
            bg="info700"
            isLoading={createUserMessageData.isLoading}
            onClick={(e) => {
              fetchUsers(filterData)
              setShowFilterModal(false);
            }}
          >
            Filter
          </Button>
        </Div>
      </Modal>
    );
  };

  const [changeStatusData, setChangeStatusData] = useState({
    status: "",
  });

  const [isChangeStatusLoading, setIsChangeStatusLoading] = useState(false);
  const changeStatus = (params) => {
    setIsChangeStatusLoading(true);

    changeUserStatus(changeStatusData)
      .then((data) => {
        if (data.error) {
          setShowChangeStatusModal(false);

          setNotificationData({
            content: "Error Occured",
            bg: "danger700",
          });
        } else {
          setShowChangeStatusModal(false);

          setNotificationData({
            content: "User Status Changed",
            bg: "success700",
          });
          setShowChangeStatusModal(false);
        }
        setIsChangeStatusLoading(false);
        fetchUsers(filterData);
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger00",
        });
      });
  };
  const ChangeStatusModal = (params) => {
    return (
      <Modal
        isOpen={showChangeStatusModal}
        onClose={() => setShowChangeStatusModal(false)}
        rounded="md"
        align="start"
        rounded="md"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setShowChangeStatusModal(false)}
          cursor="pointer"
        />
        <Div d="flex" m={{ b: "4rem" }}>
          <Icon
            name="AlertSolid"
            color="warning700"
            m={{ t: "0.35rem", r: "0.5rem" }}
          />
          <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="subheader">
            Are you sure to{" "}
            {changeStatusData.status == "active" ? "unban" : "ban"} user?
          </Text>
        </Div>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setShowChangeStatusModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            isLoading={isChangeStatusLoading}
            onClick={(e) => changeStatus()}
            bg={
              changeStatusData.status == "active" ? "success700" : "danger700"
            }
          >
            {changeStatusData.status == "active" ? "Unban" : "Ban"}
          </Button>
        </Div>
      </Modal>
    );
  };
  useEffect(() => {
    // date = date.setDate(date.getDate() + 1);
    fetchUsers(filterData);
  }, []);
  return (
    <Div>
      <AdminDashboardLayout
        isLoading={usersData.isLoading}
        notification={notificationData}
        onClose={(e) => {
          setNotificationData({
            content: false,
          });
        }}
      >
        <Div style={{ width: "Calc(100% - 10px)" }}>
          <Div d="flex">
            <Button
              bg="info700"
              hoverBg="info600"
              m={{ r: "0.5rem" }}
              onClick={(e) => {
                exportTocsv(usersData.data)
              }}
            >
              Export Email
          </Button>
            <Button
              bg="info700"
              hoverBg="info600"
              m={{ r: "0.5rem" }}
              onClick={(e) => {
                setShowFilterModal(true);
              }}
            >
              Filter
          </Button>
            <Button
              bg="info700"
              m={{ r: "0.5rem" }}
              hoverBg="info600"
              onClick={(e) => {
                setShowCreateMessageModal(true);
              }}
            >
              Add Message
          </Button>
          </Div>
          {filterModal()}
          {ChangeStatusModal()}
          {CreateMessageModal()}
          <Table
            data={[
              {
                title: "Name",
                key: "name",
              },
              {
                title: "Email",
                key: "email",
              },
              {
                title: "Joined On",
                row: [
                  (data, key) => moment(data.createdAt).format("DD-MM-YYYY "),
                ],
              },
              {
                title: "Status",
                key: "status",
              },
              {
                title: "Actions",
                row: [
                  (data, key) =>
                    data.status != "notverified" && (
                      <Button
                        key={key}
                        h="2rem"
                        w="2rem"
                        bg={data.status == "active" ? "danger300" : "info300"}
                        hoverBg={
                          data.status == "active" ? "danger400" : "info400"
                        }
                        rounded="lg"
                        m={{ r: "1rem" }}
                        onClick={(e) => {
                          setShowChangeStatusModal(true);
                          setChangeStatusData({
                            ...changeStatusData,
                            status: data.status == "active" ? "banned" : "active",
                            userid: data.id,
                          });
                          // setDeletePlanId(data.id);
                        }}
                      >
                        {" "}
                        <Icon
                          name={
                            data.status == "active" ? "DeleteSolid" : "Refresh"
                          }
                          size="20px"
                          color={
                            data.status == "active" ? "danger700" : "info700"
                          }
                        />
                      </Button>
                    ),
                  (data, key) =>
                    data.status != "notverified" && (
                      <Button
                        key={key}
                        h="2rem"
                        w="2rem"
                        bg="success300"
                        hoverBg="success400"
                        rounded="lg"
                        m={{ r: "1rem" }}
                        onClick={(e) => {
                          setShowCreateMessageModal(true);
                          setCreateUserMessageData({
                            ...createUserMessageData,
                            userID: data.id,
                          });
                          // setDeletePlanId(data.id);
                        }}
                      >
                        {" "}
                        <Icon name="MessageSolid" size="20px" color="success700" />
                      </Button>
                    ),
                  (data, key) => data.status != "notverified" && (
                    <Link to={`/admin/users/urls/list/${data.id}`}>
                      <Button
                        key={key}
                        h="2rem"
                        w="2rem"
                        bg="info300"
                        hoverBg="info400"
                        rounded="lg"
                        m={{ x: "0.5rem" }}
                      >
                        <Icon name="Link" color="info800" size="18px" />
                      </Button>
                    </Link>
                  ),
                  (data, key) => data.status != "notverified" && (
                    <Link to={`/admin/transactions/${data.id}`}>
                      <Button
                        key={key}
                        h="2rem"
                        w="2rem"
                        bg="success300"
                        hoverBg="success400"
                        rounded="lg"
                        m={{ x: "0.5rem" }}
                      >
                        <Icon name="Card" color="success800" size="18px" />
                      </Button>
                    </Link>
                  ),
                  (data, key) => data.status != "notverified" && (
                    <Link to={`/admin/currentavail/${data.id}`}>
                      <Button
                        key={key}
                        h="2rem"
                        w="2rem"
                        bg="warning300"
                        hoverBg="warning400"
                        rounded="lg"
                        m={{ x: "0.5rem" }}
                      >
                        <Icon name="Bag" color="warning800" size="18px" />
                      </Button>
                    </Link>
                  ),
                  (data, key) => data.status != "notverified" && (
                    <Link to={`/admin/users/brand/list/${data.id}`}>
                      <Button
                        key={key}
                        h="2rem"
                        w="2rem"
                        bg="info300"
                        hoverBg="info400"
                        rounded="lg"
                        m={{ x: "0.5rem" }}
                      >
                        <Icon name="Draft" color="black800" size="18px" />
                      </Button>
                    </Link>
                  ),
                ],
              },
            ]}
            value={usersData.data}
          ></Table>
        </Div>
      </AdminDashboardLayout>
    </Div>
  );
};

import { API } from "./config";

export const signUp = (user) => {
  return fetch(`${API}/signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const signin = (user) => {
  return fetch(`${API}/signin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const authenticate = (data, next) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("jwt", JSON.stringify(data));
    next();
  }
};

export const updateProfileValuesLocal = (data, next) => {
  if (typeof window !== "undefined") {
    try {
      let jwt = JSON.parse(localStorage.getItem("jwt"));
      jwt.user.name = data.name;
      jwt.user.address = data.address;
      jwt.user.country = data.country;
      jwt.user.state = data.state;
      jwt.user.gstid = data.gstid;
      jwt.user.phone = data.phone;

      localStorage.setItem("jwt", JSON.stringify(jwt));
      next();
    } catch (error) {
      console.log(error);
    }
  }
};

export const signout = (next) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("jwt");
    next();
    return fetch(`${API}/signout`, {
      method: "GET",
    })
      .then((res) => { })
      .catch((err) => console.log(err));
  }
};

export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("jwt")) {
    return JSON.parse(localStorage.getItem("jwt"));
  } else {
    return false;
  }
};

export const isAdmin = () => {
  if (isAuthenticated() && isAuthenticated().user.role == "0") {
    return true;
  } else {
    return false;
  }
};
export const forgetPassword = (body) => {
  console.log(body);
  return fetch(`${API}/forgotPassword`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      console.log(response);
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};
export const setNewPassword = (body, userid, token) => {
  console.log(body, userid, token);
  return fetch(`${API}/forgotPassword/set/${userid}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const continueWithGoogle = (user) => {
  return fetch(`${API}/externalsigin/google`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

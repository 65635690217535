import React, { useState, useEffect } from "react";
import { Div, Icon, Button, Row, Modal, Text, Input, Col } from "atomize/dist";
import Table from "../../components/Table";
import {
  getAdminUrls,
  editUrlAdmin,
  deleteUrlAdmin,
  getAdminUrlsByUserId,
  editBrandsUrlAdmin,
  deleteBrandsUrlAdmin,
} from "../../core/apiAdmin";
import { uri } from "../../core/config";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { Link } from "react-router-dom";
import { getBrandUrls } from "../../core/apiUser";

export const ListBrandUrls = ({ match }) => {
  const [urlData, setUrlData] = useState({
    data: [],
    brand:{},
    isLoading: false,
    error: false,
  });

  const fetchUrls = (params) => {
    setUrlData({ ...urlData, isLoading: true });
    getBrandUrls(match.params.brandId)
      .then((data) => {
        if (data.error) {
          setUrlData({ ...urlData, isLoading: false });
          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          console.log(data);
          setUrlData({ ...urlData, isLoading: false, data: data.data, brand: data.brand });
        }
      })

      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };
  useEffect(() => {
    fetchUrls();
  }, []);

  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });

  const [editUrlData, setEditUrlData] = useState({
    currentData: {
      title: null,
      brandurl: null,
      url_id: null,
      ios_url: null,
      and_url: null,
      mac_url: null,
      pc_url: null,
    },
    id: null,
  });

  const [isEditUrlLoading, setIsEditUrlLoading] = useState(false);
  const [showEditUrlModal, setShowEditUrlModal] = useState(false);
  const [showDeleteUrlModal, setShowDeleteUrlModal] = useState(false);
  const [deleteUrlId, setDeleteUrlId] = useState(null);
  const [isDeleteUrlLoading, setIsDeleteUrlLoading] = useState(false);

  const editUrlHandler = (url_id) => {
    setIsEditUrlLoading(true);

    editBrandsUrlAdmin(editUrlData, editUrlData.id)
      .then((data) => {
        if (data.error) {
          setIsEditUrlLoading(false);

          setNotificationData({
            content: data.error,
            bg: "danger700",
          });
        } else {
          setIsEditUrlLoading(false);
          setNotificationData({
            content: "URL Edited",
            bg: "success700",
          });
          setShowEditUrlModal(false);
          setEditUrlData({
            currentData: {
              title: null,
              url: null,
              url_id: null,
              ios_url: null,
              and_url: null,
              mac_url: null,
              pc_url: null,
            },
            id: null,
          });
        }

        fetchUrls();
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  const handleChangeEdit = (name) => (event) => {
    setEditUrlData({ ...editUrlData, [name]: event.target.value });
  };

  const deleteUrlHandler = () => {
    setIsDeleteUrlLoading(true);

    deleteBrandsUrlAdmin(deleteUrlId)
      .then((data) => {
        if (data.error) {
          setShowDeleteUrlModal(false);

          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          setShowDeleteUrlModal(false);
          setNotificationData({
            content: "URL Deleted",
            bg: "success700",
          });
          setShowDeleteUrlModal(false);
        }
        setIsDeleteUrlLoading(false);
        fetchUrls();
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  const DeleteUrlModal = (params) => {
    return (
      <Modal
        isOpen={showDeleteUrlModal}
        onClose={() => setShowDeleteUrlModal(false)}
        rounded="md"
        align="start"
        rounded="md"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setShowDeleteUrlModal(false)}
          cursor="pointer"
        />
        <Div d="flex" m={{ b: "4rem" }}>
          <Icon
            name="AlertSolid"
            color="warning700"
            m={{ t: "0.35rem", r: "0.5rem" }}
          />
          <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="subheader">
            Do you really want to delete URL?
          </Text>
        </Div>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setShowDeleteUrlModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            isLoading={isDeleteUrlLoading}
            onClick={(e) => deleteUrlHandler()}
            bg="info700"
          >
            Delete
          </Button>
        </Div>
      </Modal>
    );
  };
  const handleChangeUrlEdit = (e) => {
    const address = e.target.value;

    setEditUrlData({
      ...editUrlData,
      url: address,
      mac_url: address,
      pc_url: address,
      and_url: address,
      ios_url: address,
    });
  };
  const EditUrlModal = (data) => {
    const { mac_url, and_url, pc_url, ios_url, url } = editUrlData.currentData;
    const checkUrlLinkStatus = (params) => {
      return (mac_url == url &&
        and_url == url &&
        pc_url == url &&
        ios_url == url) ||
        mac_url == null ||
        and_url == null ||
        pc_url == null ||
        ios_url == null
        ? false
        : true;
    };
    return (
      <Modal
        isOpen={showEditUrlModal}
        onClose={() => setShowEditUrlModal(false)}
        rounded="md"
        maxW="48rem"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={() => setShowEditUrlModal(false)}
          cursor="pointer"
        />
        <Row
          p={{
            md: { l: "0.5rem", t: "0.25rem" },
          }}
          m={{ b: "2rem" }}
        >
          <Col>
            <Text textAlign="center" textSize="title" m={{ b: "1rem" }}>
              Edit
            </Text>
            <Row>
              <Col size={{ xs: "12", md: "6" }}>
                {" "}
                <Input
                  placeholder="Title"
                  //   p={{ x: "2.5rem" }}
                  defaultValue={editUrlData.currentData.title}
                  m={{ b: "1rem" }}
                  w="100%"
                  onChange={handleChangeEdit("title")}
                />
              </Col>
              <Col size={{ xs: "12", md: "6" }}>
                {" "}
                <Input
                  placeholder="URL Key"
                  //   p={{ x: "2.5rem" }}
                  defaultValue={editUrlData.currentData.url_id}
                  m={{ b: "1rem" }}
                  w="100%"
                  onChange={handleChangeEdit("url_id")}
                />
              </Col>
            </Row>
            <Input
              placeholder="Target URL"
              p={{ x: "2.5rem" }}
              m={{ b: "1rem" }}
              defaultValue={editUrlData.currentData.brandurl}
              w="100%"
              prefix={
                <Icon
                  name="Link"
                  color="warning800"
                  size="16px"
                  cursor="pointer"
                  pos="absolute"
                  top="50%"
                  left="0.75rem"
                  transform="translateY(-50%)"
                />
              }
              onChange={
                checkUrlLinkStatus
                  ? (e) => handleChangeUrlEdit(e)
                  : handleChangeEdit("url")
              }
            />
            {(mac_url == url &&
              and_url == url &&
              pc_url == url &&
              ios_url == url) ||
            mac_url == null ||
            and_url == null ||
            pc_url == null ||
            ios_url == null ? (
              ""
            ) : (
              <>
                PC Url
                <Input
                  placeholder="PC URL"
                  p={{ x: "2.5rem" }}
                  m={{ b: "1rem" }}
                  w="100%"
                  defaultValue={editUrlData.currentData.pc_url}
                  prefix={
                    <Icon
                      name="Link"
                      color="warning800"
                      size="16px"
                      cursor="pointer"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                    />
                  }
                  onChange={handleChangeEdit("pc_url")}
                />
                IOS Url
                <Input
                  placeholder="IOS URL"
                  p={{ x: "2.5rem" }}
                  m={{ b: "1rem" }}
                  w="100%"
                  defaultValue={editUrlData.currentData.ios_url}
                  prefix={
                    <Icon
                      name="Link"
                      color="warning800"
                      size="16px"
                      cursor="pointer"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                    />
                  }
                  onChange={handleChangeEdit("ios_url")}
                />
                Android Url
                <Input
                  placeholder="Android URL"
                  p={{ x: "2.5rem" }}
                  m={{ b: "1rem" }}
                  w="100%"
                  defaultValue={editUrlData.currentData.and_url}
                  prefix={
                    <Icon
                      name="Link"
                      color="warning800"
                      size="16px"
                      cursor="pointer"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                    />
                  }
                  onChange={handleChangeEdit("and_url")}
                />
                Mac Url
                <Input
                  placeholder="MAC URL"
                  p={{ x: "2.5rem" }}
                  m={{ b: "1rem" }}
                  w="100%"
                  defaultValue={editUrlData.currentData.mac_url}
                  prefix={
                    <Icon
                      name="Link"
                      color="warning800"
                      size="16px"
                      cursor="pointer"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                    />
                  }
                  onChange={handleChangeEdit("mac_url")}
                />
              </>
            )}{" "}
          </Col>
        </Row>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={() => setShowEditUrlModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            bg="info700"
            onClick={(e) => editUrlHandler(editUrlData.id)}
            isLoading={isEditUrlLoading}
          >
            Save
          </Button>
        </Div>
      </Modal>
    );
  };
  const listUrlBasedOnApp = (data) => {
    const { mac_url, and_url, pc_url, ios_url, brandurl } = data;
    if (mac_url == null || and_url == null || pc_url == null || ios_url == null)
      return (
        <Div>
          <Icon name="Dot" size="10px" /> Target URL:{" "}
          <a href={data.brandurl} target="_blank">
            {" "}
            {data.brandurl}
          </a>
        </Div>
      );
    else if (
      mac_url == brandurl &&
      and_url == brandurl &&
      pc_url == brandurl &&
      ios_url == brandurl
    )
      return (
        <Div>
          <Icon name="Dot" size="10px" /> Target URL:{" "}
          <a href={data.url} target="_blank">
            {" "}
            {data.brandurl}
          </a>
        </Div>
      );
    else
      return (
        <Div>
          <Div>
            <Icon name="Dot" size="10px" /> Target URL:{" "}
            <a href={data.brandurl} target="_blank">
              {" "}
              {data.brandurl}
            </a>
          </Div>
          <Div>
            <Icon name="Dot" size="10px" />
            Mac:{" "}
            <a href={data.mac_url} target="_blank">
              {" "}
              {data.mac_url}
            </a>
          </Div>
          <Div>
            <Icon name="Dot" size="10px" />
            IOS:{" "}
            <a href={data.ios_url} target="_blank">
              {" "}
              {data.ios_url}
            </a>
          </Div>
          <Div>
            <Icon name="Dot" size="10px" /> PC:{" "}
            <a href={data.pc_url} target="_blank">
              {" "}
              {data.pc_url}
            </a>
          </Div>
          <Div>
            <Icon name="Dot" size="10px" /> Android:
            <a href={data.and_url} target="_blank">
              {" "}
              {data.and_url}
            </a>
          </Div>
        </Div>
      );
  };
  return (
    <Div>
      <AdminDashboardLayout
        isLoading={urlData.isLoading}
        notification={notificationData}
        onClose={(e) => {
          setNotificationData({
            content: false,
          });
        }}
      >
        {DeleteUrlModal()}
        {EditUrlModal()}
        <Table
          isSearch={false}
          data={[
            {
              title: "Title",
              key: "title",
            },

            {
              title: "Customized Link",
              row: [
                (data, key) => (
                  <Text>
                    <a
                      href={`${uri}${urlData.brand?.brandname}/${data.url_id}`}
                      target="_blank"
                    >{`${uri}/${urlData.brand?.brandname}/${data.url_id}`}</a>
                  </Text>
                ),
              ],
            },
            {
              title: "URL ",
              row: [(data, key) => listUrlBasedOnApp(data)],
            },
            {
              title: "Manage",
              row: [
                (data, key) => (
                  <Link to={`/admin/users/brand/url/analytics/${data.id}`}>
                    <Button
                      key={key}
                      h="2rem"
                      w="2rem"
                      bg="success300"
                      hoverBg="success400"
                      rounded="lg"
                      m={{ x: "0.5rem" }}
                    >
                      <Icon name="Info" color="success800" size="18px" />
                    </Button>
                  </Link>
                ),
                (data, key) => (
                  <Button
                    key={key}
                    h="2rem"
                    w="2rem"
                    bg="info300"
                    hoverBg="info400"
                    rounded="lg"
                    m={{ x: "0.5rem" }}
                    onClick={(e) => {
                      setShowEditUrlModal(true);

                      setEditUrlData({
                        ...editUrlData,
                        currentData: data,
                        id: data.id,
                      });
                    }}
                  >
                    <Icon name="Edit" color="info800" size="18px" />
                  </Button>
                ),
                (data, key) => (
                  <Button
                    key={key}
                    h="2rem"
                    w="2rem"
                    bg="danger300"
                    hoverBg="danger400"
                    rounded="lg"
                    m={{ x: "0.5rem" }}
                    onClick={(e) => {
                      setShowDeleteUrlModal(true);
                      setDeleteUrlId(data.id);
                    }}
                  >
                    <Icon name="Delete" color="danger800" size="18px" />
                  </Button>
                ),
              ],
            },
          ]}
          value={urlData.data}
        ></Table>
      </AdminDashboardLayout>
    </Div>
  );
};

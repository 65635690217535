import React, { useState } from "react";
import { Div, Button, Icon, Row, Modal, Col, Text, Input } from "atomize";
import { isAuthenticated } from "../../core";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { changePasswordAdmin } from "../../core/apiAdmin";

const AdminSettings = () => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { user } = isAuthenticated();
  const [passwordData, setPasswordData] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
    email: user.email,
  });

  const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);
  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });

  const { password, newPassword, confirmPassword } = passwordData;
  const handleChange = (name) => (event) => {
    setPasswordData({ ...passwordData, [name]: event.target.value });
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    if (newPassword == confirmPassword) {
      const { user } = isAuthenticated();
      setPasswordData({ ...passwordData, email: user.email });

      setIsChangePasswordLoading(true);

      changePasswordAdmin(passwordData)
        .then((data) => {
          if (data.error) {
            setIsChangePasswordLoading(false);

            setNotificationData({
              content: JSON.parse(data.error).error,
              bg: "danger700",
            });
          } else {
            setIsChangePasswordLoading(false);

            setNotificationData({
              content: "Passowrd Changed",
              bg: "success800",
            });
            setShowChangePasswordModal(false);
          }
        })
        .catch((err) => {
          setNotificationData({
            content: err.error,
            bg: "danger700",
          });
        });
    } else {
      setNotificationData({
        content: "New Passowrd and Confirm Password Doesn't match",
        bg: "danger700",
      });
    }
  };
  const [showOldPass, setShowOldPass] = useState(false);

  const [showNewPass, setShowNewPass] = useState(false);

  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const ChangePasswordModal = (params) => {
    return (
      <Modal
        isOpen={showChangePasswordModal}
        rounded="md"
        onClose={() => setShowChangePasswordModal(false)}
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setShowChangePasswordModal(false)}
          cursor="pointer"
        />
        {/* <form onSubmit={(e) => alert("hi")}> */}
        <Row>
          <Col textAlign="center">
            <Text textSize="title" m={{ b: "1rem" }}>
              Change password
            </Text>
            <Input
              placeholder="Old Password"
              m={{ b: "1rem" }}
              type={showOldPass ? "text" : "password"}
              w="100%"
              onChange={handleChange("password")}
              suffix={
                <Button
                  pos="absolute"
                  onClick={(e) => setShowOldPass(!showOldPass)}
                  bg="transparent"
                  w="3rem"
                  top="0"
                  right="0"
                  rounded={{ r: "md" }}
                >
                  <Icon
                    name={showOldPass ? "EyeSolid" : "Eye"}
                    color={showOldPass ? "danger800" : "success800"}
                    size="16px"
                  />
                </Button>
              }
            />
            <Input
              placeholder="New Password"
              m={{ b: "1rem" }}
              type={showNewPass ? "text" : "password"}
              w="100%"
              onChange={handleChange("newPassword")}
              suffix={
                <Button
                  pos="absolute"
                  onClick={(e) => setShowNewPass(!showNewPass)}
                  bg="transparent"
                  w="3rem"
                  top="0"
                  right="0"
                  rounded={{ r: "md" }}
                >
                  <Icon
                    name={showNewPass ? "EyeSolid" : "Eye"}
                    color={showNewPass ? "danger800" : "success800"}
                    size="16px"
                  />
                </Button>
              }
            />
            <Input
              placeholder="Confirm Password"
              m={{ b: "1rem" }}
              w="100%"
              type={showConfirmPass ? "text" : "password"}
              onChange={handleChange("confirmPassword")}
              suffix={
                <Button
                  pos="absolute"
                  onClick={(e) => setShowConfirmPass(!showConfirmPass)}
                  bg="transparent"
                  w="3rem"
                  top="0"
                  right="0"
                  rounded={{ r: "md" }}
                >
                  <Icon
                    name={showConfirmPass ? "EyeSolid" : "Eye"}
                    color={showConfirmPass ? "danger800" : "success800"}
                    size="16px"
                  />
                </Button>
              }
            />
          </Col>
        </Row>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setShowChangePasswordModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(e) => handleChangePassword(e)}
            isLoading={isChangePasswordLoading}
            bg="info700"
          >
            Change Password
          </Button>
        </Div>
        {/* </form> */}
      </Modal>
    );
  };
  return (
    <AdminDashboardLayout
      notification={notificationData}
      onClose={(e) => {
        setNotificationData({
          content: false,
        });
      }}
    >
      {ChangePasswordModal()}
      <Row m={{ b: "20px" }}>
        <Icon name="User" size="50px" />
        <Div>
          {isAuthenticated() && isAuthenticated().user.name} <br />
          {isAuthenticated() && isAuthenticated().user.email}
        </Div>
      </Row>
      <Row>
        <Button bg="info700" onClick={(e) => setShowChangePasswordModal(true)}>
          {" "}
          Change Password
        </Button>
      </Row>
    </AdminDashboardLayout>
  );
};

export default AdminSettings;

import React, { useState, useEffect } from "react";
import UserDashboardLayout from "./UserDashboardLayout";
import {
  Div,
  Button,
  Col,
  Row,
  Icon,
  Modal,
  Text,
  Notification,
} from "atomize";
import MembershipCard from "../../components/MembershipCard";
import { getPlans, getAvailability } from "../../core/apiUser";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MemberShipItem from "../../components/MemberShipItem";
import moment from "moment";
import { deleteAvailiabilityUser } from "../../core/apiUser";
import { getCountryDetails } from "../../core/apiCore";
import PlanFeatureItem from "../../components/PlanFeatureItem";

const Membership = () => {
  const [planData, setPlanData] = useState({
    data: [],

    error: false,
  });

  const [availiabilty, setAvailiabilty] = useState({
    data: [],

    error: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showCarousal, setShowCarousal] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const showNotification = () => {
    return (
      <Notification
        bg="danger700"
        isOpen={openNotification}
      // onClose={() => setOpenNotification(false)}
      >
        Dont Refresh the page until transaction complete
      </Notification>
    );
  };
  const fetchMembership = () => {
    getAvailability()
      .then((data) => {
        if (data.error) {
          setIsLoading(false);
          setAvailiabilty({ ...availiabilty });
          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          setAvailiabilty({ ...availiabilty, data: data });
          setIsLoading(false);

          setShowCarousal(true)
        }
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
        setIsLoading(false);
      });
  };

  const fetchPlans = () => {
    setIsLoading(true);

    getPlans()
      .then((data) => {
        if (data.error) {
          setIsLoading(false);
          setNotificationData({
            content: data.error.msg,
            bg: "danger700",
          });
        } else {
          setPlanData({ ...planData, data: data });
        }
      })
      .catch((err) => {
        setNotificationData({
          content: "No Data",
          bg: "danger700",
        });
      });
  };

  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });

  useEffect(() => {
    fetchPlans();
    getCountry();
    fetchMembership();
  }, []);

  const [
    isLoadingDeleteAvailiability,
    setIsLoadingDeleteAvailiability,
  ] = useState(false);
  const [
    showDeleteAvailiabilityModal,
    setShowDeleteAvailiabilityModal,
  ] = useState(false);

  const [deleteAvaliabilityId, setDeleteAvaliabilityId] = useState("");

  const DeleteAvailiabilityModal = (params) => {
    return (
      <Modal
        isOpen={showDeleteAvailiabilityModal}
        onClose={() => setShowDeleteAvailiabilityModal(false)}
        rounded="md"
        align="start"
        rounded="md"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setShowDeleteAvailiabilityModal(false)}
          cursor="pointer"
        />
        <Div d="flex" m={{ b: "4rem" }}>
          <Icon
            name="AlertSolid"
            color="warning700"
            m={{ t: "0.35rem", r: "0.5rem" }}
          />
          <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="subheader">
            On deleting this plan,{" "}
            <Text tag="span" textWeight="600">
              you will not get any refund
            </Text>{" "}
            from Utubelink . If you are deleting a{" "}
            <Text tag="span" textWeight="600">
              current plan
            </Text>
            ,all the data in your account will function based on plan{" "}
            {"(if exist)"}
            after deleting current plan.Do you really want to delete?
          </Text>
        </Div>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setShowDeleteAvailiabilityModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            isLoading={isLoadingDeleteAvailiability}
            onClick={(e) => deleteAvailiabilty()}
            bg="info700"
          >
            Yes{" "}
          </Button>
        </Div>
      </Modal>
    );
  };

  const deleteAvailiabilty = (params) => {
    setIsLoadingDeleteAvailiability(true);
    deleteAvailiabilityUser(deleteAvaliabilityId)
      .then((data) => {
        if (data.error) {
          console.log(data);
          setIsLoadingDeleteAvailiability(false);
          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          setIsLoadingDeleteAvailiability(false);
          setShowDeleteAvailiabilityModal(false);
          setNotificationData({
            content: "Plan Deleted ",
            bg: "success700",
          });

          fetchPlans();
          fetchMembership();
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingDeleteAvailiability(false);
        setShowDeleteAvailiabilityModal(false);
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
        fetchMembership();
        fetchPlans();
      });
  };

  const onFinish = (params) => {
    setShowCarousal(false)
    fetchPlans();
    fetchMembership();
  };

  const [countryDetails, setCountryDetails] = useState({});

  const getCountry = async () => {
    setIsLoading(true);
    getCountryDetails().then(data => {
      if (data.error) {
      } else {
        setCountryDetails(data)
      }
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <UserDashboardLayout
      notification={notificationData}
      onClose={(e) => {
        setNotificationData({
          content: false,
        });
      }}
      isLoading={isLoading}
    >
      {showNotification()}
      {DeleteAvailiabilityModal()}
      {availiabilty.data.length > 0 && showCarousal && (
        <Div maxW="calc(100vw - 40px)">
          <OwlCarousel
            items={6}
            dots={false}
            margin={10}
            nav={false}
            className="owl-theme"
            responsiveClass={true}
            responsive={{
              0: {
                items: 1,
              },

              480: {
                items: 2,
              },

              768: {
                items: 3,
              },
              992: {
                items: 4,
              },
            }}
          >
            {availiabilty.data.map((item, index) => (
              <MemberShipItem
                setModal={setShowDeleteAvailiabilityModal}
                data={item}
                index={index}
                setId={setDeleteAvaliabilityId}
              ></MemberShipItem>
            ))}
          </OwlCarousel>

          {availiabilty.data.map(
            (data, index) =>
              index == 0 && (
                <Div>
                  <Div
                    d="flex"
                    m={{ t: "0.5rem" }}
                    justify="center"
                    textSize="title"
                  >
                    {/* <div className="availiabity-tag"></div> */}
                    Current Plan Status ({data?.title})
                  </Div>
                  <Div bg="white" h="auto" p="20px" >
                    <Row>
                      <Col size={{ xs: "12", md: "6", lg: "3" }}>
                        <Div
                          bg="white"
                          rounded="md"
                          shadow="5"
                          h="100px"
                          m={{ y: "15px" }}
                          p="20px"
                        >
                          <Text> Exprire in:</Text>

                          <Row justify="flex-end" align="flex-end">
                            <Text
                              textAlign="right"
                              textSize="display1"
                              textWeight="700"
                            >
                              {moment(data?.endDate).diff(moment(), "days")}{" "}
                              <Text tag="span" style={{ fontSize: "10px" }}>
                                days
                              </Text>
                            </Text>
                          </Row>
                        </Div>
                      </Col>
                      <PlanFeatureItem
                        title="Clicks Remaining:"
                        data1={data?.noOfClick}
                        data2={data?.noOfClickUsed}
                      ></PlanFeatureItem>
                      <PlanFeatureItem
                        title="Links Remaining:"
                        data1={data?.noOfLink}
                        data2={data?.noOfLinkUsed}
                      ></PlanFeatureItem>
                      <PlanFeatureItem
                        title="Edits Remaining:"
                        data1={data?.noOfedit}
                        data2={data?.noOfeditUsed}
                      ></PlanFeatureItem>
                      <PlanFeatureItem
                        title="Brands Remaining:"
                        data1={data?.noofbrand}
                        data2={data?.noofbrandUsed}
                      ></PlanFeatureItem>
                      <PlanFeatureItem
                        title="Brand Links Remaining:"
                        data1={data?.noofbrandlink}
                        data2={data?.noofbrandlinkUsed}
                      ></PlanFeatureItem>

                      <Col size={{ xs: "12", md: "6", lg: "3" }}>
                        <Div
                          bg="white"
                          rounded="md"
                          shadow="5"
                          minH="100px"
                          m={{ y: "15px" }}
                          p="20px"
                        >
                          <Text>Domains</Text>
                          <br />
                          {
                            data.subDomain && data.subDomain.map((item, i) => (
                              <Text
                                key={i}
                                textAlign="left"
                              >
                                {item}
                              </Text>
                            ))
                          }
                        </Div>
                      </Col>

                    </Row>
                  </Div>
                </Div>
              )
          )}
        </Div>
      )}
      <Row h="100%">
        {planData.data.map((item, index) => (
          <MembershipCard
            currency={countryDetails.currency == "USD" ? "USD" : "INR"}
            // currency={"USD"}
            onFinish={onFinish}
            openNotification={openNotification}
            setNotification={setOpenNotification}
            key={index}
            planDetails={item}
          />
        ))}
      </Row>
    </UserDashboardLayout>
  );
};

export default Membership;

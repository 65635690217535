import React, { useState, useEffect } from "react";
import UserDashboardLayout from "./UserDashboardLayout";
import {
  Div,
  Icon,
  Button,
  Row,
  Modal,
  Text,
  Input,
  Col,
  Label,
  Switch,
} from "atomize/dist";
import Table from "../../components/Table";
import {
  getUrls,
  createBrandUrl,
  editBrandUrl,
  deleteBrandUrl,
  getCurrentAvailability,
  getBrandUrls,
  getAllBrandUrls,
} from "../../core/apiUser";
import { Link } from "react-router-dom";
import { uri } from "../../core/config";
import Anchor from "atomize/dist/atoms/anchor/Anchor";
import moment from 'moment'
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DropDown from "../../components/DropDown";
import { CopyUrlItem } from "../../components/CopyUrlItem";
import Loading from "../../components/Loading";
import { errMessageSimple, GetErrorMessage } from "../../components/ErrorMessageFunction";
import ReactTooltip from 'react-tooltip';
import BrandUrlSearchList from "../../components/BrandUrlSearchList";
export const BrandURLList = () => {
  const [showAddUrlModal, setShowAddUrlModal] = useState(false);
  const [showEditUrlModal, setShowEditUrlModal] = useState(false);
  const [showDeleteUrlModal, setShowDeleteUrlModal] = useState(false);

  //TO show notification
  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });

  const [addUrlData, setAddUrlData] = useState({
    appLink: false,
    title: null,
    url: null,
    url_id: null,
    ios_url: null,
    and_url: null,
    mac_url: null,
    pc_url: null,
  });

  const [editUrlData, setEditUrlData] = useState({
    currentData: {
      title: null,
      brandurl: null,
      url_id: null,
      ios_url: null,
      and_url: null,
      mac_url: null,
      pc_url: null,
    },
    id: null,
  });

  const [deleteUrlId, setDeleteUrlId] = useState(null);

  const [isAddUrlLoading, setIsAddUrlLoading] = useState(false);
  const [isEditUrlLoading, setIsEditUrlLoading] = useState(false);
  const [isDeleteUrlLoading, setIsDeleteUrlLoading] = useState(false);
  const [noApplinkData, setNoApplinkData] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [urlData, setUrlData] = useState({
    data: [],
    brand: null,
    avalibility: null,
    selectedSubdomain: null,
    isLoading: false,
    error: false,
  });
  const [currentAvailiabilty, setCurrentAvailiabilty] = useState({
    data: null,
    isLoading: false,
  });
  const fetchUrls = () => {
    setIsDataLoading(true);
    getAllBrandUrls()
      .then((data) => {
        if (data.error) {
          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          data.data.reverse();
          setUrlData({ ...urlData, data: data.data, avalibility: data.availability });
        }
        setIsDataLoading(false);
      })
      .catch((e) => {
        setNotificationData({
          content: e.error,
          bg: "danger700",
        });
      });
  };
  const getSubdomains = (subdomains) => {
    let res = [];
    for (let i = 0; i < subdomains.length; i++) {
      const element = subdomains[i];
      res.push({ title: element, value: element });
    }
    return res;
  }

  const handleChange = (name) => (event) => {
    setAddUrlData({ ...addUrlData, [name]: event.target.value });
  };
  const handleChangeUrl = (e) => {
    const address = e.target.value;

    setAddUrlData({
      ...addUrlData,
      url: address,
      mac_url: address,
      pc_url: address,
      and_url: address,
      ios_url: address,
    });
  };
  const handleChangeUrlEdit = (e) => {
    const address = e.target.value;

    setEditUrlData({
      ...editUrlData,
      brandurl: address,
      mac_url: address,
      pc_url: address,
      and_url: address,
      ios_url: address,
    });
  };

  const handleChangeEdit = (name) => (event) => {
    setEditUrlData({ ...editUrlData, [name]: event.target.value });
  };

  const addUrl = (event) => {
    event.preventDefault();

    // setAppLinks();
    // const addData = addUrlData.appLink ? addUrlData : noApplinkData;

    setIsAddUrlLoading(true);


  };

  const editUrlHandler = (url_id) => {
    setIsEditUrlLoading(true);

    editBrandUrl(editUrlData, editUrlData.id)
      .then((data) => {
        if (data.error) {
          setIsEditUrlLoading(false);

          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          setIsEditUrlLoading(false);
          setNotificationData({
            content: "URL Edited",
            bg: "success700",
          });
          setShowEditUrlModal(false);
          setEditUrlData({
            currentData: {
              title: null,
              url: null,
              url_id: null,
              ios_url: null,
              and_url: null,
              mac_url: null,
              pc_url: null,
            },
            id: null,
          });
        }
        fetchUrls();
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  const deleteUrlHandler = () => {
    setIsDeleteUrlLoading(true);

    deleteBrandUrl(deleteUrlId)
      .then((data) => {
        if (data.error) {
          setShowDeleteUrlModal(false);

          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          setShowDeleteUrlModal(false);
          setNotificationData({
            content: "URL Deleted",
            bg: "success700",
          });
          setShowDeleteUrlModal(false);
        }
        setIsDeleteUrlLoading(false);
        fetchUrls();
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  const DeleteUrlModal = (params) => {
    return (
      <Modal
        isOpen={showDeleteUrlModal}
        onClose={() => setShowDeleteUrlModal(false)}
        rounded="md"
        align="start"
        rounded="md"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setShowDeleteUrlModal(false)}
          cursor="pointer"
        />
        <Div d="flex" m={{ b: "4rem" }}>
          <Icon
            name="AlertSolid"
            color="warning700"
            m={{ t: "0.35rem", r: "0.5rem" }}
          />
          <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="subheader">
            Do you really want to delete URL?
          </Text>
        </Div>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setShowDeleteUrlModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            isLoading={isDeleteUrlLoading}
            onClick={(e) => deleteUrlHandler()}
            bg="info700"
          >
            Delete
          </Button>
        </Div>
      </Modal>
    );
  };

  useEffect(async () => {
    await fetchUrls();
    // await fetchAvailiabilty();
  }, []);

  useEffect(async () => {
    console.log('====================================');
    console.log(editUrlData);
    console.log('====================================');
  }, [editUrlData]);

  const AddUrlModal = () => {
    return (
      <Modal
        isOpen={showAddUrlModal}
        onClose={() => setShowAddUrlModal(false)}
        rounded="md"
        maxW="48rem"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={() => setShowAddUrlModal(false)}
          cursor="pointer"
        />

        <Row
          p={{
            md: { l: "0.5rem", t: "0.25rem" },
          }}
          m={{ b: "2rem" }}
        >
          <Col>
            <Text textAlign="center" textSize="title" m={{ b: "1rem" }}>
              Add New URL
            </Text>
            <Row>
              <Col size={{ xs: "12", md: "6" }}>
                {" "}
                <Input
                  placeholder="Title"
                  required
                  //   p={{ x: "2.5rem" }}
                  m={{ b: "1rem" }}
                  w="100%"
                  onChange={handleChange("title")}
                />
              </Col>
              <Col size={{ xs: "12", md: "6" }}>
                <Input
                  placeholder="Path"
                  //   p={{ x: "2.5rem" }}
                  m={{ b: "1rem" }}
                  w="100%"
                  onChange={handleChange("url_id")}
                />
              </Col>
            </Row>

            <Input
              placeholder="Target URL"
              p={{ x: "2.5rem" }}
              m={{ b: "1rem" }}
              w="100%"
              prefix={
                <Icon
                  name="Link"
                  color="warning800"
                  size="16px"
                  cursor="pointer"
                  pos="absolute"
                  top="50%"
                  left="0.75rem"
                  transform="translateY(-50%)"
                />
              }
              onChange={(e) => {
                handleChangeUrl(e);
                // handleChange("mac_url");
                // handleChange("and_url");
                // handleChange("pc_url");
                // handleChange("ios_url");
              }}
            />
            {currentAvailiabilty.data?.appLinkOrNot ? (
              <Row>
                <Label
                  p={{ t: "0.6rem" }}
                  onClick={(e) => {
                    setAddUrlData({
                      ...addUrlData,
                      appLink: !addUrlData.appLink,
                    });
                  }}
                  align="center"
                  m={{ b: "1rem" }}
                >
                  <Switch checked={addUrlData.appLink} />
                  Custom Target URL
                </Label>
              </Row>
            ) : (
              ""
            )}
            {addUrlData.appLink ? (
              <>
                <Input
                  placeholder="PC URL"
                  p={{ x: "2.5rem" }}
                  m={{ b: "1rem" }}
                  w="100%"
                  prefix={
                    <Icon
                      name="Link"
                      color="warning800"
                      size="16px"
                      cursor="pointer"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                    />
                  }
                  onChange={handleChange("pc_url")}
                />
                <Input
                  placeholder="IOS URL"
                  p={{ x: "2.5rem" }}
                  m={{ b: "1rem" }}
                  w="100%"
                  prefix={
                    <Icon
                      name="Link"
                      color="warning800"
                      size="16px"
                      cursor="pointer"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                    />
                  }
                  onChange={handleChange("ios_url")}
                />
                <Input
                  placeholder="Android URL"
                  p={{ x: "2.5rem" }}
                  m={{ b: "1rem" }}
                  w="100%"
                  prefix={
                    <Icon
                      name="Link"
                      color="warning800"
                      size="16px"
                      cursor="pointer"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                    />
                  }
                  onChange={handleChange("and_url")}
                />
                <Input
                  placeholder="MAC URL"
                  p={{ x: "2.5rem" }}
                  m={{ b: "1rem" }}
                  w="100%"
                  prefix={
                    <Icon
                      name="Link"
                      color="warning800"
                      size="16px"
                      cursor="pointer"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                    />
                  }
                  onChange={handleChange("mac_url")}
                />
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setShowAddUrlModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            bg="info700"
            onClick={(e) => {
              addUrl(e);
            }}
            isLoading={isAddUrlLoading}
          >
            Add
          </Button>
        </Div>
      </Modal>
    );
  };

  const EditUrlModal = (data) => {
    const { mac_url, and_url, pc_url, ios_url, brandurl } = editUrlData.currentData;
    const checkUrlLinkStatus = (params) => {
      return (mac_url == brandurl &&
        and_url == brandurl &&
        pc_url == brandurl &&
        ios_url == brandurl) ||
        mac_url == null ||
        and_url == null ||
        pc_url == null ||
        ios_url == null
        ? false
        : true;
    };
    return (
      <Modal
        isOpen={showEditUrlModal}
        onClose={() => setShowEditUrlModal(false)}
        rounded="md"
        maxW="48rem"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={() => setShowEditUrlModal(false)}
          cursor="pointer"
        />
        <Row
          p={{
            md: { l: "0.5rem", t: "0.25rem" },
          }}
          m={{ b: "2rem" }}
        >
          <Col>
            <Text textAlign="center" textSize="title" m={{ b: "1rem" }}>
              Edit
            </Text>
            <Row>
              <Col size={{ xs: "12", md: "6" }}>
                {" "}
                Title
                <Input
                  placeholder="Title"
                  //   p={{ x: "2.5rem" }}
                  defaultValue={editUrlData.currentData.title}
                  m={{ b: "1rem" }}
                  w="100%"
                  onChange={handleChangeEdit("title")}
                />
              </Col>
              <Col size={{ xs: "12", md: "6" }}>
                Path
                <Input
                  placeholder="Path"
                  //   p={{ x: "2.5rem" }}
                  defaultValue={editUrlData.currentData.url_id}
                  m={{ b: "1rem" }}
                  w="100%"
                  onChange={handleChangeEdit("url_id")}
                />
              </Col>
            </Row>
            Target Url
            <Input
              placeholder="Target URL"
              p={{ x: "2.5rem" }}
              m={{ b: "1rem" }}
              defaultValue={editUrlData.currentData.brandurl}
              w="100%"
              prefix={
                <Icon
                  name="Link"
                  color="warning800"
                  size="16px"
                  cursor="pointer"
                  pos="absolute"
                  top="50%"
                  left="0.75rem"
                  transform="translateY(-50%)"
                />
              }
              onChange={
                checkUrlLinkStatus
                  ? (e) => handleChangeUrlEdit(e)
                  : handleChangeEdit("brandurl")
              }
            />
            {(mac_url == brandurl &&
              and_url == brandurl &&
              pc_url == brandurl &&
              ios_url == brandurl) ||
              mac_url == null ||
              and_url == null ||
              pc_url == null ||
              ios_url == null ? (
              ""
            ) : (
              <>
                PC Url
                <Input
                  placeholder="PC URL"
                  p={{ x: "2.5rem" }}
                  m={{ b: "1rem" }}
                  w="100%"
                  defaultValue={editUrlData.currentData.pc_url}
                  prefix={
                    <Icon
                      name="Link"
                      color="warning800"
                      size="16px"
                      cursor="pointer"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                    />
                  }
                  onChange={handleChangeEdit("pc_url")}
                />
                IOS Url
                <Input
                  placeholder="IOS URL"
                  p={{ x: "2.5rem" }}
                  m={{ b: "1rem" }}
                  w="100%"
                  defaultValue={editUrlData.currentData.ios_url}
                  prefix={
                    <Icon
                      name="Link"
                      color="warning800"
                      size="16px"
                      cursor="pointer"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                    />
                  }
                  onChange={handleChangeEdit("ios_url")}
                />
                Android Url
                <Input
                  placeholder="Android URL"
                  p={{ x: "2.5rem" }}
                  m={{ b: "1rem" }}
                  w="100%"
                  defaultValue={editUrlData.currentData.and_url}
                  prefix={
                    <Icon
                      name="Link"
                      color="warning800"
                      size="16px"
                      cursor="pointer"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                    />
                  }
                  onChange={handleChangeEdit("and_url")}
                />
                Mac Url
                <Input
                  placeholder="MAC URL"
                  p={{ x: "2.5rem" }}
                  m={{ b: "1rem" }}
                  w="100%"
                  defaultValue={editUrlData.currentData.mac_url}
                  prefix={
                    <Icon
                      name="Link"
                      color="warning800"
                      size="16px"
                      cursor="pointer"
                      pos="absolute"
                      top="50%"
                      left="0.75rem"
                      transform="translateY(-50%)"
                    />
                  }
                  onChange={handleChangeEdit("mac_url")}
                />
              </>
            )}{" "}
          </Col>
        </Row>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={() => setShowEditUrlModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            bg="info700"
            onClick={(e) => editUrlHandler(editUrlData.id)}
            isLoading={isEditUrlLoading}
          >
            Save
          </Button>
        </Div>
      </Modal>
    );
  };
  const listUrlBasedOnApp = (data) => {
    const { mac_url, and_url, pc_url, ios_url, brandurl } = data;
    if (mac_url == null || and_url == null || pc_url == null || ios_url == null)
      return (
        <Div>
          <Icon name="Link" size="10px" /> Target URL:{" "}
          <Anchor style={{ wordWrap: 'break-word' }} textColor="gray700" href={data.brandurl} target="_blank">
            {" "}
            {data.brandurl}
          </Anchor>
        </Div>
      );
    else if (
      mac_url == brandurl &&
      and_url == brandurl &&
      pc_url == brandurl &&
      ios_url == brandurl
    )
      return (
        <Div>
          <Icon name="Link" size="10px" /> Target URL:{" "}
          <Anchor style={{ wordWrap: 'break-word' }} textColor="gray700" href={data.brandurl} target="_blank">
            {" "}
            {data.brandurl}
          </Anchor>
        </Div>
      );
    else
      return (
        <Div>
          <Div>
            <Icon name="Link" size="10px" /> Target URL:{" "}
            <Anchor style={{ wordWrap: 'break-word' }} textColor="gray700" href={data.brandurl} target="_blank">
              {" "}
              {data.brandurl}
            </Anchor>
          </Div>
          <Div>
            <Icon name="Link" size="10px" />
            Mac:{" "}
            <Anchor style={{ wordWrap: 'break-word' }} textColor="gray700" href={data.mac_url} target="_blank">
              {" "}
              {data.mac_url}
            </Anchor>
          </Div>
          <Div>
            <Icon name="Link" size="10px" />
            IOS:{" "}
            <Anchor style={{ wordWrap: 'break-word' }} textColor="gray700" href={data.ios_url} target="_blank">
              {" "}
              {data.ios_url}
            </Anchor>
          </Div>
          <Div>
            <Icon name="Link" size="10px" /> PC:{" "}
            <Anchor style={{ wordWrap: 'break-word' }} textColor="gray700" href={data.pc_url} target="_blank">
              {" "}
              {data.pc_url}
            </Anchor>
          </Div>
          <Div>
            <Icon name="Link" size="10px" /> Android:
            <Anchor style={{ wordWrap: 'break-word' }} textColor="gray700" href={data.and_url} target="_blank">
              {" "}
              {data.and_url}
            </Anchor>
          </Div>
        </Div>
      );
  };
  return (
    <Div>
      { DeleteUrlModal()}
        <Div d="flex" justify="center" textSize="heading">Brand urls</Div>
      <Loading h="200px" isLoading={isDataLoading}>
        {DeleteUrlModal()}
        {EditUrlModal()}
        <BrandUrlSearchList 
          urlData={urlData}
          setNotification={(msg, color) => {
            setNotificationData({
              content: msg,
              bg: color,
            })
          }}
          onEdit={(data) => {

            setShowEditUrlModal(true);
            setEditUrlData({
              ...editUrlData,
              currentData: data,
              id: data.id,
            })
          }}
          onDelete={(data) => {

            setShowDeleteUrlModal(true);
            setDeleteUrlId(data.id);
          }}
          > </BrandUrlSearchList>
      </Loading>
    </Div >
  );
};

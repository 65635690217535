import React from "react";
import { Div, Text } from "atomize";

const Footer = () => {
  return (
    <Div
      justify="center"
      textAlign="center"
      m={{ t: "40px" }}
      textSize="caption"
      d="flex"
      textColor="gray800"
      p="10px"
    >
      Powered by &nbsp;{" "}
      <Text textSize="caption" textColor="black700">
        {" "}
        urlmentor
      </Text>
    </Div>
  );
};

export default Footer;

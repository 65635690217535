import React from "react";
import { StyleReset, ThemeProvider } from "atomize";
import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import Routes from "./Routes";

// const debug = process.env.NODE_ENV === "production" ? void 0 : new DebugEngine();
const debug = new DebugEngine();
const engine = new Styletron();

const theme = {
  fontFamily: {
    primary: "Poppins, sans-serif",
  },
  colors: {
    black900: "#1d1d1e",
    price_tag: "#FF4D43",
    prime_blue: "#0045AD",
    customGray: "#B1B1B1",
  },
};

const App = () => {
  return (
    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
      <ThemeProvider theme={theme}>
        <StyleReset />
        <Routes></Routes>
      </ThemeProvider>
    </StyletronProvider>
  );
};

export default App;

import React, { useState, useEffect } from "react";
import { Div, Button, Icon, Modal, Text, Row, Col } from "atomize";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { getAvailabilityAdmin } from "../../core/apiAdmin";
import moment from "moment";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MemberShipItem from "../../components/MemberShipItem";
const CurrentUserAvailiability = ({ match }) => {
  const [availiabilty, setAvailiabilty] = useState({
    data: null,
    memberships: [],
    error: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const fetchAvailiability = () => {
    setIsLoading(true);
    getAvailabilityAdmin(match.params.userId)
      .then((data) => {
        if (data.error) {
          setIsLoading(false);
          setAvailiabilty({ ...availiabilty });
          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          console.log(data);
          setAvailiabilty({ ...availiabilty, data: data.current, memberships: data.memberships });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
        setIsLoading(false);
      });
  };
  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });
  useEffect(() => {
    fetchAvailiability();
  }, []);

  return (
    <AdminDashboardLayout
      isLoading={isLoading}
      notification={notificationData}
      onClose={(e) => {
        setNotificationData({
          content: false,
        });
      }}
    >
      <Div>
        {
          availiabilty.data != null ? (
            <Div>

              <Div maxW="calc(100vw - 40px)">
                <OwlCarousel
                  items={6}
                  dots={false}
                  margin={10}
                  nav={false}
                  className="owl-theme"
                  responsiveClass={true}
                  responsive={{
                    0: {
                      items: 1,
                    },

                    480: {
                      items: 2,
                    },

                    768: {
                      items: 3,
                    },
                    992: {
                      items: 4,
                    },
                  }}
                >
                  {availiabilty.memberships.map((item, index) => (
                    <MemberShipItem
                      data={item}
                      index={index}
                    ></MemberShipItem>
                  ))}
                </OwlCarousel>
              </Div>

              <Div
                d="flex"
                m={{ t: "0.5rem" }}
                justify="center"
                textSize="title"
              >
                {/* <div className="availiabity-tag"></div> */}
              Current Plan Status ({availiabilty.data?.title})
            </Div>
              <Div bg="white" h="auto" p="20px" m={{ y: "0.5rem" }}>
                <Row>
                  <Col size={{ xs: "12", md: "6", lg: "3" }}>
                    <Div
                      bg="white"
                      rounded="md"
                      shadow="5"
                      h="100px"
                      m={{ y: "15px" }}
                      p="20px"
                    >
                      <Text> Exprire in:</Text>

                      <Row justify="flex-end" align="flex-end">
                        <Text
                          textAlign="right"
                          textSize="display1"
                          textWeight="700"
                        >
                          {moment(availiabilty.data?.endDate).diff(moment(), "days")}{" "}
                          <Text tag="span" style={{ fontSize: "10px" }}>
                            days
                              </Text>
                        </Text>
                      </Row>
                    </Div>
                  </Col>
                  <Col size={{ xs: "12", md: "6", lg: "3" }}>
                    <Div
                      bg="white"
                      rounded="md"
                      shadow="5"
                      h="100px"
                      m={{ y: "15px" }}
                      p="20px"
                    >
                      <Text> Clicks Remaining:  </Text>
                      <Row justify="flex-end" align="flex-end">
                        <Icon
                          color="danger700"
                          name="Up"
                          m="10px"
                          size="20px"
                        />

                        <Text
                          textAlign="right"
                          textSize="heading"
                          textWeight="700"
                        >
                          {availiabilty.data?.noOfClick == -1 ? "Unlimited" : availiabilty.data?.noOfClick - availiabilty.data?.noOfClickUsed}
                        </Text>
                      </Row>
                    </Div>
                  </Col>
                  <Col size={{ xs: "12", md: "6", lg: "3" }}>
                    {" "}
                    <Div
                      bg="white"
                      rounded="md"
                      shadow="5"
                      h="100px"
                      m={{ y: "15px" }}
                      p="20px"
                    >
                      <Text> Links Remaining: </Text>
                      <Row justify="flex-end" align="flex-end">
                        <Icon
                          color="danger700"
                          name="Link"
                          m="10px"
                          size="20px"
                        />

                        <Text
                          textAlign="right"
                          textSize="heading"
                          textWeight="700"
                        >
                          {availiabilty.data?.noOfLink == -1 ? "Unlimited" : availiabilty.data?.noOfLink - availiabilty.data?.noOfLinkUsed}
                        </Text>
                      </Row>
                    </Div>
                  </Col>
                  <Col size={{ xs: "12", md: "6", lg: "3" }}>
                    <Div
                      bg="white"
                      rounded="md"
                      shadow="5"
                      h="100px"
                      m={{ y: "15px" }}
                      p="20px"
                    >
                      <Text> Edits Remaining: </Text>
                      <Row justify="flex-end" align="flex-end">
                        <Icon
                          color="danger700"
                          name="Rename"
                          m="10px"
                          size="20px"
                        />

                        <Text
                          textAlign="right"
                          textSize="heading"
                          textWeight="700"
                        >
                          {availiabilty.data?.noOfedit == -1 ? "Unlimited" : availiabilty.data?.noOfedit - availiabilty.data?.noOfeditUsed}
                        </Text>
                      </Row>
                    </Div>
                  </Col>
                  <Col size={{ xs: "12", md: "6", lg: "3" }}>
                    <Div
                      bg="white"
                      rounded="md"
                      shadow="5"
                      h="100px"
                      m={{ y: "15px" }}
                      p="20px"
                    >
                      <Text> Brands Remaining: </Text>
                      <Row justify="flex-end" align="flex-end">
                        <Icon
                          color="danger700"
                          name="Rename"
                          m="10px"
                          size="20px"
                        />

                        <Text
                          textAlign="right"
                          textSize="heading"
                          textWeight="700"
                        >
                          {availiabilty.data?.noofbrand == -1 ? "Unlimited" : availiabilty.data?.noofbrand - availiabilty.data?.noofbrandUsed}
                        </Text>
                      </Row>
                    </Div>
                  </Col>
                  <Col size={{ xs: "12", md: "6", lg: "3" }}>
                    <Div
                      bg="white"
                      rounded="md"
                      shadow="5"
                      h="100px"
                      m={{ y: "15px" }}
                      p="20px"
                    >
                      <Text> Brands Links Remaining: </Text>
                      <Row justify="flex-end" align="flex-end">
                        <Icon
                          color="danger700"
                          name="Rename"
                          m="10px"
                          size="20px"
                        />

                        <Text
                          textAlign="right"
                          textSize="heading"
                          textWeight="700"
                        >
                          {availiabilty.data?.noofbrandlink == -1 ? "Unlimited" : availiabilty.data?.noofbrandlink - availiabilty.data?.noofbrandlinkUsed}
                        </Text>
                      </Row>
                    </Div>
                  </Col>
                  <Col size={{ xs: "12", md: "6", lg: "3" }}>
                    <Div
                      bg="white"
                      rounded="md"
                      shadow="5"
                      minH="100px"
                      m={{ y: "15px" }}
                      p="20px"
                    >
                      <Text>Domains</Text>
                      <br />
                      {
                        availiabilty.data.subDomain && availiabilty.data.subDomain.map((item, i) => (
                          <Text
                            key={i}
                            textAlign="left"
                          >
                            {item}
                          </Text>
                        ))
                      }
                    </Div>
                  </Col>

                </Row>
              </Div>
            </Div>
          ) : (
            <Div
              d="flex"
              m={{ t: "0.5rem" }}
              justify="center"
              textSize="title"
            >No membership
            </Div>
          )
        }
      </Div>
    </AdminDashboardLayout>
  );
};

export default CurrentUserAvailiability;

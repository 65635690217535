import React, { useState } from "react";
import { Div, Row, Col, Button } from "atomize";
import { Link, Redirect, useHistory } from "react-router-dom";
import { isAuthenticated, signout } from "../core";

const Kaimly = () => {
  return (
    <Div h="40px" bg="white" shadow="2">
      Kaimly .com
    </Div>
  );
};

export default Kaimly;

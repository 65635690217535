import React, { useState, useEffect } from "react";
import { Div, Button, Icon, Modal, Text } from "atomize";
import UserDashboardLayout from "./UserDashboardLayout";
import Table from "../../components/Table";
import { getTransaction } from "../../core/apiUser";
import moment from "moment";
import Invoice from "../../components/Invoice";
import { isAuthenticated } from "../../core";
import { getInvoiceNumber } from "../../components/InvoiceFunctions";

export const Transactions = () => {
  const [transactions, setTransactions] = useState({
    data: [],
    isLoading: false,
    error: false,
  });
  const { user } = isAuthenticated();
  const [billData, setBillData] = useState({
    data: [],
    showModal: false,
  });
  const BillModal = (params) => {
    return (
      <Modal
        isOpen={billData.showModal}
        onClose={() => setBillData({ ...billData, showModal: false })}
        rounded="md"
        maxW="750px"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setBillData({ ...billData, showModal: false })}
          cursor="pointer"
        />

        <Invoice
          billData={billData.data}
          userData={user}
        />
        {/* <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setBillData({ ...billData, showModal: false })}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Close
          </Button>
        </Div> */}
      </Modal>
    );
  };

  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });


  const addInvoice= (data)=>{
    for (let i = 0; i < data.length; i++) {
      data[i].invoiceNo = getInvoiceNumber(data[i])          
    }
    return data;
  }

  const fetchTransaction = () => {
    setTransactions({ ...transactions, isLoading: true });
    getTransaction()
      .then((data) => {
        if (data.error) {
          setTransactions({ ...transactions, isLoading: false });
          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          data = addInvoice(data)
          setTransactions({ ...transactions, isLoading: false, data: data });
        }
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  useEffect(() => {
    fetchTransaction();
  }, []);
  return (
    <UserDashboardLayout
      isLoading={transactions.isLoading}
      notification={notificationData}
      onClose={(e) => {
        setNotificationData({
          content: false,
        });
      }}
    >
      {BillModal()}
      <Table
        data={[
          {
            title: "Invoice No",
            key: "invoiceNo",
          },
          {
            title: "Transaction Date",
            // row: [(data, key) => Date.parse(data.date)],
            row: [
              (data, key) =>
                data.date.split('T')[0]+" UTC",
            ],
          },
          {
            title: "Membership Type",
            key: "type",
          },
          {
            title: "Price",
            key: "paidPrice",
          },
          {
            title: "Manage",
            row: [
              (data, key) => (
                <Button
                  key={key}
                  h="2rem"
                  w="2rem"
                  bg="info300"
                  hoverBg="info400"
                  rounded="lg"
                  m={{ x: "0.5rem" }}
                  onClick={(e) => {
                    setBillData({ data: data, showModal: true });
                  }}
                >
                  <Icon name="Eye" color="info800" size="18px" />
                </Button>
              ),
            ],
          },
        ]}
        value={transactions.data}
      ></Table>
    </UserDashboardLayout>
  );
};

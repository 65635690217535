import React, { useState, useEffect } from "react";
import { Div, Button, Icon, Text, Modal } from "atomize";
import Table from "../../components/Table";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { getMessages, deleteMessageAdmin } from "../../core/apiAdmin";
import moment from "moment";

export const Messages = () => {
  const [messages, setMessages] = useState({
    data: [],
    isLoading: false,
    err: false,
  });
  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });

  const [deleteMessage, setDeleteMessage] = useState({
    messagesId: null,
    isLoading: false,
    showModal: false,
  });
  const deleteMessageHandler = () => {
    setDeleteMessage({ ...deleteMessage, isLoading: true });

    deleteMessageAdmin(deleteMessage.messagesId)
      .then((data) => {
        if (data.error) {
          setDeleteMessage({ ...deleteMessage, isLoading: false });

          setNotificationData({
            content: JSON.parse(data.error).error,

            bg: "danger700",
          });
        } else {
          setNotificationData({
            content: "Message Deleted",
            bg: "success700",
          });
        }
        setDeleteMessage({
          ...deleteMessage,
          showModal: false,
          isLoading: false,
        });
        fetchMessages();
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };
  const DeleteMessageModal = (params) => {
    return (
      <Modal
        isOpen={deleteMessage.showModal}
        onClose={() => setDeleteMessage({ ...deleteMessage, showModal: false })}
        rounded="md"
        align="start"
        rounded="md"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) =>
            setDeleteMessage({ ...deleteMessage, showModal: false })
          }
          cursor="pointer"
        />
        <Div d="flex" m={{ b: "4rem" }}>
          <Icon
            name="AlertSolid"
            color="warning700"
            m={{ t: "0.35rem", r: "0.5rem" }}
          />
          <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="subheader">
            Do you really want to delete message?
          </Text>
        </Div>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) =>
              setDeleteMessage({ ...deleteMessage, showModal: false })
            }
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            isLoading={deleteMessage.isLoading}
            onClick={(e) => deleteMessageHandler()}
            bg="info700"
          >
            Delete
          </Button>
        </Div>
      </Modal>
    );
  };
  const fetchMessages = (params) => {
    setMessages({ ...messages, isLoading: true });
    getMessages()
      .then((data) => {
        if (data.error) {
          setMessages({ ...messages, isLoading: false });
          setNotificationData({
            content: JSON.parse(data.error).error,

            bg: "danger700",
          });
        } else {
          setMessages({ ...messages, isLoading: false, data: data });
        }
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  useEffect(() => {
    fetchMessages();
  }, []);
  return (
    <AdminDashboardLayout
      isLoading={messages.isLoading}
      notification={notificationData}
      onClose={(e) => {
        setNotificationData({
          content: false,
        });
      }}
    >
      {DeleteMessageModal()}
      <Div style={{width:"Calc(100% - 10px)"}}>
      <Table
        data={[
          {
            title: "User",
            row: [
              (data, key) => (data.name == null ? "Public Message" : data.name),
            ],
          },
          {
            title: "Title",
            key: "title",
          },
          {
            title: "Content",
            key: "content",
          },
          {
            title: "Time",
            row: [
              (data, key) =>
                moment(data.createdAt).format("DD-MM-YYYY HH:MM a"),
            ],
          },

          {
            title: "Manage",
            row: [
              (data, key) => (
                <Button
                  key={key}
                  h="2rem"
                  w="2rem"
                  bg="danger300"
                  hoverBg="danger400"
                  rounded="lg"
                  m={{ x: "0.5rem" }}
                  onClick={(e) => {
                    setDeleteMessage({
                      ...deleteMessage,
                      messagesId: data.id,
                      showModal: true,
                    });
                  }}
                >
                  <Icon name="Delete" color="danger800" size="18px" />
                </Button>
              ),
            ],
          },
        ]}
        value={messages.data}
      ></Table>
      </Div>
    </AdminDashboardLayout>
  );
};

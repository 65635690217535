import { isAuthenticated } from ".";
import { API } from "./config";

export const getTransaction = () => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/transactions/${user.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const getUrls = () => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/url/list/${user.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const getPlans = () => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/plan/list/${user.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const createUrl = (body) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/url/create/${user.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const editUrl = (body, url_id) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/url/edit/${url_id}/${user.id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const deleteUrl = (url_id) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/url/delete/${url_id}/${user.id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const changePassword = (body) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/changePassword/${user.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};
export const getAvailability = () => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/availability/${user.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {


      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const getCurrentAvailability = () => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/availability/current/${user.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const makeTransaction = (planId, body) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/transaction/${user.id}/${planId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const buyFreePlan = (planId,body) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/transaction/free/${user.id}/${planId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};
export const deleteAvailiabilityUser = (availabilityId) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/availability/delete/${user.id}/${availabilityId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const payTransaction = (transactionId, body) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/transactionpaid/${user.id}/${transactionId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const getDashboard = () => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/user/dashboard/${user.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const getUrlAnalytics = (urlid, weeks) => {
  const { user, token } = isAuthenticated();
  let today = new Date();

  today.setDate(today.getDate() - 7 * weeks);
  today = today.toISOString().split("T")[0];
  console.log(today);

  return fetch(`${API}/analitics/${user.id}/${urlid}?from=${today}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};


export const getBrandUrlAnalytics = (urlid, weeks) => {
  const { user, token } = isAuthenticated();
  let today = new Date();

  today.setDate(today.getDate() - 7 * weeks);
  today = today.toISOString().split("T")[0];
  console.log(today);

  return fetch(`${API}/analitics/brand/${user.id}/${urlid}?from=${today}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};


export const getReferralLink = () => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/referal/${user.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const updateReferalStatus = (body) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/referal/update/${user.id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const createBrand = (body) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/brand/${user.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body:JSON.stringify(body)
  })
    .then((response) => {

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};


export const getBrands = () => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/brand/${user.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};


export const deleteBrands = (brandId) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/brand/${user.id}/${brandId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};



export const getBrandUrls = (brandId) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/brandurl/${user.id}/${brandId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};



export const getallBrandUrls = () => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/brandurl/${user.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const editBrandUrl = (body, url_id) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/brandurl/${user.id}/${url_id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};


export const deleteBrandUrl = (url_id) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/brandurl/${user.id}/${url_id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const createBrandUrl = (body,brandId) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/brandurl/${user.id}/${brandId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const getAllBrandUrls = () => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/brandurl/${user.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const updateProfile = (body) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/user/profile/${user.id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

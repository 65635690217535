import React, { useState, useEffect } from "react";
import { Div, Button, Icon, Input, Modal, Row } from "atomize";
import Table from "../../components/Table";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { getMessages, deleteMessageAdmin, alltransactions } from "../../core/apiAdmin";
import moment from "moment";
import Label from "atomize/dist/atoms/label/Label";
import Col from "atomize/dist/atoms/grid/Col";
import DropDown from "../../components/DropDown";
import Invoice from "../../components/Invoice";
import ExportJsonExcel from 'js-export-excel';
import { getAmount, getDiscount, getGst, getInvoiceNumber, getInvoiceTotal, getKfc, getRoundOff, getTaxableValue } from "../../components/InvoiceFunctions";
import printJS from "print-js";
import { uri } from "../../core/config";
import { getStateCode } from "../../core/apiCore";

export const AllTransactions = () => {
  const [data, setData] = useState({
    data: [],
    isLoading: false,
    err: false,
  });
  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });
  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null,
    isgst: false,
    isLoading: false,
    err: false,
  });

  const addInvoice= (data)=>{
    for (let i = 0; i < data.length; i++) {
      data[i].invoiceNo = getInvoiceNumber(data[i])          
    }
    return data;
  }

  const fetchTransactions = (params) => {
    setData({ ...data, isLoading: true, data: [] });
    alltransactions(filterData)
      .then( (data) => {
        if (data.error) {
          setData({ ...data, isLoading: false });
          setNotificationData({
            content: data.error,

            bg: "danger700",
          });
        }
        data =  addInvoice(data)
        setData({ ...data, isLoading: false, data: data });
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };


  const [billData, setBillData] = useState({
    data: {},
    showModal: false,
  });
  const BillModal = (params) => {
    return (
      <Modal
        isOpen={billData.showModal}
        onClose={() => setBillData({ ...billData, showModal: false })}
        rounded="md"
        maxW="750px"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setBillData({ ...billData, showModal: false })}
          cursor="pointer"
        />

        <Invoice
          billData={billData.data}
        // userData={user}
        />
        {/* <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setBillData({ ...billData, showModal: false })}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Close
          </Button>
        </Div> */}
      </Modal>
    );
  };

  const exportToExcel = () => {
    let exportData = [];
    data.data.map((item, i) => {
      exportData.push({
        "Invoice No": getInvoiceNumber(item),
        Name: item.name,
        Email: item.email,
        Address: item.address,
        Country: item.country,
        State: item.state,
        "State Code":item.country == "India" ? getStateCode(item.state):'',
        Phone: item.phone,
        "Buyer Gst": item.gstid,
        Date: item.updatedAt,
        Description: item.title,
        SAC: "998314",
        "Tax Rate": "18%",
        Rate: item.price,
        QTY: item.countOfMonth,
        Amount: getAmount(item),
        Discount: getDiscount(item),
        "Taxable value": getTaxableValue(item),
        "Total Value": getTaxableValue(item),
        SGST: item.state == "Kerala" ? getGst(item) : "",
        CGST: getGst(item),
        IGST: item.state != "Kerala" ? getGst(item) : "",
        "KFC@1%": getKfc(item),
        "Round off": getRoundOff(item),
        "Invoice total": getInvoiceTotal(item),
      })
    })

    let excel = new ExportJsonExcel({
      datas: [{
        sheetData: exportData,
        sheetName: "Invoice",
        sheetFilter: ['Invoice No', 'Name', 'Email','Address', 'Country', 'State',  'State Code','Phone', 'Buyer Gst', 'Date', 'Description', 'SAC', 'Tax Rate', 'Rate', 'QTY', 'Amount', 'Discount', 'Taxable value', 'Total Value', 'SGST', 'CGST', 'IGST', 'KFC@1%', 'Round off', 'Invoice total'],
        sheetHeader: ['Invoice No', 'Name','Email','Address', 'Country', 'State',  'State Code','Phone', 'Buyer Gst', 'Date', 'Description', 'SAC', 'Tax Rate', 'Rate', 'QTY', 'Amount', 'Discount', 'Taxable value', 'Total Value', 'SGST', 'CGST', 'IGST', 'KFC@1%', 'Round off', 'Invoice total'],

      }], fileName: "Invoice data"
    });
    excel.saveExcel()
  }


  const [pdfExport, setPdfExport] = useState({
    data: {},
    showModal: false,
  });
  const [printf, setPrintf] = useState(false)
  const PdfExportModal = (params) => {
    return (
      <Modal
        isOpen={pdfExport.showModal}
        onClose={() => setPdfExport({ ...pdfExport, showModal: false })}
        rounded="md"
        maxW="750px"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setPdfExport({ ...pdfExport, showModal: false })}
          cursor="pointer"
        />
        <Row justify="center" m={{ y: "10px" }}>
          <Button
            h="2.5rem"
            w="2.5rem"
            bg="info300"
            hoverBg="info400"
            rounded="lg"
            m={{ r: "1rem" }}
            onClick={async (e) => {
              await setPrintf(true)
              await printJS({
                printable: "bulk_invoice",
                type: "html",
                repeatTableHeader: "false",
                css: `${uri}resoursce/css/index.css`,
                targetStyles: ["*"],
              })
              await setPrintf(false)
            }
            }
          >
            <Icon name="Print" size="20px" color="info700" />
          </Button>
        </Row>

        <div id="bulk_invoice" style={{ overflowX: printf ? "visible" : "auto" }}>
          {
            data.data.map((item, i) => (
              <Div m={{ b: "80px" }}>
                <Invoice
                  key={i}
                  _printf={printf}
                  billData={item}
                  showPrint={false}
                // userData={user}
                />
              </Div>
            ))
          }
        </div>
      </Modal>
    );
  };



  useEffect(() => {
  }, []);
  return (
    <AdminDashboardLayout
      // isLoading={data.isLoading}
      notification={notificationData}
      onClose={(e) => {
        setNotificationData({
          content: false,
        });
      }}
    >
      {BillModal()}
      {PdfExportModal()}
      <Div style={{ width: "Calc(100% - 10px)" }}>
        <Row m={{ b: "10px" }}>

          <Col size={{ md: "3", lg: "3", xs: "12", sm: "6" }}>
            <Label>
              Start Date
            </Label>
            <Input
              required="true"
              type="date"
              onChange={(e) => {
                setFilterData({ ...filterData, startDate: e.target.value })
              }}
            ></Input>
          </Col>
          <Col size={{ md: "3", lg: "3", xs: "12", sm: "6" }}>
            <Label>
              End Date
            </Label>
            <Input
              required="true"
              type="date"
              onChange={(e) => {
                setFilterData({ ...filterData, endDate: e.target.value })
              }}
            ></Input>
          </Col>
          <Col size={{ md: "3", lg: "3", xs: "12", sm: "6" }}>
            <Label>
              GST IN
            </Label>
            <DropDown
              datas={[
                { value: "true", title: "GSTIN (B2B)" },
                { value: "false", title: "No Gst (B2C)" },
                { value: "all", title: "All (B2B & B2C)" },
              ]}
              valueKey="title"
              title="No Gst (B2C)"
              defaultValue="false"
              defaultValueKey="value"
              onChange={(data) => {
                setFilterData({ ...filterData, isgst: data.value })
              }}
            ></DropDown>
          </Col>
          <Col size={{ md: "3", lg: "3", xs: "12", sm: "6" }}>
            <Label> </Label>
            <Button
              isLoading={data.isLoading}
              bg="info700"
              onClick={(e) => {
                fetchTransactions();
              }}
            >Fetch</Button>
          </Col>
          <Col size={{ md: "3", lg: "3", xs: "12", sm: "6" }}>
            <Label> </Label>
            <Button
              bg="info700"
              onClick={(e) => {
                exportToExcel();
              }}
            >Export to xlxs</Button>
          </Col>
          <Col size={{ md: "3", lg: "3", xs: "12", sm: "6" }}>
            <Label> </Label>
            <Button
              bg="info700"
              onClick={(e) => {
                setPdfExport({ ...pdfExport, showModal: true })
              }}
            >Export to pdf</Button>
          </Col>

        </Row>
        {
          !data.isLoading && (
            <Table
              data={[
                {
                  title: "Invoice no",
                  key:'invoiceNo'
                },
                {
                  title: "Email",
                  key: "email"
                },
                {
                  title: "Date",
                  key: "updatedAt",
                },
                {
                  title: "Price Paid",
                  key: "paidPrice",
                },
                {
                  title: "Payment Id",
                  key: "trasitionID"
                },

                {
                  title: "Manage",
                  row: [
                    (data, key) => (
                      <Button
                        key={key}
                        h="2rem"
                        w="2rem"
                        bg="success300"
                        hoverBg="success400"
                        rounded="lg"
                        m={{ x: "0.5rem" }}
                        onClick={(e) => {
                          setBillData({ data: data, showModal: true });
                        }}
                      >
                        <Icon name="EyeSolid" color="success700" size="18px" />
                      </Button>
                    ),
                  ],
                },
              ]}
              value={data.data}
            ></Table>
          )
        }

      </Div>
    </AdminDashboardLayout>
  );
};

import React, { useEffect, useState } from "react";
import {
  Div,
  Icon,
  Button,
  Container,
  Notification,
  Text,
  Input,
  Anchor,
} from "atomize";
import { Link, Redirect } from "react-router-dom";
import { authenticate, isAuthenticated, signin } from "../core";
import { signInWithGoogle } from "../core/firebase.util";

const Login = () => {
  const [showPassword, setshowPassword] = useState(false);
  const [gautoLoading, setgautoLoading] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const [values, setValues] = useState({
    loading: false,
    redirectToReferrer: false,
    error: false,
  });

  const { loading, error, redirectToReferrer } = values;
  const { user } = isAuthenticated();

  useEffect(() => {});

  const handleChange = (name) => (event) => {
    setData({
      ...data,
      [name]: event.target.value,
    });
  };

  const redirectuser = () => {
    if (redirectToReferrer) {
      if (user && user.role == "0") {
        return <Redirect to="/admin/dashboard"></Redirect>;
      } else if (user && user.role == "1") {
        return <Redirect to="/user/dashboard"></Redirect>;
      } 
    }

    if (isAuthenticated() && isAuthenticated().user.role == 0) {
      return <Redirect to="/admin/dashboard"></Redirect>;
    } else if (isAuthenticated() && isAuthenticated().user.role == 1) {
      return <Redirect to="/user/dashboard"></Redirect>;
    } 
  };

  const showError = () => {
    return (
      <Notification
        bg="danger700"
        isOpen={error}
        onClose={() => setValues({ ...values, error: false })}
        prefix={
          <Icon
            name="CloseSolid"
            color="white"
            size="18px"
            m={{ r: "0.5rem" }}
          />
        }
      >
        {/* {console.log(error)} */}
        {error.error}
      </Notification>
    );
  };

  const submitLogin = (event) => {
    event.preventDefault();
    setValues({ ...values, loading: true });

    signin(data)
      .then((data) => {
        if (data.error) {
          setValues({
            ...values,
            error: data,
            loading: false,
          });
        } else {
          authenticate(data, () => {
            setValues({
              ...values,
              redirectToReferrer: true,
            });
          });
        }
      })
      .catch((e) => {
        setValues({ ...values, error: e, loading: false });
      });
  };

  return (
    <Container p={{ t: "20px" }} h="100vh">
      {redirectuser()}
      {showError()}
      <br />
      <Div textAlign="center">
        <img src="/img/logo.svg" width="100px" alt="logo" />
      </Div>
      <Text textAlign="center" textSize="display1" tag="h4">
      UTUBELINK
      </Text>
      <br />
      <Div
        m={{ xs: "20px", md: "auto" }}
        bg="white"
        shadow="5"
        rounded="xl"
        p="20px"
        maxW={{ xs: "auto", md: "350px" }}
      >
        <Text textAlign="center" textSize="heading" m={{ b: "0.5rem" }}>
          Sign In
        </Text>
        <Text
          m={{ b: "0.5rem" }}
          textColor="disabled"
          textAlign="center"
          textSize="caption"
        >
          Don't have an account yet?
          <span>
            <Link to="/signup"> Create New</Link>
          </span>
        </Text>
        <Input
          placeholder="Email"
          p={{ x: "2.5rem" }}
          m={{ b: "1rem" }}
          prefix={
            <Icon
              name="UserSolid"
              color="warning800"
              size="16px"
              cursor="pointer"
              pos="absolute"
              top="50%"
              left="0.75rem"
              transform="translateY(-50%)"
            />
          }
          onChange={handleChange("email")}
        />
        <Input
          placeholder="Password"
          m={{ b: "1rem" }}
          type={showPassword ? "text" : "password"}
          suffix={
            <Button
              pos="absolute"
              onClick={() => setshowPassword(!showPassword)}
              bg="transparent"
              w="3rem"
              top="0"
              right="0"
              rounded={{ r: "md" }}
            >
              <Icon
                name={showPassword ? "EyeSolid" : "Eye"}
                color={showPassword ? "danger800" : "success800"}
                size="16px"
              />
            </Button>
          }
          onChange={handleChange("password")}
        />
        <Text m={{ b: "0.5rem" }} textColor="disabled" textSize="caption">
          <Link to="/forgot-password"> Forgot Password</Link>
        </Text>
        <Text m={{b:"1rem"}} textAlign="center" textSize="caption">By using our website, you hereby consent to our <Anchor href="/privacy">Privacy Policy</Anchor> and agree to its <Anchor href="/toc">terms</Anchor>.</Text>

        <Button
          p={{ x: "2.5rem" }}
          hoverShadow="2"
          m={{ r: "1rem" }}
          bg="info800"
          hoverBg="info700"
          onClick={submitLogin}
          isLoading={loading}
        >
          Login
        </Button>

      </Div>
      <Div
        m={{ xs: "20px", md: "auto" }}
        rounded="xl"
        p="20px"
        maxW={{ xs: "auto", md: "350px" }}
      >
        <Button
          p={{ x: "2rem" }}
          hoverShadow="2"
          shadow="3"
          w="100%"
          bg="white"
          textColor="black"
          isLoading={gautoLoading}
          onClick={(e) => {
            setgautoLoading(true)
            signInWithGoogle(undefined,() => {
              setgautoLoading(false)
              setValues({
                ...values,
                redirectToReferrer: true,
              });
            });
          }}
        >Continue with &nbsp;<img src="/img/google.png" width="18px" height="18px" alt="" />
        </Button>
      </Div>
    </Container>
  );
};

export default Login;

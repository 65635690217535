import { Div, Row, Icon, Button, Modal, Text } from "atomize";
import React, { useState } from "react";
import moment from "moment";

const MemberShipItem = ({ data, index, setModal, setId }) => {
  const getProgress = (params) => {
    let today = moment();
    let expire = moment(data?.endDate);
    let totalDays = expire.diff(moment(data?.startDate), "days");
    let expireIn = expire.diff(today, "days");
    let progress;

    if (index == 0) {
      if (today < moment(data?.startDate)) progress = 0;
      else progress = ((totalDays - expireIn) / totalDays) * 100;
    } else progress = 0;
    return progress.toString();
  };

  return (
    <Div h="50px" m={{ x: "5px" }}>
      <Div pos="relative">
        <Div textAlign="center" w="100%" pos="absolute" textColor="white">
          {data?.title}
        </Div>
        <Row>
          <Div bg="gray400" w="100%" h="20px">
            {" "}
            <Div bg="success700" w={`${getProgress()}%`} h="20px"></Div>
          </Div>
        </Row>
      </Div>
      <Div d="flex" justify="space-between" h="10px">
        <Div>{moment(data?.startDate).format("Do MMMM")}</Div>
        <Div d="flex" justify="flex-end">
          {" "}
          {
            setModal != null && setId != null && (
              <Button
                h="1.5rem"
                w="1.5rem"
                onClick={(e) => {
                  setModal(true);
                  setId(data.id);
                }}
                bg="danger300"
                hoverBg="danger400"
                rounded="lg"
                m={{ t: "0.4rem" }}
              >
                <Icon name="Delete" size="20px" color="danger700" />
              </Button>
            )
          }

        </Div>
        <Div>{moment(data?.endDate).format("Do MMMM")} </Div>
      </Div>
      {}
    </Div>
  );
};

export default MemberShipItem;

import React, { useState, useEffect } from "react";
import UserDashboardLayout from "./UserDashboardLayout";
import {
  Div,
  Icon,
  Button,
  Row,
  Modal,
  Text,
  Input,
  Col,
  Label,
  Switch,
} from "atomize/dist";
import Table from "../../components/Table";
import {
  getUrls,
  createUrl,
  editUrl,
  deleteUrl,
  getCurrentAvailability,
  getBrands,
  createBrand,
  deleteBrands,
} from "../../core/apiUser";
import { Link } from "react-router-dom";
import { uri } from "../../core/config";
import Anchor from "atomize/dist/atoms/anchor/Anchor";
import moment from 'moment'
import Loading from "../../components/Loading";
import { isBrandHaveError } from "../../components/ErrorMessageFunction";
export const BrandList = ({ currentAvailiabiltydata }) => {
  const [showAddUrlModal, setShowAddUrlModal] = useState(false);
  const [showDeleteUrlModal, setShowDeleteUrlModal] = useState(false);

  //TO show notification
  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });

  const [addUrlData, setAddUrlData] = useState({
    brandname: null,
  });


  const [deleteUrldata, setDeleteUrLData] = useState(null);

  const [isAddUrlLoading, setIsAddUrlLoading] = useState(false);
  const [isDeleteUrlLoading, setIsDeleteUrlLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [urlData, setUrlData] = useState({
    data: [],
    isLoading: false,
    error: false,
  });
  const [currentAvailiabilty, setCurrentAvailiabilty] = useState({
    data: currentAvailiabiltydata,
    isLoading: false,
  });
  const fetchUrls = () => {
    setIsDataLoading(true);
    getBrands()
      .then((data) => {
        if (data.error) {
          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          data.reverse();
          setUrlData({ ...urlData, data: data });
        }
        setIsDataLoading(false);
        // fetchAvailiabilty();
      })
      .catch((e) => {
        setNotificationData({
          content: e.error,
          bg: "danger700",
        });
      });
  };

  const fetchAvailiabilty = (params) => {
    getCurrentAvailability()
      .then((data) => {
        if (data.error) {
          setNotificationData({
            content: data.error.msg,
            bg: "danger700",
          });
        } else {
          setCurrentAvailiabilty({ ...currentAvailiabilty, data: data });
        }
        setIsDataLoading(false);
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
        setIsDataLoading(false);
      });
  };
  const handleChange = (name) => (event) => {
    setAddUrlData({ ...addUrlData, [name]: event.target.value });
  };

  const addUrl = (event) => {
    event.preventDefault();


    setIsAddUrlLoading(true);

    createBrand(addUrlData)
      .then((data) => {
        if (data.error) {
          setIsAddUrlLoading(false);

          setNotificationData({
            content: data.error,
            bg: "danger700",
          });
        } else {
          setIsAddUrlLoading(false);
          setNotificationData({
            content: "URL Added",
            bg: "success700",
          });
          setShowAddUrlModal(false);
          fetchUrls();
        }
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };


  const deleteUrlHandler = () => {
    setIsDeleteUrlLoading(true);

    deleteBrands(deleteUrldata.id)
      .then((data) => {
        if (data.error) {
          setShowDeleteUrlModal(false);

          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          setShowDeleteUrlModal(false);
          setNotificationData({
            content: "Brand Deleted",
            bg: "success700",
          });
          setShowDeleteUrlModal(false);
          setIsDeleteUrlLoading(false);
        }
        setNotificationData({
          content: "Brand Deleted",
          bg: "success700",
        });
        setShowDeleteUrlModal(false);
        setIsDeleteUrlLoading(false);
        fetchUrls();
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  const DeleteUrlModal = (params) => {
    return (
      <Modal
        isOpen={showDeleteUrlModal}
        onClose={() => setShowDeleteUrlModal(false)}
        rounded="md"
        align="start"
        rounded="md"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setShowDeleteUrlModal(false)}
          cursor="pointer"
        />
        <Div d="flex" m={{ b: "4rem" }}>
          <Icon
            name="AlertSolid"
            color="warning700"
            m={{ t: "0.35rem", r: "0.5rem" }}
          />
          <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="subheader">
            Do you really want to delete Brand {deleteUrldata?.brandname}?
          </Text>
        </Div>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setShowDeleteUrlModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            isLoading={isDeleteUrlLoading}
            onClick={(e) => deleteUrlHandler()}
            bg="info700"
          >
            Delete
          </Button>
        </Div>
      </Modal>
    );
  };

  useEffect(async () => {
    await fetchUrls();
  }, []);

  const AddUrlModal = () => {
    return (
      <Modal
        isOpen={showAddUrlModal}
        onClose={() => setShowAddUrlModal(false)}
        rounded="md"
        maxW="48rem"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={() => setShowAddUrlModal(false)}
          cursor="pointer"
        />

        <Row
          p={{
            md: { l: "0.5rem", t: "0.25rem" },
          }}
          m={{ b: "2rem" }}
        >
          <Col>
            <Text textAlign="center" textSize="title" m={{ b: "1rem" }}>
              Add New Brand
            </Text>

            <Input
              placeholder="Brand name/your slash path"
              p={{ x: "2.5rem" }}
              m={{ b: "1rem" }}
              w="100%"
              prefix={
                <Icon
                  name="Link"
                  color="warning800"
                  size="16px"
                  cursor="pointer"
                  pos="absolute"
                  top="50%"
                  left="0.75rem"
                  transform="translateY(-50%)"
                />
              }
              onChange={handleChange("brandname")}
            />

          </Col>
        </Row>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setShowAddUrlModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            bg="info700"
            onClick={(e) => {
              addUrl(e);
            }}
            isLoading={isAddUrlLoading}
          >
            Add
          </Button>
        </Div>
      </Modal>
    );
  };

  if (isDataLoading) {

  }

  return (
    <Div p={{ x: "10px" }} minW="100%">
      {DeleteUrlModal()}
      <Div d="flex" justify="center" textSize="heading">Brands</Div>
      <Loading h="200px" isLoading={isDataLoading}>

        <Row>
          {

            currentAvailiabilty && currentAvailiabilty.data && urlData.data && urlData.data.map((data, index) => (
              <Col key={index} size={{ xs: "12", md: "4", lg: "3" }} >
                <Div border="1px solid" bg={!data.problem ? "white" : "danger300"} borderColor="gray300" textColor="black900" d="flex" m={{ y: "20px" }} p="20px" shadow="3" rounded="md">
                  {data.problem &&
                    (<Div m={{ r: "10px" }}>
                      <Icon name="AlertSolid" color="danger700"></Icon>
                    </Div>)}
                  <Div w="100%" d="flex" justify="space-between">
                    <a target="_blank" href={`/user/brands/${data.id}/urls`}>
                      <Div textColor="black900">
                        {data?.brandname}
                      </Div>

                    </a>

                    <Button
                      h="2rem"
                      w="2rem"
                      bg="danger300"
                      hoverBg="danger400"
                      rounded="lg"
                      m={{ x: "0.5rem" }}
                      onClick={(e) => {
                        setDeleteUrLData(data);
                        setShowDeleteUrlModal(true);
                      }}
                    >
                      <Icon name="DeleteSolid" size="20px" color="danger700" />
                    </Button>
                  </Div>
                </Div>

              </Col>
            ))
          }
        </Row>
      </Loading>

    </Div>
  );
};

import React, { useState, useEffect } from "react";
import { Div, Icon, Button, Row, Modal, Text, Input, Col } from "atomize/dist";
import Table from "../../components/Table";
import {
  getBrandsByUserAdmin,
  editUrlAdmin,
  deleteUrlAdmin,
  getAdminUrlsByUserId,
} from "../../core/apiAdmin";
import { uri } from "../../core/config";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { Link } from "react-router-dom";
import { deleteBrands } from "../../core/apiUser";

export const ListBrands = ({ match }) => {
  const [urlData, setUrlData] = useState({
    data: [],
    user: {},
    isLoading: false,
    error: false,
  });

  const fetchUrls = (params) => {
    setUrlData({ ...urlData, isLoading: true });
    getBrandsByUserAdmin(match.params.userId)
      .then((data) => {
        if (data.error) {
          setUrlData({ ...urlData, isLoading: false });
          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          setUrlData({ ...urlData, isLoading: false, data: data.brand, user: data.user });
        }
      })

      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };
  useEffect(() => {
    fetchUrls();
  }, []);

  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });


  const [showDeleteUrlModal, setShowDeleteUrlModal] = useState(false);
  const [deleteUrlId, setDeleteUrlId] = useState(null);
  const [isDeleteUrlLoading, setIsDeleteUrlLoading] = useState(false);


  const deleteUrlHandler = () => {
    setIsDeleteUrlLoading(true);

    deleteBrands(deleteUrlId.id)
      .then((data) => {
        if (data.error) {
          setShowDeleteUrlModal(false);

          setNotificationData({
            content: JSON.parse(data.error).error,
            bg: "danger700",
          });
        } else {
          setShowDeleteUrlModal(false);
          setNotificationData({
            content: "Brand Deleted",
            bg: "success700",
          });
          setShowDeleteUrlModal(false);
        }
        setShowDeleteUrlModal(false);
        setNotificationData({
          content: "Brand Deleted",
          bg: "success700",
        });
        setIsDeleteUrlLoading(false);
        fetchUrls();
      })
      .catch((err) => {
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };

  const DeleteUrlModal = (params) => {
    return (
      <Modal
        isOpen={showDeleteUrlModal}
        onClose={() => setShowDeleteUrlModal(false)}
        rounded="md"
        align="start"
        rounded="md"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setShowDeleteUrlModal(false)}
          cursor="pointer"
        />
        <Div d="flex" m={{ b: "4rem" }}>
          <Icon
            name="AlertSolid"
            color="warning700"
            m={{ t: "0.35rem", r: "0.5rem" }}
          />
          <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="subheader">
            Do you really want to delete Brand?
          </Text>
        </Div>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setShowDeleteUrlModal(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            isLoading={isDeleteUrlLoading}
            onClick={(e) => deleteUrlHandler()}
            bg="info700"
          >
            Delete
          </Button>
        </Div>
      </Modal>
    );
  };

  return (
    <Div>
      <AdminDashboardLayout
        isLoading={urlData.isLoading}
        notification={notificationData}
        onClose={(e) => {
          setNotificationData({
            content: false,
          });
        }}
      >
        {DeleteUrlModal()}

        <Div>
          <Link to={`/admin/users`}>Users </Link>
        / {
            urlData.user?.name
          } / Brands
        </Div>

        <Row>
          {
            urlData.data && urlData.data.map((data, index) => (
              <Col key={index} size={{ xs: "12", md: "4", lg: "3" }}>
                  <Div textColor="black900" d="flex" justify="space-between" m={{ y: "20px" }} p="20px" shadow="3" rounded="md">
                    
                  <Link to={`/admin/users/brand/url/list/${data.id}`}>
                    <Div textColor="black900">
                      {data?.brandname}
                    </Div>
                    
                </Link>

                    <Button
                      h="2rem"
                      w="2rem"
                      bg="danger300"
                      hoverBg="danger400"
                      rounded="lg"
                      m={{ x: "0.5rem" }}
                      onClick={(e) => {
                        setDeleteUrlId(data);
                        setShowDeleteUrlModal(true);
                      }}
                    >
                      <Icon name="DeleteSolid" size="20px" color="danger700" />
                    </Button>
                  </Div>

              </Col>
            ))
          }
        </Row>
      </AdminDashboardLayout>
    </Div>
  );
};

import { Anchor, Div, Dropdown } from "atomize";
import React, { useState, useEffect } from "react";

const DropDown = ({
  width,
  title,
  defaultValue = false,
  defaultValueKey = false,
  datas = [],
  valueKey,
  onChange = () => {},
  isloading = false,
  loadingTitle = "Loading...",
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(title);
  useEffect(() => {
    if (!defaultValue || !defaultValueKey) {
      return;
    }
    datas.map((item, i) => {
      if (item[defaultValueKey] == defaultValue) {
        setSelectedTitle(item[valueKey]);
      }
    });
  }, []);

  const menuList = (
    <Div>
      {datas.map((data, index) => (
        <Anchor
          key={index}
          d="block"
          p={{ y: "0.25rem", x: "10px" }}
          onClick={(e) => {
            onChange(data);
            setSelectedTitle(data[valueKey]);
            setShowDropdown(!showDropdown);
          }}
        >
          {data[valueKey]}
        </Anchor>
      ))}
    </Div>
  );

  return (
    <Dropdown
      isOpen={showDropdown}
      isLoading={isloading}
      w={width}
      onClick={() => {
        setShowDropdown(!showDropdown);
      }}
      menu={menuList}
    >
      {isloading ? loadingTitle : selectedTitle}
    </Dropdown>
  );
};

export default DropDown;

import React, { useEffect, useState } from "react";
import {
  Div,
  Icon,
  Button,
  Container,
  Notification,
  Text,
  Input,
  Anchor,
} from "atomize";
import { Link, Redirect, useParams } from "react-router-dom";
import { authenticate, isAuthenticated, signUp } from "../core";
import { signInWithGoogle } from "../core/firebase.util";
import { uri } from "../core/config";
import { useHistory } from "react-router-dom";
import { Fragment } from "react";

const SignUp = ({ location, match }) => {
  const history = useHistory();

  const [showPassword, setshowPassword] = useState(false);
  const [gautoLoading, setgautoLoading] = useState(false);
  const [showPasswordConfirm, setshowPasswordConfirm] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    dob: "2021-01-15 00:00:00",
    name: "",
    acceptTerm: false,
  });

  const [values, setValues] = useState({
    loading: false,
    bg: "",
    error: false,
    error1: false,
  });

  const { loading, error, error1 } = values;
  const params = useParams();
  useEffect(() => {
    setReferral();
  }, []);

  const handleChange = (name) => (event) => {
    setData({
      ...data,
      [name]: event.target.value,
    });
  };
  const setReferral = () => {
    const [...code] = location.search.split("=");
    if (code[0] == "?ref")
      if (code[1] != "") setData({ ...data, referalcode: code[1] });
  };
  const showError = () => {
    return (
      <Notification
        bg="danger700"
        isOpen={error}
        onClose={() => setValues({ ...values, error: false })}
        prefix={
          <Icon
            name="CloseSolid"
            color="white"
            size="18px"
            m={{ r: "0.5rem" }}
          />
        }
      >
        {values.error
          ? values.error
          : values.error.error &&
          values.error.error.map((x, y) => <p>{x.msg}</p>)}
      </Notification>
    );
  };
  const showErrorPass = () => {
    return (
      <Notification
        bg={values.bg}
        isOpen={values.error1}
        onClose={() => setValues({ ...values, error1: false })}
      >
        {error1}
      </Notification>
    );
  };

  const submitSignUp = (event) => {
    event.preventDefault();

    console.log(data);
    if (data.confirmPassword == data.password) {
      setValues({ ...values, loading: true });
      signUp(data)
        .then((_data) => {
          console.log(_data);
          if (_data.error) {
            setValues({
              error: _data.error,
              loading: false,
            });
          } else {
            console.log(_data);
            setValues({
              ...values,
              loading: false,
              error1: " Check email and verify your account",
              bg: "success700",
            });
            setTimeout(function () {
              history.push("/login");
            }, 3000);
          }
        })
        .catch((e) => {
          console.log(e);
          setValues({ ...values, error: "Error", loading: false });
        });
    } else {
      setValues({
        ...values,
        loading: false,
        error1: " Password and Confirm Password doesn't match",
        bg: "danger700",
      });
    }
  };

  const checkPassword=()=>{
    let res=[];
    if(data.password.length==0){
      return [];
    }
    if(data.confirmPassword.length>0&&(data.confirmPassword!=data.password)){
      res.push({msg:"Password must be equal"})
    }
    if(data.password.length<8){
      res.push({msg:"Password must contain 8 character"})
    }
    if(!/[A-Z]/.test(data.password)){
      res.push({msg:"Password must contain an uppercase letter"})
    }
    if(!/[a-z]/.test(data.password)){
      res.push({msg:"Password must contain an lowercase letter"})
    }
    if(!/\d/.test(data.password)){
      res.push({msg:"Password must contain a number"})
    }
    return res;
  }

  return (
    <Container p={{ t: "20px" }} h="100vh">
      {showError()}
      {showErrorPass()}
      {/* {setReferral()} */}
      <br />
      <Div textAlign="center">
        <img src="/img/logo.svg" width="100px" alt="logo" />
      </Div>
      <Text textAlign="center" textSize="display1" tag="h4">
        UTUBELINK
      </Text>
      <br />
      <Div
        m={{ xs: "20px", md: "auto" }}
        bg="white"
        shadow="5"
        rounded="xl"
        p="20px"
        maxW={{ xs: "auto", md: "350px" }}
      >
        <Text textAlign="center" textSize="heading" m={{ b: "1rem" }}>
          Sign Up
        </Text>
        <Text
          m={{ b: "0.5rem" }}
          textColor="disabled"
          textAlign="center"
          textSize="caption"
        >
          Already have an account?{" "}
          <span>
            <Link to="/login"> Login</Link>
          </span>
        </Text>
        <Input
          placeholder="Name"
          type="email"
          //   p={{ x: "2.5rem" }}
          m={{ b: "1rem" }}
          onChange={handleChange("name")}
        />
        {/* <Input
          placeholder="Date of Birth"
          type="date"
          //   p={{ x: "2.5rem" }}
          m={{ b: "1rem" }}
          onChange={handleChange("dob")}
        /> */}
        <Input
          placeholder="Email"
          p={{ x: "2.5rem" }}
          m={{ b: "1rem" }}
          prefix={
            <Icon
              name="UserSolid"
              color="warning800"
              size="16px"
              cursor="pointer"
              pos="absolute"
              top="50%"
              left="0.75rem"
              transform="translateY(-50%)"
            />
          }
          onChange={handleChange("email")}
        />
        <Input
          placeholder="Password"
          m={{ b: "1rem" }}
          type={showPassword ? "text" : "password"}
          // suffix={
          //   <Button
          //     pos="absolute"
          //     onClick={() => setshowPassword(!showPassword)}
          //     bg="transparent"
          //     w="3rem"
          //     top="0"
          //     right="0"
          //     rounded={{ r: "md" }}
          //   >
          //     <Icon
          //       name={showPassword ? "EyeSolid" : "Eye"}
          //       color={showPassword ? "danger800" : "success800"}
          //       size="16px"
          //     />
          //   </Button>
          // }
          onChange={handleChange("password")}
        />
        <Input
          placeholder="Confirm Password"
          m={{ b: "1rem" }}
          type={showPasswordConfirm ? "text" : "password"}
          suffix={
            <Button
              pos="absolute"
              onClick={() => setshowPasswordConfirm(!showPasswordConfirm)}
              bg="transparent"
              w="3rem"
              top="0"
              right="0"
              rounded={{ r: "md" }}
            >
              <Icon
                name={showPasswordConfirm ? "EyeSolid" : "Eye"}
                color={showPasswordConfirm ? "danger800" : "success800"}
                size="16px"
              />
            </Button>
          }
          onChange={handleChange("confirmPassword")}
        />
        <Div textSize="caption" m={{b:"10px"}} textColor="danger700">
        {checkPassword().map((item,i)=>(
          <Fragment key={i}>
            <Text textSize="caption" textColor="danger700">{item.msg}</Text>
          </Fragment>
        ))}
        </Div>
        {/* <Text m={{ b: "1rem" }}>
          By Signing Up, you agree to Utubelink's{" "}
          <a href={`${uri}/toc`} target="_blank">
            Terms of Service 
          </a>{" "}and{" "}
          <a href={`${uri}/privacy`} target="_blank">
            Privacy policy 
          </a>{" "}
        </Text> */}
        <Text m={{b:"1rem"}} textAlign="center" textSize="caption">By using our website, you hereby consent to our <Anchor href="/privacy">Privacy Policy</Anchor> and agree to its <Anchor href="/toc">terms</Anchor>.</Text>

        <Button
          p={{ x: "2.5rem" }}
          hoverShadow="2"
          m={{ r: "1rem" }}
          bg="info800"
          hoverBg="info700"
          onClick={submitSignUp}
          isLoading={loading}
        >
          Sign Up
        </Button>
        

      </Div>

      <Div
        m={{ xs: "20px", md: "auto" }}
        rounded="xl"
        p="20px"
        maxW={{ xs: "auto", md: "350px" }}
      >
        <Button
          p={{ x: "2rem" }}
          hoverShadow="2"
          shadow="3"
          w="100%"
          bg="white"
          textColor="black"
          isLoading={gautoLoading}
          onClick={(e) => {
            setgautoLoading(true);
            signInWithGoogle(data.referalcode, () => {
              setgautoLoading(false);
              if (isAuthenticated() && isAuthenticated().user.role == 0) {
                history.push("/admin/dashboard");
              } else if (
                isAuthenticated() &&
                isAuthenticated().user.role == 1
              ) {
                history.push("/user/dashboard");
              }
            });
          }}
        >
          Continue with &nbsp;
          <img src="/img/google.png" width="18px" height="18px" alt="" />
        </Button>
      </Div>
    </Container>
  );
};

export default SignUp;

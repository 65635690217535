import React, { Fragment, useEffect, useState } from "react";
import {
  Div,
  Icon,
  Button,
  SideDrawer,
  Row,
  Text,
  Col,
  currentDevice,
  Container,
  Notification,
} from "atomize";
import { isAuthenticated, signout } from "../../core";
import { useHistory } from "react-router-dom";
import Footer from "../../components/Footer";
import Anchor from "atomize/dist/atoms/anchor/Anchor";

const UserDashboardLayout = ({
  isLoading = false,
  children,
  notification,
  onClose = (e) => { },
}) => {
  const [deviseSize, setDeviseSize] = useState("");
  const [sideDrawerIsOpen, setSideDrawerIsOpen] = useState(false);

  useEffect(() => {
    setDeviseSize(currentDevice());
  }, []);

  const history = useHistory();

  const { user } = isAuthenticated();

  const notificationFn = () => (
    <Notification
      isOpen={notification && notification.content && true}
      onClose={(e) => onClose(e)}
      bg={notification && notification.bg && notification.bg}
    >
      {notification && notification.content}
    </Notification>
  );

  const menuItems = [
    {
      name: "Dashboard",
      path: "/user/dashboard",
      icon: "HomeSolid"
    },
    {
      name: "Urls",
      path: "/user/urls",
      icon: "Link"
    },
    {
      name: "Brands",
      path: "/user/brands",
      icon: "Draft"
    },

    {
      name: "Membership",
      path: "/user/membership",
      icon: "Store"
    },
    {
      name: "Transactions",
      path: "/user/transactions",
      icon: "Card"
    },
    {
      name: "Referral",
      path: "/user/referral",
      icon: "History"
    },
    {
      name: "Settings",
      path: "/user/settings",
      icon: "Settings"
    },
  ];

  const sizeSideDrawer = () => {
    return (
      <SideDrawer
        isOpen={sideDrawerIsOpen}
        onClose={(e) => setSideDrawerIsOpen(false)}
        w={{ xs: "12rem", sm: "12rem" }}
      >
        <Div minH="calc(100vh - 180px)">{sideBarContent()}</Div>
        <Div textSize="body" h="80px">
          <Button
            bg="white"
            hoverBg="gray400"
            rounded="lg"
            m={{ r: "1rem", b: "10px" }}
            h="30px"
            w="100%"
            textColor="black400"
          >
            {user && user.name}
          </Button>
          <Button
            bg="danger700"
            rounded="lg"
            m={{ r: "1rem", b: "10px" }}
            h="30px"
            onClick={(e) => {
              signout(() => {
                history.push("/login");
              });
            }}
            w="100%"
          >
            Logout
          </Button>
        </Div>
      </SideDrawer>
    );
  };

  const showHamberg = () => {
    if (sideBarCal()) {
      return (
        <Button
          bg="transparent"
          h="30px"
          onClick={(e) => setSideDrawerIsOpen(true)}
        >
          <Icon name="Menu" size="20px" />
        </Button>
      );
    }
  };

  const sideBarCal = () => {
    if (deviseSize == "xs" || deviseSize == "sm" || deviseSize == "md") {
      return true;
    } else {
      return false;
    }
  };

  const sideBarContent = () => {
    return (
      <Fragment>
        {menuItems.map((item, i) => (
          <Div
            key={i}
            bg={history.location.pathname == item.path ? "gray400" : "white"}
            hoverBg="gray400"
            rounded="md"
            m={{ r: "1rem", b: "10px" }}
            p={{ x: "10px", y: "8px" }}
            h="40px"
            w="100%" d="flex"
            textColor="black400"
            onClick={(e) => {
              history.push(item.path);
            }}
          >
            <Icon name={item.icon} color="black400" size="20px" ></Icon>
            <Anchor textColor="black400" hoverTextColor="black600"  m={{ x: "10px" }} textAlign="left"
            >{item.name}</Anchor>
          </Div>
        ))}
      </Fragment>
    );
  };

  const showSideBar = () => {
    return (
      <Div
        w="200px"
        maxW="20vw"
        bg="white"
        shadow="4"
        h="100vh"
        p={{ x: "10px", y: "20px" }}
      >
        <Div textAlign="center" m={{ b: "40px" }}>
          <img
            src="/img/logo.svg"
            alt=""
            style={{ height: "100px", width: "100px" }}
          />
          <Text textSize="body" textColor="info800" tag="h1">
           UTUBELINK
          </Text>
        </Div>
        <Div minH="calc(100vh - 295px)">{sideBarContent()}</Div>
        <Div textSize="body" >
          <Button
            bg="white"
            hoverBg="gray400"
            rounded="lg"
            m={{ r: "1rem", b: "10px" }}
            h="30px"
            w="100%"
            textColor="black400"
          >
            {user && user.name}
          </Button>
          <Button
            bg="danger700"
            rounded="lg"
            m={{ r: "1rem", b: "10px" }}
            h="30px"
            onClick={(e) => {
              signout(() => {
                history.push("/login");
              });
            }}
            w="100%"
          >
            Logout
            </Button>
        </Div>
      </Div>
    );
  };

  const firstNavBar = () => {
    return (
      <Div h="40px" bg="white" shadow="2">
        <Row w="100%" p={{ y: "5px" }}>
          <Button
            bg="white"
            hoverBg="white"
            rounded="lg"
            m={{ x: "1rem", y: "auto" }}
            h="30px"
          >
            <Div
              d="flex"
              align="center"
              onClick={(e) => history.push("/user/dashboard")}
            >
              <img
                src="/img/logo.svg"
                alt=""
                style={{ height: "30px", width: "40px" }}
              />
              <Text textSize="body" textColor="info800" tag="h1">
                {" "}
                UTUBELINK
              </Text>
            </Div>
            <Text
              m={{ x: { xs: "0.2rem", md: "1rem" }, t: "2px" }}
              textSize="body"
              textColor="black700"
            >
              User
            </Text>
          </Button>

          {/* 
                <Col size={{ xs: "auto", md: "7" }} p={{ y: "5px" }}>
                    <Button
                        bg="white"
                        hoverBg="gray400"
                        rounded="lg"
                        m={{ r: "1rem", y: "auto" }}
                        h="30px"
                        textColor="black400"
                    >
                        About
                </Button>
                </Col> */}
        </Row>
      </Div>
    );
  };

  const secondNavBar = () => {
    return (
      <Div h="50px" bg="white" shadow="2">
        <Div d="flex" justify="space-between" p={{ y: "5.5px" }} m={{t:"10px"}}>
          <Div
            d="flex"
            align="center"
            onClick={(e) => history.push("/user/dashboard")}
          >
            <img
              src="/img/logo.svg"
              alt=""
              style={{ height: "30px", width: "40px" }}
            />
            <Text textSize="body" textColor="info800" tag="h1">
            UTUBELINK
              </Text>
          </Div>
          {showHamberg()}
        </Div>
      </Div>
    );
  };

  return (
    <Div >
      {notificationFn()}
      {sizeSideDrawer()}
      {sideBarCal() && secondNavBar()}
      <Row w="100%" m={{ x: "0px" }}>
        {!sideBarCal() && showSideBar()}
        <Div
          p="10px"
          flexGrow="1"
          w="100%"
          style={{ overflowY: 'auto' }}
          maxH="100vh"
        >
          {isLoading ? (
            <Div w="100%" h="500px" className="loading" textAlign="center">
              <Icon
                name="Loading2"
                m={{ l: !sideBarCal() ? "-50px" : "-25px" }}
                size="50px"
                color="info700"
                className="loading-center"
              />
            </Div>
          ) : !sideBarCal() ? (
            <Div p={{ x: "20px", y: "20px" }}>{children}</Div>
          ) : (
                <Div p={{ x: "10px", y: "10px" }}>{children}</Div>
              )}
          <Footer></Footer>
        </Div>
      </Row>
    </Div>
  );
};

export default UserDashboardLayout;

import React, { useState, useEffect } from "react";
// import logo from "../logo.svg";
import { Button, Icon, Div, Row } from "atomize/dist";
import printJS from "print-js";
import moment from "moment";
import { uri } from '../core/config'
import { inWords } from "../core/NumberToWords";
import { stateTaxValue, getAmount, getDiscount, getTaxableValue, getGst, getKfc, getTotal, getRoundOff, getInvoiceNumber, getInvoiceTotal } from "../components/InvoiceFunctions";
import { getStateCode } from "../core/apiCore";
const Invoice = ({ userData, billData, showPrint = true,_printf=false }) => {
  const [printf, setPrintf] = useState(_printf)
  useEffect(() => {
    if(!showPrint){
      setPrintf(true)
    }
    console.log(billData);
  }, [])

  // const stateTaxValue = () => {
  //   if (billData.state == "Kerala") {
  //     return 19;
  //   } else {
  //     return 18;
  //   }
  // }

  // const getAmount = () => {
  //   return (billData.price  * billData.countOfMonth);
  // }
  // const getDiscount =()=> billData.offerPrice* billData.countOfMonth;


  // const getTaxableValue = () => {
  //    let value =  ((getAmount()-getDiscount())/(stateTaxValue()+100))*100;

  //    return Math.round((value + Number.EPSILON) * 100) / 100;
  // }

  // const getGst=()=>{
  //   let amount = (getTaxableValue());
  //   amount = (amount/100)*9;
  //   return Math.round((amount + Number.EPSILON) * 100) / 100;
  // }

  // const getKfc=()=>{
  //   if(billData.state == "Kerala") {
  //     let amount = getTaxableValue();
  //     amount = (amount/100)*1;
  //     return Math.round((amount + Number.EPSILON) * 100) / 100
  //   }else{
  //     return 0;
  //   }
  // }

  // const getTotal=()=>{
  //   let amount = getTaxableValue()+getGst()+getGst()+getKfc();
  //   return amount;
  // }

  // const getRoundOff=()=>{
  //   let Tamount = getTotal();
  //   let amount  = getAmount()-getDiscount();
  //   return Math.round((amount-Tamount + Number.EPSILON) * 100) / 100;
  // }

  // const getInvoiceNumber =()=>{
  //   let billdate = new Date(billData.date);
  //   let value = billdate.getFullYear();
  //   let year = billdate.getFullYear()%100;
  //   if(billdate.getMonth()<3){
  //     return "UBK/" +(year-1) + "-" + year+"/"+billData.invoiceno;
  //   }else{
  //     return "UB/"+year + "-" + (year+1)+"/"+billData.invoiceno;

  //   }
  // }
  // const getInvoiceTotal=()=>{
  //   return Math.round((getTotal()+getRoundOff() + Number.EPSILON) * 100) / 100;
  // }


  return (
    <div style={{ width: printf ? "100%" : "auto" }}>
     

      <div id="invoice" style={{ overflowX: printf ? "visible" : "auto" }}>
        <table className="invoice-table border-black" style={{ width: printf ? "100%" : "auto" }}>
          <tr>
            <td>
              GSTIN:32DEJPM0328M1ZG
            </td>
            <td>
              MOBILE: +918590583893
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }} colSpan="2">
              Tax Invoice
            </td>
          </tr>
          <tr>
            <td id="invoice-address" rowSpan="2" colSpan="2">
              <div style={{ display: "flex", marginLeft: "auto", marginRight: "auto", justifyContent: "center" }} >
                <img src="/img/logo.svg" width="40px" alt="logo" />
                <div style={{ height: "100px" }}>
                  <span style={{ fontWeight: "600" }}> UTUBELINK </span>
                  <br />
                  <span className="address-small">
                    First Floor, 8/44K, Noahs Ark Building, Angamaly South,
                    Nedumbassery, Ernakulam, Kerala, 683573 <br />
                    utubelink.net
                </span>
                </div>
              </div>
            </td>

          </tr>

          <tr></tr>

          <tr>
            <td>
              Invoice NO: {getInvoiceNumber(billData)}
            </td>
            <td>
              Date: {billData.date.split('T')[0]}  UTC
            </td>
          </tr>

          <tr>
            <td >
              Details of buyer (Billed to) <br />
              Name : {billData.name} <br />
              Address: {billData.address} <br />
              Country: {billData.country} <br />
              State: {billData.state} <br />
              State Code: {billData.country == "India" && getStateCode( billData.state)} <br />
              Phone: {billData.phone} <br />
              email: {billData.email} <br />
            </td>
            <td style={{ textAlign: "start", verticalAlign: "top" }}>
              Buyer GSTIN: {billData.gstid}
            </td>
          </tr>
          <tr>
            <td colSpan='2' style={{ padding: "0px", border: "none !important" }}>
              <table className="second-invoice-table top-no-border">
                <tr className="top-no-border">
                  <td>SL.NO</td>
                  <td>Description</td>
                  <td>SAC</td>
                  <td>Tax Rate</td>
                  <td>Rate</td>
                  <td>QTY</td>
                  <td>Amount</td>
                  <td style={{ width: "100px" }}>Discount</td>
                  <td>Taxable value</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>{billData.title}</td>
                  <td>998314</td>
                  <td>
                    {/* {stateTaxValue()}% */}18%
                  </td>
                  <td>{billData.price}</td>
                  <td>{billData.countOfMonth}</td>
                  <td>{getAmount(billData)}</td>
                  <td>{getDiscount(billData)}</td>
                  <td>{getTaxableValue(billData)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan="6" rowSpan="2">
                    Amount (in Words): INR {inWords(getInvoiceTotal(billData)).toLocaleUpperCase()}
                  </td>
                  <td colSpan="2">Total Value:</td><td>{getTaxableValue(billData)}</td>
                </tr>

                <tr>
                  <td colSpan="2">CGST:</td><td>{billData.state == "Kerala" && getGst(billData)}</td>
                </tr>
                <tr className="bottom-no-border">
                  <td colSpan="6" style={{ textAlign: "start", verticalAlign: "top" }} rowSpan="7">
                    Declaration: <br />
                    Certified that the particulars given above are true and correct.
                    This is computer genereated invoce, no signature required.
                  </td>
                  <td colSpan="2">SGST:</td><td>{billData.state == "Kerala" && getGst(billData)}</td>
                </tr>
                <tr>
                  <td colSpan="2">IGST:</td><td>{billData.state != "Kerala" && (getGst(billData) +getGst(billData))}</td>
                </tr>
                <tr>
                  <td colSpan="2">KFC@1%:</td><td>{getKfc(billData)}</td>
                </tr>
                <tr>
                  <td colSpan="2">Round off</td><td>{getRoundOff(billData)}</td>
                </tr>
                <tr>
                  <td colSpan="2">Invoice total:</td><td>{getInvoiceTotal(billData)}</td>
                </tr>
                <tr className="bottom-no-border">
                  <td colSpan="3" style={{ height: "100px" }}>
                    FOR <strong>UTUBELINK</strong>
                    <br />
                    <br />
                    Authorized Signature
                  </td>
                </tr>

              </table>
            </td>
          </tr>

        </table>
      </div>
      {
        showPrint && (
          <Row justify="center" m={{ y: "10px" }}>
            <Button
              h="2.5rem"
              w="2.5rem"
              bg="info300"
              hoverBg="info400"
              rounded="lg"
              m={{ r: "1rem" }}
              onClick={async (e) => {
                await setPrintf(true)
                await printJS({
                  printable: "invoice",
                  type: "html",
                  documentTitle:getInvoiceNumber(billData),
                  css: `${uri}resoursce/css/index.css`,
                  targetStyles: ["*"],
                })
                await setPrintf(false)
              }
              }
            >
              <Icon name="Print" size="20px" color="info700" />
            </Button>
          </Row>
        )
      }
    </div>
  );
};

export default Invoice;
import React, { useState,useEffect } from 'react'
import {
    Div,
    Icon,
    Button,
    Row,
    Modal,
    Text,
    Input,
    Col,
    Label,
    Switch,
} from "atomize/dist";
import Anchor from "atomize/dist/atoms/anchor/Anchor";
import DropDown from "./DropDown";
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

export const CopyUrlItem = ({ url, availability ,OnChange=()=>{}}) => {

    const [selectedDomain, setSelectedDomain] = useState("")

    const getSubdomains = (subdomains) => {
        let res = [];
        for (let i = 0; i < subdomains.length; i++) {
            const element = subdomains[i];
            res.push({ title: element, value: element });
        }
        return res;
        // return [{ title: "element", value: "element" }]

    }

    useEffect(() => {
        setSelectedDomain(getSubdomains(availability?.subDomain)[0].title)
    }, [])

    return (
        <Row>
            <Anchor m={{ t: "8px", r: "5px" }} style={{ wordWrap: 'break-word' }} textColor="danger700" target="_blank" href={`http://${selectedDomain}/${url}`}>https://</Anchor>
            <DropDown
                datas={getSubdomains(availability?.subDomain)}
                valueKey="title"
                title="Domains"
                defaultValue={getSubdomains(availability?.subDomain)[0].title}
                defaultValueKey='title'
                width="100"
                onChange={(data) => {
                    
                    setSelectedDomain(data.title)
                }}
            ></DropDown>
            <Anchor m={{ t: "8px", l: "5px" }} style={{ wordWrap: 'break-word' }} textColor="danger700" target="_blank" href={`http://${selectedDomain}/${url}`}>/{url}</Anchor>

            <CopyToClipboard
                text={`https://${selectedDomain}/${url}`}
                onCopy={() =>OnChange() }
            >
                <Button
                    h="2rem"
                    w="2rem"
                    bg="danger300"
                    hoverBg="danger400"
                    rounded="lg"
                    m={{ x: "0.5rem" }}
                >
                    <FileCopyOutlinedIcon fontSize="small" color="secondary" />
                </Button>
            </CopyToClipboard>
        </Row>
    )
}

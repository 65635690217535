import { isAdmin, isAuthenticated } from ".";
import { API } from "./config";

export const demo = (body) => {
  if (!isAdmin()) {
    return;
  }
  const { user, token } = isAuthenticated();

  return fetch(`${API}/path`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }
      return response.body;
    })

    .catch((err) => {
      console.log(err);
    });
};

export const getUsers = (body) => {
  //ChangeApi

  if (!isAdmin()) {
    return;
  }
  const { user, token } = isAuthenticated();

  return fetch(`${API}/user/list/${user.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body:JSON.stringify(body)
  })
    .then((response) => {
      
      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const changeUserStatus = (body) => {
  //ChangeApi

  if (!isAdmin()) {
    return;
  }
  const { user, token } = isAuthenticated();

  return fetch(`${API}/user/changeStatus/${user.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const getAdminUrls = () => {
  //ChangeApi

  if (!isAdmin()) {
    return;
  }
  const { user, token } = isAuthenticated();

  return fetch(`${API}/admin/url/list/${user.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const getAdminUrlsByUserId = (userId) => {
  //ChangeApi

  if (!isAdmin()) {
    return;
  }
  const { user, token } = isAuthenticated();

  return fetch(`${API}/admin/url/list/${user.id}?userid=${userId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const editUrlAdmin = (body, url_id) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/url/admin/edit/${url_id}/${user.id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const getAdminTransaction = () => {
  //ChangeApi
  if (!isAdmin()) {
    return;
  }
  const { user, token } = isAuthenticated();

  return fetch(`${API}/transactions/3`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};
export const getPlansAdmin = () => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/plan/list`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const createPlan = (body) => {
  //ChangeApi

  if (!isAdmin()) {
    return;
  }
  const { user, token } = isAuthenticated();

  return fetch(`${API}/plan/create/${user.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const editPlan = (body, plan_id) => {
  const { user, token } = isAuthenticated();
  console.log(JSON.stringify(body));

  return fetch(`${API}/plan/edit/${user.id}/${plan_id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const deletePlan = (plan_id) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/plan/delete/${user.id}/${plan_id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const changePasswordAdmin = (body) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/changePassword/${user.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const deleteUrlAdmin = (url_id) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/url/admin/delete/${url_id}/${user.id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const getAdminDashboard = () => {
  //ChangeApi

  if (!isAdmin()) {
    return;
  }
  const { user, token } = isAuthenticated();

  return fetch(`${API}/admin/dashboard/${user.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};
export const createMessage = (body) => {
  //ChangeApi

  if (!isAdmin()) {
    return;
  }
  const { user, token } = isAuthenticated();

  return fetch(`${API}/message/create/${user.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const getMessages = () => {
  //ChangeApi

  if (!isAdmin()) {
    return;
  }
  const { user, token } = isAuthenticated();

  return fetch(`${API}/message/${user.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};
export const deleteMessageAdmin = (messageId) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/message/delete/${user.id}/${messageId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const getTransactionAdmin = (id) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/transactions/admin/${user.id}?userid=${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const getAvailabilityAdmin = ( userId) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/availability/admin/current/${user.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({id:userId})
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};


export const getServerStatus = ( userId) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/admin/dashboard/status/${user.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};



export const updateServerStatus = ( status) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/admin/dashboard/status/${user.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({status:status})
  })
    .then((response) => {
      if (response.status != 200) {
        return response.text().then((data) => {
          return {
            error: data,
          };
        });
      }

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};



export const getBrandsByUserAdmin = ( userId) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/brand/${user.id}?userID=${userId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  })
    .then((response) => {

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};



export const editBrandsUrlAdmin = (body, brandUrlId) => {
  console.log(body);
  const { user, token } = isAuthenticated();

  return fetch(`${API}/brandurl/admin/${user.id}/${brandUrlId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body:JSON.stringify(body)
  })
    .then((response) => {

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const deleteBrandsUrlAdmin = ( brandUrlId) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/brandurl/admin/${user.id}/${brandUrlId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  })
    .then((response) => {

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

export const alltransactions = ( body) => {
  const { user, token } = isAuthenticated();

  return fetch(`${API}/transactions/all/admin/${user.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body:JSON.stringify(body)
  })
    .then((response) => {

      return response.json();
    })

    .catch((err) => {
      console.log(err);
    });
};

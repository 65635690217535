import React from "react";
import { Div, Icon, Row, Text, Col } from "atomize";
import { useHistory } from "react-router-dom";

const Card = ({ title, value, icon, color, route }) => {
  const history = useHistory();
  return (
    <Col size={{ xs: "12", md: "6", lg: "3" }}>
      <Div
        onClick={(e) => history.push(route)}
        bg="white"
        rounded="md"
        shadow="5"
        m={{ y: "15px" }}
        p="20px"
      >
        <Text textColor={color} textWeight="700">
          {title}
        </Text>
        <Row justify="flex-end" align="flex-end">
          {icon && <Icon color={color} name={icon} m="10px" size="20px" />}
          <Text
            textColor={color}
            textAlign="right"
            textSize="display1"
            textWeight="700"
          >
            {value == null ? 0 : value}
          </Text>
        </Row>
      </Div>
    </Col>
  );
};

export default Card;

import React, { useEffect, useState } from "react";
import {
  Div,
  Button,
  Icon,
  Row,
  Col,
  Text,
  Modal,
  Notification,
} from "atomize";
import { Fragment } from "react";
import UserDashboardLayout from "../Routes/User/UserDashboardLayout";
import { makeTransaction, payTransaction, buyFreePlan } from "../core/apiUser";
import DropDown from "./DropDown";
import { useHistory } from "react-router-dom";
import { Currencies, ExchangeRate, ExchangeResponse } from 'exchange-rates-as-promised'
import { razorPayApiKeyTest, razorPayApiKey } from "../core/config";

const MembershipCard = ({
  currency = "INR",
  planDetails,
  onFinish,
  setNotification,
  openNotification,
}) => {


  useEffect(() => {
    getRate(currency)
  }, [])

  const [currentPlanDetails, setCurrentPlanDetails] = useState(planDetails);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isFreePlanBuyModalOpen, setIsFreePlanBuyModalOpen] = useState(false);
  const [isloading, setIsloading] = useState(false);


  const exchangeRate = new ExchangeRate()

  const getRate = (currency) => {
    if (currency == "INR") {
      setCurrentPlanDetails({ ...currentPlanDetails, finalprice: (planDetails.price - planDetails.offerPrice), price: planDetails.price })
    } else {
      exchangeRate.setBaseCurrency(Currencies.INR);
      exchangeRate.getRates()
        .then((response) => {
          setCurrentPlanDetails({
            ...currentPlanDetails, finalprice: ((planDetails.price - planDetails.offerPrice) * response.rates.USD).toFixed(1),
            price: (planDetails.price * response.rates.USD).toFixed(1)
          })
        })
    }
  }


  const offerPercentage = () => {
    return ((planDetails.offerPrice / planDetails.price) * 100).toFixed();
  };

  const [notificationData, setNotificationData] = useState({
    content: false,
    bg: false,
  });

  const FreePlanModal = (params) => {
    return (
      <Modal
        isOpen={isFreePlanBuyModalOpen}
        onClose={() => setIsFreePlanBuyModalOpen(false)}
        rounded="md"
        align="start"
        rounded="md"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setIsFreePlanBuyModalOpen(false)}
          cursor="pointer"
        />
        <Div d="flex" m={{ b: "4rem" }}>
          <Icon
            name="AlertSolid"
            color="warning700"
            m={{ t: "0.35rem", r: "0.5rem" }}
          />
          <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="subheader">
            Do you really want to continue?
          </Text>
        </Div>
        <Div d="flex" justify="flex-end">
          <Button
            onClick={(e) => setIsFreePlanBuyModalOpen(false)}
            bg="gray200"
            textColor="medium"
            m={{ r: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            isLoading={isloading}
            onClick={(e) => buyFreePlanHandler()}
            bg="info700"
          >
            Yes{" "}
          </Button>
        </Div>
      </Modal>
    );
  };
  const showNotification = () => {
    return (
      <Notification
        bg={notificationData.bg}
        isOpen={notificationData.content}
        onClose={() =>
          setNotificationData({ ...notificationData, content: false })
        }
      >
        {notificationData.content}
      </Notification>
    );
  };
  const buyFreePlanHandler = () => {
    setNotification(true);
    setIsloading(true);
    buyFreePlan(planDetails.id,{
      noOFMonth: noOfMonth,
    })
      .then((data) => {
        setNotification(false);
        if (data.error) {
          setIsloading(false);
          setNotificationData({
            content: JSON.parse(data.error)?.error,
            bg: "danger700",
          });
        } else {
          setIsloading(false);

          setIsFreePlanBuyModalOpen(false);
          setIsDetailsModalOpen(false);
          onFinish();
          openNotification(false);

          setNotificationData({
            content: "Free Plan Activated",
            bg: "success700",
          });
        }
      })
      .catch((err) => {
        setIsloading(false);
        setIsFreePlanBuyModalOpen(false);
        setNotificationData({
          content: err.error,
          bg: "danger700",
        });
      });
  };
  const paymentCallback = (data) => {
    setIsloading(false);
  };

  const [noOfMonth, setNoOfMonth] = useState(1);
  const paymentHandler = async (data) => {
    // e.preventDefault();
    setNotification(true);
    setIsloading(true);
    makeTransaction(planDetails.id, {
      noOFMonth: noOfMonth,
    })
      .then((data) => {
        console.log(data);
        const options = {
          key: razorPayApiKey,
          name: "Utubelink",
          description: "Your Order",
          order_id: data.order.id,
          amount: data.order.amount_due,
          currency: "INR",
          handler: async (response) => {
            payTransaction(data.trasitionLogData.id, {
              paymentid: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            })
              .then((data) => {
                paymentCallback(data);
                setNotification(false);
                onFinish();
              })
              .catch((err) => {
                setNotification(false);
                console.log(err);

                setIsloading(false);
                onFinish();
              });
          },
          modal: {
            ondismiss: function () {
              setNotification(false);
              setIsloading(false);
            },
          },
          theme: {
            color: "#686CFD",
          },
        };
        const rzp1 = new window.Razorpay(options);

        rzp1.open();
        rzp1.on("payment.failed", function (response) {
          setNotification(false);
        });
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
        setNotification(false);
      });
  };

  const MembershipDetailsModal = () => {
    return (
      <Modal
        isOpen={isDetailsModalOpen}
        rounded="md"
        onClose={() => setIsDetailsModalOpen(false)}
        transition
        maxW="60rem"
        h="auto"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={() => setIsDetailsModalOpen(false)}
          cursor="pointer"
        />
        {(planDetails.type != "FREE" || planDetails.type != "INFLUENCERS") && planDetails.offerPrice != 0 ? (
          <div className="offer-tag">
            {offerPercentage() + "%"} <br /> Off
          </div>
        ) : (planDetails.type == "FREE" || planDetails.type == "INFLUENCERS") ? (
          <div className="offer-tag">Free</div>
        ) : (
          ""
        )}
        <Row>
          <Col size={{ xs: "12", md: 4 }} p={{ t: "1rem" }}>
            <Text m="0.5rem" textAlign="center" textSize="display1">
              {planDetails.title}
            </Text>
            <Text
              textSize="display2"
              m="0.5rem"
              textAlign="center"
              textColor="price_tag"
            >
              <Text
                tag="span"
                textSize="display2"
                textAlign="center"
                textColor="price_tag"
                textDecor={planDetails.offerPrice > 0 ? "line-through" : ""}
              >
                {currency == "INR" ? "₹" : "$"} {noOfMonth * currentPlanDetails.price}{" "}
              </Text>

              <Text tag="span" textSize="subheader" textColor="customGray">
                / {noOfMonth * 30} days
              </Text>
              {planDetails.offerPrice > 0 ? (
                <Text
                  tag="span"
                  textSize="display2"
                  textAlign="center"
                  textColor="price_tag"
                >
                  <br></br> {currency == "INR" ? "₹" : "$"} &nbsp;
                  {noOfMonth * (currentPlanDetails.finalprice)}
                </Text>
              ) : (
                ""
              )}
            </Text>
          </Col>
          <Col>
            <Div w="2px" h="100%" bg="gray500"></Div>
          </Col>
          <Col size={{ xs: "12", md: 7 }} p={{ t: "1.5rem" }}>
            <Text textSize="subheader" textColor="dark700">
              {planDetails.noofbrand == -1 ? "Unlimited" : planDetails.noofbrand} Brands
            </Text>
            <Text textSize="subheader" textColor="dark700">
              {planDetails.noofbrandlink == -1 ? "Unlimited" : planDetails.noofbrandlink} Branded links
            </Text>
            <Text textSize="subheader" textColor="dark700">
              {planDetails.noOfClick == -1 ? "Unlimited" : planDetails.noOfClick} Clicks
            </Text>
            <Text textSize="subheader" textColor="dark700">
              {planDetails.noOfedit == -1 ? "Unlimited" : planDetails.noOfedit} Edits
            </Text>
            <Text textSize="subheader" textColor="dark700">
              {planDetails.noOfLink == -1 ? "Unlimited" : planDetails.noOfLink} Links
            </Text>
            {
              planDetails.type == "ENTERPRISE" || planDetails.type == "INFLUENCERS" ? (
                <Text textSize="subheader" textColor="dark700">
                  {planDetails.noofmonths == -1 ? "Unlimited" : planDetails.noofmonths} Months Remaining
                </Text>
              ) : ("")
            }

            {planDetails.appLinkOrNot ? (
              <Text textSize="subheader" textColor="dark700">
                Custom Target URL
              </Text>
            ) : (
              <Row m='0'>

                <Text
                  textSize="subheader"
                  textDecor="line-through"
                  textColor="dark700"
                >
                  Custom Target URL
                </Text>
              </Row>
            )}
            <br />
            <Text textSize="subheader" tag="h1" textColor="">
              Sub Domains
            </Text>
            {
              planDetails.subDomain.map((item, i) => (
                <Text key={i} textSize="subheader" textColor="dark700">
                  {item}
                </Text>
              ))
            }
          </Col>
        </Row>
        {
          currency !== "INR" && (
            <Text textSize="tiny">*Price is aproximate value of INR in USD, and billed in INR</Text>)
        }
        {FreePlanModal()}
        <Row justify="flex-end">
          {(planDetails.type == "FREE") ? (
            // {(planDetails.type == "FREE" || planDetails.type == "INFLUENCERS") ? (
            <Button
              m={{ t: "1rem" }}
              bg="transparent"
              textColor="black"
              border="1px solid"
              borderColor="prime_blue"
              isLoading={isloading}
              onClick={(e) => setIsFreePlanBuyModalOpen(true)}
            >
              Try 1 month
            </Button>
          ) : (
            <>
              {" "}
              <Div m={{ t: "1rem", x: "1rem" }}>
                <DropDown
                  width="fit-content"
                  datas={[
                    { value: "1", title: "1 month" },

                    { value: "3", title: "3 month" },

                    { value: "6", title: "6 month" },

                    { value: "12", title: "1 year" },
                    { value: "24", title: "2 year" },
                  ]}
                  title="1 month"
                  valueKey="title"
                  onChange={(data) => setNoOfMonth(data.value)}
                ></DropDown>
              </Div>
              <Button
                m={{ t: "1rem" }}
                bg="transparent"
                textColor="black"
                border="1px solid"
                borderColor="prime_blue"
                isLoading={isloading}
                onClick={(e) => { planDetails.type == "INFLUENCERS" ? buyFreePlanHandler() : paymentHandler(planDetails) }}
              >
                Buy Now
              </Button>
            </>
          )}
        </Row>
      </Modal>
    );
  };


  return (
    <Col size={{ xs: "12", md: "6", lg: "4" }}>
      {MembershipDetailsModal()}
      {showNotification()}
      <Div bg="white" shadow="4" p="1rem" rounded="md" m={{ y: "0.5rem" }}>
        <Row>
          <Col>
            <Text textColor="black" textSize="display1">
              {" "}
              {planDetails.title}
            </Text>
            <Text textColor="price_tag">
              <Text
                tag="span"
                textWeight="900"
                textSize="title"
                textColor="price_tag"
              >
                <span
                  style={{
                    marginRight: "5px",
                    marginLeft: "2px",
                    fontWeight: planDetails.offerPrice > 0 ? "lighter" : "",
                    textDecoration:
                      planDetails.offerPrice > 0 ? "line-through" : "",
                  }}
                >
                  {currency == "INR" ? "₹" : "$"}{currentPlanDetails.price && currentPlanDetails.price}
                </span>
                {planDetails.offerPrice > 0
                  // ? "₹" + (planDetails.price - planDetails.offerPrice)currency
                  ? currentPlanDetails.finalprice && (
                    <Fragment>

                      {currency == "INR" ? "₹" : "$"}
                      {currentPlanDetails.finalprice}
                    </Fragment>
                  )
                  : ""}
              </Text>
              <br />/ 30 days
            </Text>
          </Col>
          <Col>
            <Div d="flex" h="100%" justify="flex-end" align="center">
              <Button
                h="2rem"
                p={{ x: "0.75rem" }}
                textSize="caption"
                textColor="prime_blue"
                hoverTextColor="info900"
                bg="white"
                onClick={() => setIsDetailsModalOpen(true)}
                hoverBg="info200"
                border="1px solid"
                borderColor="prime_blue"
                hoverBorderColor="info900"
                m={{ r: "0.5rem" }}
              >
                Details
              </Button>
            </Div>
          </Col>
        </Row>
      </Div>
    </Col>
  );
};

export default MembershipCard;

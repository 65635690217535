import React, { useState } from "react";
import { Div, Row, Col, Button } from "atomize";
import { Link, Redirect, useHistory } from "react-router-dom";
import { isAuthenticated, signout } from "../core";

const Home = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const history = useHistory();
  return (
    <Div h="40px" bg="white" shadow="2">
      {window.location.replace("/home")}
      <Redirect to="/home"></Redirect>
      <Row w="100%" p={{ y: "5px" }}>
        <Button
          bg="white"
          hoverBg="white"
          rounded="lg"
          m={{ x: "1rem", y: "auto" }}
          h="30px"
          textColor="info800"
        >
          UTUBELINK
        </Button>
        <Div m={{ l: "auto" }}>
          <Row>
            <Button
              bg="white"
              hoverBg="gray400"
              rounded="lg"
              m={{ r: "1rem", y: "auto" }}
              h="30px"
              textColor="black400"
            >
              About
            </Button>

            <Button
              bg="white"
              hoverBg="gray400"
              rounded="lg"
              m={{ r: "1rem", y: "auto" }}
              h="30px"
              textColor="black400"
              onClick={(e) => {
                if (isAuthenticated() && isAuthenticated().user.role == 1) {
                  history.push("/user/dashboard");
                } else if (
                  isAuthenticated() &&
                  isAuthenticated().user.role == 0
                ) {
                  history.push("/admin/dashboard");
                } else {
                  history.push("/login");
                }
              }}
            >
              {isAuthenticated() ? "Dashboard" : "Login"}
            </Button>
            {isAuthenticated() && (
              <Button
                bg="white"
                hoverBg="gray400"
                rounded="lg"
                m={{ r: "1rem", y: "auto" }}
                h="30px"
                textColor="black400"
                onClick={(e) => {
                  signout(() => {
                    history.push("/");
                  });
                }}
              >
                Logout
              </Button>
            )}
          </Row>
        </Div>
      </Row>
    </Div>
  );
};

export default Home;

import firebase from 'firebase/app';
import 'firebase/auth';
import { authenticate, continueWithGoogle } from './index'

const config = {
    apiKey: "AIzaSyDnSW7OsHKFQMudG5H57RRiSql9ZxKjoFk",
    // authDomain: "utubelink-a547a.firebaseapp.com",
    projectId: "utubelink-a547a",
    storageBucket: "utubelink-a547a.appspot.com",
    messagingSenderId: "104866753203",
    appId: "1:104866753203:web:c3eb984ec30b0c628d1ec6",
    measurementId: "G-R3S3WT8Y44",
    authDomain: 'auth.utube.link'
  };

firebase.initializeApp(config);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = (referalcode, next) => auth.signInWithPopup(provider).then(async data => {
    await auth.currentUser.getIdToken(true).then(async idToken => {
        return await continueWithGoogle({
            token: idToken,
            name: data.user.displayName,
            email: data.user.email,
            referalcode: referalcode
        }).then(data => {
            if(data.error){
                return;
            }else{
                return authenticate(data, () => {
                    return data;
                })
            }
        }).catch(e => {
            next()
            console.log(e);
            return e;
        });
    }).catch(e => {
        next()
        console.log({e});
    })
    next()
}).catch(e => {
    next()
    console.log(e);
});

export default firebase;
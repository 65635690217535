import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminRoute from "./core/AdminRoute";
import UserRoute from "./core/UserRoute";

import AdminDashBoard from "./Routes/Admin/AdminDashboard";
import UserDashBoard from "./Routes/User/UserDashboard";
import Home from "./Routes/Home";
import Login from "./Routes/Login";
import { Urls } from "./Routes/User/Urls";
import { Transactions } from "./Routes/User/Transactions";
import Membership from "./Routes/User/Membership";
import Settings from "./Routes/User/Settings";
import { AdminUrls } from "./Routes/Admin/AdminUrls";
import { Users } from "./Routes/Admin/Users";
import Plans from "./Routes/Admin/Plans";
import { AdminTransactions } from "./Routes/Admin/AdminTransactions";
import AdminSettings from "./Routes/Admin/AdminSettings";
import SignUp from "./Routes/SignUp";
import ChartTest from "./Routes/User/Chart";
import { Messages } from "./Routes/Admin/Messages";
import { ListUrls } from "./Routes/Admin/ListUrl";
import ChartAdmin from "./Routes/Admin/ChartAdmin";
import Invoice from "./components/Invoice";
import Referral from "./Routes/User/Referral";
import ForgotPassword from "./Routes/ForgotPassword";
import SetNewPassword from "./Routes/SetNewPassword";
import CurrentUserAvailiability from "./Routes/Admin/CurrentUserAvailiability";
import BrandUrlAnalytics from "./Routes/User/BrandUrlAnalytics";
import Kaimly from "./Routes/Kaimly";
import { Brands } from "./Routes/User/Brands";
import { BrandUrls } from "./Routes/User/BrandUrls";
import { ListBrands } from "./Routes/Admin/ListBrands";
import { ListBrandUrls } from "./Routes/Admin/ListBrandUrls";
import ChartAdminBrandUrl from "./Routes/Admin/ChartAdminBrandUrl";
import { AllTransactions } from "./Routes/Admin/AllTransactions";

function Routes() {

  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home}></Route>
        <Route path="/madebykaimly" exact component={Kaimly}></Route>
        <Route path="/login" exact component={Login}></Route>
        <Route path="/signup" exact component={SignUp}></Route>
        <Route path="/forgot-password" exact component={ForgotPassword}></Route>
        <Route path="/set-new-password/:userid/:token" exact component={SetNewPassword}></Route>

        <UserRoute path="/user/dashboard" exact component={UserDashBoard}></UserRoute>
        <UserRoute path="/user/urls" exact component={Urls}></UserRoute>
        <UserRoute path="/user/brands" exact component={Brands}></UserRoute>
        <UserRoute path="/user/brands/:brandId/urls" exact component={BrandUrls}></UserRoute>
        <UserRoute path="/user/brands/:brandId/:brandUrlId/analytics" exact component={BrandUrlAnalytics}></UserRoute>
        <UserRoute path="/user/transactions" exact component={Transactions} ></UserRoute>
        <UserRoute path="/user/membership"  exact component={Membership} ></UserRoute>
        <UserRoute path="/user/settings" exact component={Settings}></UserRoute>
        <UserRoute path="/url/chart/:urlId"  exact component={ChartTest} ></UserRoute>
        <UserRoute path="/user/referral"  exact component={Referral} ></UserRoute>

        <AdminRoute path="/admin/dashboard" exact component={AdminDashBoard} ></AdminRoute>
        <AdminRoute path="/admin/urls" exact component={AdminUrls} ></AdminRoute>
        <AdminRoute path="/admin/urls/chart/:urlId" exact component={ChartAdmin} ></AdminRoute>
        <AdminRoute path="/admin/users" exact component={Users} ></AdminRoute>
        <AdminRoute path="/admin/users/urls/list/:userId" exact component={ListUrls} ></AdminRoute>
        <AdminRoute path="/admin/users/brand/list/:userId" exact component={ListBrands} ></AdminRoute>
        <AdminRoute path="/admin/users/brand/url/list/:brandId" exact component={ListBrandUrls} ></AdminRoute>
        <AdminRoute path="/admin/users/brand/url/analytics/:brandUrlId" exact component={ChartAdminBrandUrl} ></AdminRoute>

        <AdminRoute path="/admin/plans" exact component={Plans} ></AdminRoute>
        <AdminRoute path="/admin/transactions/:userId" exact component={AdminTransactions} ></AdminRoute>
        <AdminRoute path="/admin/transactions" exact component={AllTransactions} ></AdminRoute>
        <AdminRoute path="/admin/messages" exact component={Messages} ></AdminRoute>
        <AdminRoute path="/admin/settings" exact component={AdminSettings} ></AdminRoute>
        <AdminRoute path="/admin/bill" exact component={Invoice} ></AdminRoute>
        <AdminRoute path="/admin/currentavail/:userId" exact component={CurrentUserAvailiability} ></AdminRoute>
      </Switch>
    </Router>
  );
}

export default Routes;

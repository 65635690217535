import { Div, Icon } from 'atomize'
import React from 'react'
import { Fragment } from 'react'

const Loading = ({h="500px",isLoading=true,children}) => {
    
    if(isLoading){
        return (
            <Div w="100%" h={h} className="loading" textAlign="center">
              <Icon
                name="Loading2"
                m={{ l: "-25px" }}
                size="50px"
                color="info700"
                className="loading-center"
              />
            </Div>
        )
    }else{
        return (
            <Fragment>
                {children}
            </Fragment>
        )
    }
    
}

export default Loading

import { Div } from "atomize"
import React, { useState, useEffect } from "react";

export const errMessage =()=>`You are using a downgraded plan compared to your old plan. All Brands and Links will work as per your current plan. Please upgrade your plan or the Brands and Links marked red will not deeplink to their corresponding apps.`;
export const errMessageSimple =()=>`Please upgrade your plan`;

export const GetErrorMessage = ({ availability }) => {
    return (

        !isPlanError(availability) ? (
            <Div ></Div>
        ) : (<Div p={{ y: "10px", x: "20px" }} bg="danger700" w="100%" textColor="white" m={{ b: "10px" }} rounded="sm">{errMessage()}</Div>)

    )
}

export const isPlanError = (availability) => {
    console.log(availability);
    const featureArray = ['noOfClick', 'noOfLink', 'noofbrand', 'noofbrandlink', 'noOfedit'];
    const Error_Messages = ['', 'links', 'brands', 'brand links', 'noOfedit'];
    let flag = false;
    let flagedFeatures = [];
    for (let i = 0; i < featureArray.length; i++) {
        const element = featureArray[i];
        if (!availability) return;
        if (availability[element] != -1 && (availability[element] - availability[element + "Used"] < 0)) {
            console.log("errror")
            console.log(availability[element])
            console.log(availability[element + "Used"])
            console.log(element)
            console.log(element + "Used")
            flag = true;
            flagedFeatures.push(Error_Messages[i]);
        }

    }
    return flag


}


export const isBrandHaveError = (availability, brands, brandId) => {
    let value_diffrence = availability.noofbrandUsed - availability.noofbrand;
    // brands=brands.reverse();
    if (isPlanError(availability)) {
        for (let i = 0; i < brands.length; i++) {
            if (value_diffrence > 0) {
                brands[i].haveProblem = true;
                value_diffrence--;
            }
            if(brands[i].id==brandId && brands[i].haveProblem){
                return true;
            }
        }
    }

}


export const isURLHaveError = (availability, urls, urlId) => {
    let value_diffrence = availability.noOfLinkUsed - availability.noOfLink;
    if (isPlanError(availability)) {
        for (let i = 0; i < urls.length; i++) {
            if (value_diffrence > 0) {
                urls[i].haveProblem = true;
                value_diffrence--;
            }
            if(urls[i].id==urlId && urls[i].haveProblem){
                return true;
            }
        }
    }

}


export const isBrandURLHaveError = (availability, urls, urlId) => {
    let value_diffrence = availability.noofbrandlinkUsed - availability.noofbrandlink;
    if (isPlanError(availability)) {
        for (let i = 0; i < urls.length; i++) {
            if (value_diffrence > 0) {
                urls[i].haveProblem = true;
                value_diffrence--;
            }
            if(urls[i].id==urlId && urls[i].haveProblem){
                return true;
            }
        }
    }

}
import { Text, Icon, Row } from 'atomize'
import Div from 'atomize/dist/atoms/div/Div'
import Col from 'atomize/dist/atoms/grid/Col'
import React from 'react'

const PlanFeatureItem = ({ data2, data1, title }) => {
    return (
        <Col size={{ xs: "12", md: "6", lg: "3" }}>
            <Div
                bg="white"
                rounded="md"
                shadow="5"
                m={{ y: "15px" }}
                p="20px"
            >
                <Text> {title} </Text>
                <Row justify="flex-end" align="flex-end">
                    <Icon
                        color="danger700"
                        name="Rename"
                        m="10px"
                        size="20px"
                    />

                    <Text
                        textAlign="right"
                        textSize="subheader"
                        textWeight="900"
                        textColor={data1 != -1 && (data1 - data2) < 0 ? "danger700" : "black900"}
                    >
                        {data1 == -1 ? "Unlimited" : `${data1 - data2} /${data1}`}
                    </Text>
                </Row>

                {data1 != -1 && (data1 - data2) < 0 && (<Text textColor="danger700" textSize="tiny">Please delete overflow contents.</Text>)}
            </Div>
        </Col>
    )
}

export default PlanFeatureItem

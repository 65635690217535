import React, { useState } from "react";
import {
  Div,
  Row,
  Text,
  Col,
  currentDevice,
  Icon,
  Modal,
  Button,
} from "atomize/dist";
import moment from "moment";

const NotificationCard = ({ data }) => {
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const date = new Date(data.createdAt)
  const NotificationModal = () => {
    return (
      <Modal
        isOpen={showNotificationModal}
        onClose={(e) => setShowNotificationModal(false)}
        align="center"
        rounded="md"
      >
        <Icon
          name="Cross"
          pos="absolute"
          top="1rem"
          right="1rem"
          size="16px"
          onClick={(e) => setShowNotificationModal(false)}
          cursor="pointer"
        />

        <Row justify="space-between">
          <Col size={{ xs: 12, md: 6 }}>
            {" "}
            <Text textWeight="500" textSize="subheader">
              {data.title}
            </Text>
            <Text textSize="caption">
              {" "}
              {date.toUTCString()} 
              
            </Text>
          </Col>
        </Row>
        <Text p={{ b: "1rem", t: "0.5rem" }} textSize="body">
          {data.content}
        </Text>
      </Modal>
    );
  };
  return (
    <Div
      hoverBg="gray300"
      rounded="md"
      p={{ x: "0.5rem" }}
      h={{ xs: "160px", md: "60px" }}
      border={{ b: "1px solid" }}
      borderColor="gray500"
      m={{ y: "0.5rem" }}
      onClick={(e) => setShowNotificationModal(true)}
    >
      {NotificationModal()}
      <Row justify="space-between">
        <Col size={{ xs: 12, md: 6 }}>
          {" "}
          <Text textWeight="500" textSize="subheader">
            {data.title}
          </Text>
        </Col>
        <Col size={{ xs: 12, md: 1 }}>
          <Text textSize="caption"> {data.date}</Text>
        </Col>
      </Row>
      <Text textSize="body">
        {data.content.substring(0, currentDevice() == "xs" ? 100 : 150) + "..."}
      </Text>
    </Div>
  );
};

export default NotificationCard;

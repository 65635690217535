// export const API = "http://localhost:4000/api";
// export const uri = "http://localhost:4000/";

// export const API2 = "https://utublink-production.herokuapp.com/api";
// export const uri2 = "https://utublink-production.herokuapp.com/";

// export const API2 = "https://utube.link/api";
// export const uri2 = "https://utube.link";

export const API2 = `https://${window.location.hostname}/api`;
export const uri2 = `https://${window.location.hostname}`;

// export const API1 = "http://192.168.1.37:4000/api";
export const razorPayApiKey = "rzp_live_h9DJ5GTERCYUZt";



// export const API2 = "http://localhost:4000/api";
// export const uri2 = "http://localhost:4000/";

// export const razorPayApiKey = "rzp_test_4ZePLSdWFNZ667";

// export const API = window.location.hostname != "localhost"? window.location.hostname!="192.168.1.37"?API2:"http://localhost:4000/api":"http://192.168.1.37:3000/api";

// export const API = window.location.hostname != "192.168.1.37"?API2:"http://192.168.1.37:4000/api";
// export const uri = window.location.hostname != "192.168.1.37"?uri2:"http://192.168.1.37:4000";

export const API = window.location.hostname != "localhost"?API2:"http://localhost:4000/api";
export const uri = window.location.hostname != "localhost"?uri2:"http://localhost:4000";


import React, { useEffect, useState } from "react";
import {
  Div,
  Icon,
  Button,
  Container,
  Notification,
  Text,
  Input,
} from "atomize";
import { Link, Redirect } from "react-router-dom";
import { setNewPassword } from "../core";
import { Fragment } from "react";
const SetNewPassword = ({ match }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [showPasswordConfirm, setshowPasswordConfirm] = useState(false);
  const [passwords, setPasswords] = useState({
    password: "",
    confPassword: "",
  });
  const handleChange = (name) => (event) => {
    setPasswords({
      ...passwords,
      [name]: event.target.value,
    });
  };

  const [notification, setNotification] = useState({
    content: false,
    bg: "",
  });
  const submitPassword = (params) => {
    if (passwords.password == passwords.confPassword) {
      setIsLoading(true);
      setNewPassword(passwords, match.params.userid, match.params.token)
        .then((data) => {
          console.log(data);
          if (data.error) {
            setIsLoading(false);
            setNotification({
              content: JSON.parse(data.error).error,
              bg: "danger700",
            });
          } else {
            setIsLoading(false);
            setNotification({
              content: "Password Changed. Please Login  ",
              bg: "success700",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          setNotification({
            content: err && err.error && err.error,
            bg: "danger700",
          });
        });
    } else {
      setNotification({ content: "Password Doesn't Match", bg: "danger700" });
    }
  };

  const checkPassword=()=>{
    let res=[];
    if(passwords.password.length==0){
      return [];
    }
    if(passwords.confPassword.length>0&&(passwords.confPassword!=passwords.password)){
      res.push({msg:"Password must be equal"})
    }
    if(passwords.password.length<8){
      res.push({msg:"Password must contain 8 character"})
    }
    if(!/[A-Z]/.test(passwords.password)){
      res.push({msg:"Password must contain an uppercase letter"})
    }
    if(!/[a-z]/.test(passwords.password)){
      res.push({msg:"Password must contain an lowercase letter"})
    }
    if(!/\d/.test(passwords.password)){
      res.push({msg:"Password must contain a number"})
    }
    return res;
  }

  const showError = () => {
    return (
      <Notification
        bg={notification.bg}
        isOpen={notification.content}
        onClose={() =>
          setNotification({ ...notification, content: false, bg: "" })
        }
      >
        {notification.content}
        {/* {values.error.err || values.error?.error[0]?.rawError[0]?.message} */}
        {/* {values.error[0]?.rawError[0]?.message} */}
      </Notification>
    );
  };
  return (
    <Container p={{ t: "20px" }} h="100vh">
      <br />
      {showError()}
      <br />
      <Div textAlign="center">
        <img src="/img/logo.svg" width="100px" alt="logo" />
      </Div>
      <Text textAlign="center" textSize="display1" tag="h4">
      UTUBELINK
      </Text>
      <br />
      <Div
        m={{ xs: "20px", md: "auto" }}
        bg="white"
        shadow="5"
        rounded="xl"
        p="20px"
        maxW={{ xs: "auto", md: "350px" }}
      >
        <Text textAlign="center" textSize="subheading" m={{ b: "0.5rem" }}>
          Set New Password
        </Text>

        <Input
          placeholder="Password"
          m={{ b: "1rem" }}
          type={"password"}
          onChange={handleChange("password")}
        />
        <Input
          placeholder="Confirm Password"
          m={{ b: "1rem" }}
          type={showPasswordConfirm ? "text" : "password"}
          suffix={
            <Button
              pos="absolute"
              onClick={() => setshowPasswordConfirm(!showPasswordConfirm)}
              bg="transparent"
              w="3rem"
              top="0"
              right="0"
              rounded={{ r: "md" }}
            >
              <Icon
                name={showPasswordConfirm ? "EyeSolid" : "Eye"}
                color={showPasswordConfirm ? "danger800" : "success800"}
                size="16px"
              />
            </Button>
          }
          onChange={handleChange("confPassword")}
        />


        <Div textSize="caption" m={{b:"10px"}} textColor="danger700">
        {checkPassword().map((item,i)=>(
          <Fragment key={i}>
            <Text textSize="caption" textColor="danger700">{item.msg}</Text>
          </Fragment>
        ))}
        </Div>
  

<Text m={{ b: "0.5rem" }} textColor="disabled" textSize="caption">
          <Link to="/login"> Login</Link>
        </Text>
        <Div d="flex" justify="center">
          <Button
            p={{ x: "2.5rem" }}
            hoverShadow="2"
            m={{ r: "1rem" }}
            bg="info800"
            hoverBg="info700"
            onClick={submitPassword}
            isLoading={isLoading}
          >
            Submit
          </Button>
        </Div>
      </Div>
    </Container>
  );
};

export default SetNewPassword;

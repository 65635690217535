
import React, { useState, useEffect } from "react";
// import logo from "../logo.svg";
import { Button, Icon, Div, Row } from "atomize/dist";
import printJS from "print-js";
import moment from "moment";
import { uri } from '../core/config'
import { inWords } from "../core/NumberToWords";

export const stateTaxValue = (billData) => {
  if (billData.state == "Kerala") {
    return 19;
  } else {
    return 18;
  }
}

export const getAmount = (billData) => {
  return (billData.price * billData.countOfMonth);
}
export const getDiscount = (billData) => billData.offerPrice * billData.countOfMonth;


export const getTaxableValue = (billData) => {
  let value = ((getAmount(billData) - getDiscount(billData)) / (stateTaxValue(billData) + 100)) * 100;

  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export const getGst = (billData) => {
  let amount = (getTaxableValue(billData));
  amount = (amount / 100) * 9;
  return Math.round((amount + Number.EPSILON) * 100) / 100;
}

export const getKfc = (billData) => {
  if (billData.state == "Kerala") {
    let amount = getTaxableValue(billData);
    amount = (amount / 100) * 1;
    return Math.round((amount + Number.EPSILON) * 100) / 100
  } else {
    return 0;
  }
}

export const getTotal = (billData) => {
  let amount = getTaxableValue(billData) + getGst(billData) + getGst(billData) + getKfc(billData);
  return amount;
}

export const getRoundOff = (billData) => {
  let Tamount = getTotal(billData);
  let amount = getAmount(billData) - getDiscount(billData);
  return Math.round((amount - Tamount + Number.EPSILON) * 100) / 100;
}

export const getInvoiceNumber = (billData) => {
  let billdate = new Date(billData.date);
  let value = billdate.getFullYear();
  let year = billdate.getFullYear() % 100;
  if (billdate.getMonth() < 3) {
    return "UBK/" + (year - 1) + "-" + year + "/" + billData.invoiceno;
  } else {
    return "UB/" + year + "-" + (year + 1) + "/" + billData.invoiceno;

  }
}
export const getInvoiceTotal = (billData) => {
  return Math.round((getTotal(billData) + getRoundOff(billData) + Number.EPSILON) * 100) / 100;
}


